import { Slider, Typography, useTheme } from "@material-ui/core"
import React, { useState, useEffect, useContext, useRef } from 'react';
import { AssessmentOutlined, Launch, ArrowBack, Settings, Work, School } from '@material-ui/icons';
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import SliderInput from './../SliderInput';



export default function Retraining(props) {

    const theme = useTheme();

    const { retraining, setRetraining } = props;

    return (<div>
        <Typography id="schoolPeriodLabel" >
            School Period
            </Typography>
        < div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }
        }>
            <Slider
                style={{ marginTop: theme.spacing(2) }}
                value={[retraining.startMonth, retraining.endMonth]}
                onChange={(event, newValue) => { setRetraining(state => ({ ...state, startMonth: newValue[0], endMonth: newValue[1] })) }}
                valueLabelDisplay="auto"
                aria-labelledby="schoolPeriodLabel"
                ValueLabelComponent={ValueLabelComponent}
                step={6}
                max={120}
                min={0}
                marks={sickLeaveMarks}
            />
        </div>
        <SliderInput value={retraining.annualTuitionFee} showCurrency onChange={(newValue) => setRetraining(state => ({ ...state, annualTuitionFee: newValue }))} minValue={0} maxValue={1000000} title={'Annual Tuition Fee'} />
        <Typography id="schoolabsenceLabel" >
            Rate of Absence
            </Typography>
        <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            <Slider
                // style={{ marginTop: theme.spacing(3) }}
                value={retraining.rateOfAbsence}
                onChange={(event, newValue) => { setRetraining(state => ({ ...state, rateOfAbsence: newValue })) }}
                // valueLabelDisplay="auto"
                aria-labelledby="schoolabsenceLabel"
                // ValueLabelComponent={ValueLabelComponent}
                step={0.25}
                max={1}
                min={0}
                marks={rateOfAbsenceMarks}
            />
        </div>
        < Typography id="payIncreaseLabel" >
            Expected Pay Increase
            </Typography>
        <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            <Slider
                // style={{ marginTop: theme.spacing(3) }}
                value={retraining.expectedPayRaise}
                onChange={(event, newValue) => { setRetraining(state => ({ ...state, expectedPayRaise: newValue })) }}
                // valueLabelDisplay="auto"
                aria-labelledby="payIncreaseLabel"
                // ValueLabelComponent={ValueLabelComponent}
                step={0.1}
                max={1}
                min={0}
                marks={payIncreaseMarks}
            />
        </div>
    </div>)
};


const sickLeaveMarks = [
    {
        value: 0,
        label: 'Now',
    },
    {
        value: 12,
        label: '1',
    },
    {
        value: 24,
        label: '2',
    },
    {
        value: 36,
        label: '3',
    },
    {
        value: 48,
        label: '4',
    },
    {
        value: 60,
        label: '5',
    },
    {
        value: 72,
        label: '6',
    },
    {
        value: 84,
        label: '7',
    },
    {
        value: 96,
        label: '8',
    },
    {
        value: 108,
        label: '9',
    },
    {
        value: 120,
        label: '10yrs',
    }
];

const rateOfAbsenceMarks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 0.25,
        label: '25%',
    },
    {
        value: 0.5,
        label: '50%',
    },
    {
        value: 0.75,
        label: '75%',
    },
    {
        value: 1,
        label: '100%',
    },
];

const payIncreaseMarks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 0.2,
        label: '20%',
    },
    {
        value: 0.4,
        label: '40%',
    },
    {
        value: 0.6,
        label: '60%',
    },
    {
        value: 0.8,
        label: '80%',
    },
    {
        value: 1,
        label: '100%',
    },
];

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <ValueLabel
            open={open}
            enterTouchDelay={0}
            placement="top"
            value={props.index === 0 ? <School fontSize='inherit' /> : <Work fontSize='inherit' />}

        >
            {children}
        </ValueLabel>

    );
}