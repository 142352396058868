import React from 'react';

export const InvestmentGoalContext = React.createContext({
    monthlyInvestmentAmount: '',
    setMonthlyInvestmentAmount: () => { },
    accountType: '',
    setAccountType: () => {},
    isaContributionStrategy: '',
    setIsaContributionStrategy: () => { },
    isValid: false,
});
