import moment from "moment";
import { AssetsAndDebts, CashFlowObservationsRequest, DividendType, FinancialPlanningRequestModel, Frequency, InflationAdjustmentContract, InvestmentAccountTaxationType, InvestmentStrategyType, ProductCodeCodeType, TypeOfRealEstateRequest } from "../api_client/api";
import { investmentAccount } from "../api_client/tsClient";
import { percentiles } from "../appConstants";
import { v4 as uuidv4 } from 'uuid';


export function createRetirementExampleRequest(
    currentAccount: string,
    scenarioSet: string,
    annualSpending: number,
    riskRatio: number
): FinancialPlanningRequestModel {

    const now = moment("2022-01-01T00:00:00.000Z");

    const observationDates = [...Array(41).keys()].map(index => now.clone().add(index, 'years').toDate());

    const bolagetsKF = uuidv4();
    const houseLund = uuidv4();
    const cassis = uuidv4();
    const middleBox = uuidv4();
    const growthBox = uuidv4();
    const mortgageLund = uuidv4();
    const mortgageCassis = uuidv4();

    // const equityFund = "IE00BN898167";
    // const capitalMarketsFund = "IE00BFG1RC24";

    const equityFund = "NO0010735129";
    const capitalMarketsFund = "LU1980295213";


    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            {
                id: bolagetsKF,
                investmentAllocations: [
                    {
                        currencyCode: "SEK",
                        outperformanceAssumption: 0,
                        fees: {
                            annualFee: 0.0035,
                            performanceFee: 0,
                            purchaseFee: 0,
                            salesFee: 0,
                            transactionCost: 0
                        },
                        weight: riskRatio,
                        productCode: {
                            code: equityFund,
                            codeType: ProductCodeCodeType.Isin
                        },
                        currentValue: 15432375
                    },
                    {
                        currencyCode: "SEK",
                        outperformanceAssumption: 0,
                        fees: {
                            annualFee: 0.0027,
                            performanceFee: 0,
                            purchaseFee: 0,
                            salesFee: 0,
                            transactionCost: 0
                        },
                        weight: 1 - riskRatio,
                        productCode: {
                            code: capitalMarketsFund,
                            codeType: ProductCodeCodeType.Isin
                        },
                        currentValue: 5144125
                    }
                ],
                taxationType: InvestmentAccountTaxationType.SweEndowmentInsurance,
                strategy: InvestmentStrategyType.RebalanceToPlan
            },
            {
                id: middleBox,
                investmentAllocations: [
                    {
                        currencyCode: "SEK",
                        outperformanceAssumption: 0,
                        fees: {
                            annualFee: 0.0035,
                            performanceFee: 0,
                            purchaseFee: 0,
                            salesFee: 0,
                            transactionCost: 0
                        },
                        weight: 0.5,
                        productCode: {
                            code: equityFund,
                            codeType: ProductCodeCodeType.Isin
                        },
                        currentValue: 2050000
                    },
                    {
                        currencyCode: "SEK",
                        outperformanceAssumption: 0,
                        fees: {
                            annualFee: 0.0027,
                            performanceFee: 0,
                            purchaseFee: 0,
                            salesFee: 0,
                            transactionCost: 0
                        },
                        weight: 0.5,
                        productCode: {
                            code: capitalMarketsFund,
                            codeType: ProductCodeCodeType.Isin
                        },
                        currentValue: 2050000
                    }
                ],
                taxationType: InvestmentAccountTaxationType.SweIsk,
                strategy: InvestmentStrategyType.RebalanceToPlan
            },
            {
                id: growthBox,
                investmentAllocations: [
                    {
                        currencyCode: "SEK",
                        outperformanceAssumption: 0,
                        fees: {
                            annualFee: 0.0035,
                            performanceFee: 0,
                            purchaseFee: 0,
                            salesFee: 0,
                            transactionCost: 0
                        },
                        weight: riskRatio,
                        productCode: {
                            code: equityFund,
                            codeType: ProductCodeCodeType.Isin
                        },
                        currentValue: 0
                    },
                    {
                        currencyCode: "SEK",
                        outperformanceAssumption: 0,
                        fees: {
                            annualFee: 0.0027,
                            performanceFee: 0,
                            purchaseFee: 0,
                            salesFee: 0,
                            transactionCost: 0
                        },
                        weight: 1 - riskRatio,
                        productCode: {
                            code: capitalMarketsFund,
                            codeType: ProductCodeCodeType.Isin
                        },
                        currentValue: 0
                    }
                ],
                taxationType: InvestmentAccountTaxationType.SweIsk,
                strategy: InvestmentStrategyType.RebalanceToPlan
            },
        ],
        transactionAccounts: [{ id: currentAccount, currencyCode: 'SEK', currentValue: 1000000 }],
        realEstate: [
            { id: houseLund, currentValue: 13000000, typeOfProperty: TypeOfRealEstateRequest.PrivateHome, acquisitionValue: 13000000 },
            { id: cassis, currentValue: 0, typeOfProperty: TypeOfRealEstateRequest.PrivateHome, acquisitionValue: 0 },
        ],
        mortgages: [
            {
                id: mortgageLund,
                currentValue: -2000000,
                annualInterestRate: 0.01,
                fixedRatePeriodInMonths: 3,
                interestRatePaymentAccountId: currentAccount
            },
            {
                id: mortgageCassis,
                currentValue: 0,
                annualInterestRate: 0.01,
                fixedRatePeriodInMonths: 3,
                interestRatePaymentAccountId: currentAccount
            }
        ]

    };

    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        cashFlowObservations: CashFlowObservationsRequest.Annual,
        incomesAndExpenses: {
            earnedIncome: {
                recurringTransactions: [],
                oneTimeTransactions: [],
                transactionArrays: [
                    { //dividends as salary
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                        frequency: Frequency.Annual,
                        startDate: now.toDate(),
                        amounts: [1390000, 1000000, 1000000, 1000000, 1000000, 1000000],
                        targetId: currentAccount
                    },
                    { //pension inc. occupational
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                        frequency: Frequency.Annual,
                        startDate: new Date(2027, 1, 1),
                        amounts: [500400, 528600, 556800, 645600, 734400, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 732000, 175200, 175200, 175200, 175200, 175200, 175200, 175200, 175200, 175200, 175200, 175200, 175200, 175200],
                        targetId: currentAccount
                    }
                ]
            },
            dividends: [
                {
                    type: DividendType.SweDividendsK10,
                    transactions: {
                        oneTimeTransactions: [],
                        recurringTransactions: [],
                        transactionArrays: [
                            {
                                inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                                frequency: Frequency.Annual,
                                startDate: now.toDate(),
                                amounts: [200000, 200000, 200000, 200000, 200000, 200000],
                                targetId: currentAccount
                            }]
                    }
                },
                {
                    type: DividendType.SweDividendsK12,
                    transactions: {
                        oneTimeTransactions: [],
                        recurringTransactions: [],
                        transactionArrays: [
                            {
                                inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                                frequency: Frequency.Annual,
                                startDate: new Date(2028, 1, 1),
                                amounts: [30000000],
                                targetId: currentAccount
                            }]
                    }
                }
            ],
            taxExempt:
            {
                recurringTransactions: [{
                    amount: annualSpending,
                    startDate: now.toDate(),
                    endDate: new Date(Date.UTC(2046, 12, 31)),
                    frequency: Frequency.Annual,
                    sourceId: currentAccount,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                }, 
                {
                    amount: 1000000,
                    startDate: new Date(Date.UTC(2047, 1, 1)),
                    endDate: new Date(Date.UTC(2066, 12, 31)),
                    frequency: Frequency.Annual,
                    sourceId: currentAccount,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                },
                {
                    amount: 137856,
                    startDate: now.toDate(),
                    endDate: new Date(Date.UTC(2031, 12, 31)),
                    frequency: Frequency.Annual,
                    targetId: currentAccount,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                }, 
                {
                    amount: 82596,
                    startDate: new Date(Date.UTC(2032, 1, 1)),
                    endDate: new Date(Date.UTC(2066, 12, 31)),
                    frequency: Frequency.Annual,
                    targetId: currentAccount,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                },
                ],
                oneTimeTransactions: [],
                transactionArrays: [
                    {
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                        frequency: Frequency.Annual,
                        startDate: now.toDate(),
                        amounts: [1200000, 1200000, 1200000, 1200000, 1200000, 1200000, 30000000],
                        sourceId: bolagetsKF
                    },
                    { // köp cassis 1
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                        frequency: Frequency.Annual,
                        startDate: now.toDate(),
                        amounts: [7100000],
                        sourceId: mortgageLund,
                        targetId: cassis
                    },
                    { // köp cassis 2
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                        frequency: Frequency.Annual,
                        startDate: now.toDate(),
                        amounts: [900000],
                        sourceId: mortgageCassis,
                        targetId: cassis
                    },
                    { // UTDELNING till bolaget
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                        frequency: Frequency.Annual,
                        startDate: now.toDate(),
                        amounts: [7800000, 3300000],
                        targetId: bolagetsKF
                    }],
            }
        },
        taxInformation: {
            taxPaymentAssetsAndDebtsItemId: currentAccount,
            municipalityKey: "1281"
        },
        portfolioStrategies: {
            waterfallAllocationStrategies: [{
                tiers: [{
                    assetsAndDebtsItemId: currentAccount,
                    priority: 3,
                    targetValue: 1000000,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                },
                {
                    assetsAndDebtsItemId: middleBox,
                    priority: 2,
                    targetValue: 4000000,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                },
                {
                    assetsAndDebtsItemId: growthBox,
                    priority: 1,
                    targetValue: 100000000,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                }
                ]
            }]
        },
        assetsAndDebtsItemGroups: [

            {
                id: uuidv4(), // eget kapital tillsammans [6]
                assetsAndDebtsItemIds: [
                    currentAccount,
                    bolagetsKF,
                    middleBox,
                    growthBox
                ]
            },
            {
                id: uuidv4(), // huset lund [3]
                assetsAndDebtsItemIds: [
                    houseLund,
                    mortgageLund
                ]
            },
            {
                id: uuidv4(), // cassis [4]
                assetsAndDebtsItemIds: [
                    cassis,
                    mortgageCassis
                ]
            },

        ],
        observationDates: observationDates,
        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
    }
    return financialPlanningRequest;
}

