import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, ReferenceLine, Cell, YAxis, Tooltip } from 'recharts';
import { useAppColors } from './useAppColors';
import { TextNumberFormat } from './NumberFormatCustom'
import { PanToolOutlined } from '@material-ui/icons';


function InvestmentOutcomeBarChart(props) {

    const colors = useAppColors();

    const { badOutcome, medianOutcome, goodOutcome, targetAmount } = props;
    const chartData = [
        { name: 'Pessimistic', value: badOutcome },
        { name: 'Realistic', value: medianOutcome },
        { name: 'Optimistic', value: goodOutcome },
    ];

    const maxValue = Math.ceil((goodOutcome > targetAmount ? goodOutcome : targetAmount) / 1000) * 1000;

    return (<div style={{ height: '10rem' }}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{
                top: 20, right: 20, left: 18, bottom: 2,
            }}>
                <defs>
                    <linearGradient id="colorBad" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.outcomes.bad} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.outcomes.bad} stopOpacity={0.85} />
                    </linearGradient>
                    <linearGradient id="colorGood" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.outcomes.good} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.outcomes.good} stopOpacity={0.85} />
                    </linearGradient>
                </defs>
                <Tooltip isAnimationActive={false} content={<CustomizedTooltip />}></Tooltip>
                <XAxis tickLine={false} dataKey="name" />
                <YAxis width={30} type="number" tick={<CustomizedAxisTick />} domain={[0, maxValue]} />
                <Bar dataKey="value" stackId="a" barSize={70} strokeWidth={2} strokeOpacity={0.5} stroke={colors.fanChart.referenceLine} >
                    {renderCell(chartData, targetAmount, "url(#colorGood)", "url(#colorBad)")}
                </Bar>

                <ReferenceLine stroke={colors.fanChart.referenceLine} strokeDasharray="3 3" y={targetAmount} />
            </BarChart>
        </ResponsiveContainer>
    </div>);
}

const renderCell = (data, targetAmount, goodColor, badColor) => {
    return data.map((coordinate, i) => {
        const { value } = coordinate
        return (
            <Cell
                key={i}
                fill={
                    value >= targetAmount ? goodColor : badColor
                }
            />
        )
    })
}

function CustomizedAxisTick(props) {
    const {
        x, y, stroke, payload, coordinate,
    } = props;


    return (
        <>
            <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: 10 }}
                renderText={value =>
                    <svg>
                        <g transform={`translate(${x},${y})`}>
                            <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: 10 }} fill="#666" >{value}</text>
                        </g>
                    </svg>
                }
            />

        </>
    );
}

function CustomizedTooltip(props) {
    const {
        payload, coordinate,
    } = props;

    const value = payload.length > 0 ? payload[0].value : 0;

    return (
        <>
            <TextNumberFormat roundToNearest={1} value={value}
                renderText={value =>
                    <svg>
                        <g transform={`translate(${coordinate.x},${coordinate.y})`}>
                            <text x={0} y={0} textAnchor="end" fill="#666" >{value}</text>
                        </g>
                    </svg>
                }
            />

        </>
    );
}


function areEqual(prevProps, nextProps) {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

export default React.memo(InvestmentOutcomeBarChart, areEqual);

