import React from 'react';
import { AreaChart, ReferenceLine, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from './appConstants';
import { useAppColors } from './useAppColors';
import { TextNumberFormat } from './NumberFormatCustom'


function GroupResultsChart(props) {
    const { groupResults, horizon } = props;
    const colors = useAppColors();


    if (groupResults === undefined || groupResults.length === 0) {
        return <></>;
    }

    const xAxisLength = horizon === undefined ? 30 : (Math.ceil(horizon / 10) * 10) + 1;


    console.log("gr");
    console.log(groupResults);

    const chartData = groupResults[0].forecast.slice(0, xAxisLength).map((o, i) => {
        var result = { name: '' + (i) };

        groupResults.forEach(group => {
            result = { ...result, [group.name]: group.forecast[i].expectedValue }
        });
        return result;
    });

    var areas = groupResults.map((g, i) => <Area key={i} animationDuration={500} type="monotone" dataKey={g.name} stackId="1" strokeWidth={2} strokeOpacity={1} stroke={"url(#color" + i + ")"} fillOpacity={0.7} fill={"url(#color" + i + ")"} />)

    return (<div style={{ height: '14rem' }}>
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData} margin={{
                top: 20, right: 20, left: 23, bottom: 2,
            }}>
                <defs>
                    <linearGradient id="color0" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.spendingItems.interestPayment} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.spendingItems.interestPayment} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.spendingItems.investmentAmount} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.spendingItems.investmentAmount} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.spendingItems.monthlyAmortisation} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.spendingItems.monthlyAmortisation} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.spendingItems.taxes} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.spendingItems.taxes} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.spendingItems.ordinaryConsumption} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.spendingItems.ordinaryConsumption} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.spendingItems.maintenanceCost} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.spendingItems.maintenanceCost} stopOpacity={1} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeOpacity={0.3} vertical={false} />
                <XAxis tick={{ fontSize: 10 }} ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]} interval={'preserveStartEnd'} dataKey="name" />
                <YAxis width={35} type="number" tick={<CustomizedAxisTick />} />
                {areas}
                {/* <Area animationDuration={500} type="monotone" dataKey="firstGroup" stackId="1" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill="#1a6b9d" />
                <Area animationDuration={500} type="monotone" dataKey="secondGroup" stackId="1" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill="#8fc1d9" /> */}
                <ReferenceLine stroke="#333333" strokeDasharray="3 3" y={0} />
                <Legend iconType='square' align='center' layout='vertical' verticalAlign='bottom'></Legend>
            </AreaChart>
        </ResponsiveContainer>
    </div>);
}


function CustomizedAxisTick(props) {
    const {
        x, y, payload,
    } = props;

    return (
        <>
            <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: 10 }}
                renderText={value =>
                    <g transform={`translate(${x},${y})`}>
                        <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: 10 }} fill="#666" >{value}</text>
                    </g>
                }
            />

        </>
    );
}

export function areEqual(prevProps, nextProps) {
    var prevProps2 = Object.assign({}, prevProps);
    delete prevProps2.targetLabel;

    var nextProps2 = Object.assign({}, nextProps);
    delete nextProps2.targetLabel;

    if (JSON.stringify(prevProps2) === JSON.stringify(nextProps2)) {

        return true;
    }
    return false;
}

export default React.memo(GroupResultsChart, areEqual);
