import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Popover, Link, Divider, useTheme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PieChart from '@material-ui/icons/PieChart';
import HelpIcon from '@material-ui/icons/Help';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';
import MoreIcon from '@material-ui/icons/MoreVert';
import { SettingsContext } from './SettingsContext';
import CustomerProfile from './CustomerProfile';
import MortgagePlanning from './MortgagePlanning';
import LifeEvents from './LifeEvents';
import { styleDelegate, maxContentWidth } from './useStyles';
import InvestmentSavingsGoal from './InvestmentSavingsGoal';
import PortfolioBuilder from './PortfolioBuilder';


export default function PensionAdviceAppBar(props) {
  const classes = makeStyles(styleDelegate)();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const settings = useContext(SettingsContext);
  const theme = useTheme();

  const { title } = props;

  // const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    settings.gotoToFullScreenComponent(<CustomerProfile />);
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';


  const mobileMenuId = 'primary-search-account-menu-mobile';

  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const open = Boolean(anchorElPopover);

  const id = open ? 'simple-popover' : undefined;


  const handleClick = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElPopover(null);
  };


  const [anchorElCurrency, setAnchorElCurrency] = React.useState(null);
  const handleClickCurrency = (event) => {
    setAnchorElCurrency(event.currentTarget);
  };

  const handleCloseCurrency = () => {
    setAnchorElCurrency(null);
  };

  const [anchorElScenarioSet, setAnchorElScenarioSet] = React.useState(null);

  const handleClickScenarioSet = (event) => {
    setAnchorElScenarioSet(event.currentTarget);
  };

  const handleCloseScenarioSet = () => {
    setAnchorElScenarioSet(null);
  };

  const helpMarkup = () => {
    return (<Typography className={classes.popover}>
      This app demonstrates how to use OutRank to plan for retirement using the the Swedish pension system.
      More info <Link href='https://www.pensionsmyndigheten.se/other-languages/english-engelska/english-engelska/your-pension-is-made-up-of-several-components' target="_blank" rel="noopener" color='inherit' underline='always'>here</Link>.
      <br />
      <br />
      Salary and pension amounts are expressed as monthly payments before taxes.
      <br />
      <br />
      "Pension Capital" refers to the total amount of accumulated capital for the different parts of the pension system. This information is available at <Link href='https://www.minpension.se/allt-om-pensioner/pensionsprognos/se-din-intjanade-pension' target="_blank" rel="noopener" color='inherit' underline='always'>Min Pension</Link> (information in Swedish).
    </Typography>)
  }

  const currencies = settings.availableCurrencies;
  const scenarioSets = settings.availableScenarioSets;
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen} 
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => settings.gotoToFullScreenComponent(<MortgagePlanning />)}>
        Mortgage
      </MenuItem>
      <MenuItem onClick={() => settings.gotoToFullScreenComponent(<InvestmentSavingsGoal />)}>
        Investment
      </MenuItem>
      <MenuItem onClick={() => settings.gotoToFullScreenComponent(<LifeEvents />)}>
        Life Events
      </MenuItem>
      <MenuItem onClick={() => settings.gotoToFullScreenComponent(<PortfolioBuilder />)}>
        Portfolio Builder
      </MenuItem>
      <Divider></Divider>
      <MenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickCurrency}>
        <IconButton className={classes.barButton} color='inherit' >
          <Typography variant='body2'> {settings.currency}</Typography>
        </IconButton>
        Currency
      </MenuItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorElCurrency}
        keepMounted
        open={Boolean(anchorElCurrency)}
        onClose={handleCloseCurrency}
      >
        {currencies.map(c => (<MenuItem key={c} onClick={() => {
          settings.setCurrency(c);
          handleCloseCurrency();
        }}>{c}</MenuItem>))}
      </Menu>
      <MenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickScenarioSet}>
        <IconButton className={classes.barButton} color='inherit'>
          <Typography variant='body2'> {settings.currentScenarioSet.name.substring(0, 3) + '...'}</Typography>
        </IconButton>
        Scenario Set
      </MenuItem>
      <Menu
        id="scenario-sets-menu"
        anchorEl={anchorElScenarioSet}
        keepMounted
        open={Boolean(anchorElScenarioSet)}
        onClose={handleCloseScenarioSet}
      >
        {scenarioSets.map(c => (<MenuItem key={c.id} onClick={() => {
          settings.setCurrentScenarioSet(c);
          handleCloseScenarioSet();
        }}>{c.name}</MenuItem>))}
      </Menu>
      <MenuItem onClick={() => {
        settings.setTheme(!settings.isLightTheme);
      }}>
        <IconButton className={classes.barButton} aria-describedby={id} aria-label="darkmode" color="inherit">
          <SettingsBrightnessIcon />
        </IconButton>
        {settings.isLightTheme ? 'Dark Mode' : 'Light Mode'}
      </MenuItem>
      <MenuItem aria-describedby={id} aria-label="help" onClick={handleClick}>
        <IconButton className={classes.barButton} color="inherit">
          <HelpIcon />
        </IconButton>
        Help
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElPopover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {helpMarkup()}
      </Popover>
    </Menu>
  );

  const backgroundColor = settings.isLightTheme ? theme.palette.primary.main : theme.palette.grey[900]; // TODO move to withStyles or something

  return (
    <div className={classes.grow}>
      <AppBar style={{ background: backgroundColor }} className={classes.appBar} position="static">
        <Toolbar disableGutters={true} style={{ margin: 'auto', maxWidth: maxContentWidth, width: '100%' }}>
          
          <Grid className={classes.appTitle} direction="row" alignItems="center" container>
            <Grid item><PieChart style={{ paddingRight: '0.5rem', paddingTop: '2px' }} /></Grid>
            <Grid item><Typography variant="body1">{title}</Typography></Grid>
          </Grid>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit">
              <AccountCircle />
            </IconButton>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {/* {renderMenu} */}
    </div>
  );
}