import React, { useContext } from 'react';
import NumberFormat from 'react-number-format';
import { SettingsContext } from './SettingsContext';

function getThousandSeparator(currency) {
  if (['GBP', 'USD'].includes(currency)) return ',';
  if (currency === 'NOK') return '.';
  return ' ';
}

function getDecimalSeparator(currency) {
  if (['GBP', 'USD'].includes(currency)) return '.';
  return ',';
}

function getPrefix(currency) {
  if (currency === "EUR") return '€';
  if (currency === "GBP") return '£';
  if (currency === "USD") return '$';
  return currency + ' ';
}

export function NumberFormatCustom(props) {
  const settings = useContext(SettingsContext);

  const { inputRef, onChange, ...other } = props;
  return (<NumberFormat {...other} getInputRef={inputRef} onValueChange={values => {
    onChange({
      target: {
        value: values.value,
      },
    });
  }}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    decimalScale="2"
    isNumericString />);
}

export function CurrencyFormatCustom(props) {
  const settings = useContext(SettingsContext);

  const { inputRef, onChange, ...other } = props;
  return (<NumberFormat {...other} getInputRef={inputRef} onValueChange={values => {
    onChange({
      target: {
        value: values.value,
      },
    });
  }}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    prefix={getPrefix(settings.currency)}
    decimalScale="2"
    isNumericString />);
}

export function PercentFormatCustom(props) {
  const settings = useContext(SettingsContext);

  const { inputRef, onChange, ...other } = props;
  return (<NumberFormat {...other} getInputRef={inputRef} onValueChange={values => {
    onChange({
      target: {
        value: values.value,
      },
    });
  }}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    decimalScale="2"
    suffix='%'
    isNumericString />);
}


export function TextNumberFormat(props) {
  const settings = useContext(SettingsContext);

  const { value, disableRounding, roundToNearest, hidePrefix, decimals, ...other } = props;
  const roundToNearestValue = roundToNearest === undefined ? 1000 : roundToNearest;
  
  let roundedValue = (disableRounding ? value : (Math.round(value / roundToNearestValue) * roundToNearestValue)).toFixed(decimals ?? 0);
  return (<NumberFormat value={roundedValue} {...other}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    displayType={'text'}
    decimalScale="2"
    prefix={hidePrefix ? undefined : getPrefix(settings.currency)}
    isNumericString />);
}
