import Color from 'color';

export const maxContentWidth = 1000;

export const styleDelegate = theme => {

    return ({
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(100,100,100,.5)',
                outline: '1px solid slategrey'
            }
        },
        siteFooter: {
            backgroundImage: 'url(../images/footer-background.png)', // TODO
            backgroundPosition: 'top right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative'
        },
        backgroundContainer: {
            minHeight: '100vh',
        },
        stepper: {
            maxWidth: maxContentWidth,
            width: '100%',
            margin: 'auto'
        },
        backgroundContainerOnboarding: {
            background: theme.palette.background.default,
            padding: theme.spacing(2),
            textAlign: 'center',
            // minHeight: '100vh',
            height: '100vh',
            maxHeight: '-webkit-fill-available',
            maxWidth: maxContentWidth,
            flexGrow: '1',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column'
        },
        stepperContainer: {
            bottom: 0,
            position: 'sticky',
            maxWidth: maxContentWidth
        },
        stepperContent: {
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0
        },
        paperContainer: {
            maxWidth: maxContentWidth,
            paddingBottom: theme.spacing(1),
            paddingTop: 0,
            textAlign: "center",
            height: "100%",
            margin: 'auto',
            flexGrow: 1,
            overflowX: 'hidden',
            overflow: 'hidden'
        },
        paper: {
            textAlign: 'left',
            height: "100%",
            color: theme.palette.text.secondary,
        },
        card: {
            textAlign: 'left',
            height: "100%",
            color: theme.palette.text.secondary,
        },
        formContainer: {
            textAlign: 'left',
            height: "100%",
            padding: theme.spacing(2),
        },
        spacedGridContainer: {
            width: '100%',
            overflowX: 'hidden',
            overflow: 'hidden'
        },
        cardHeaderPadding: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        title: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        },
        customTable: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
        },
        mobileDivider: {
            display: 'block',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        fab: {
            marginBottom: theme.spacing(1),
        },
        resultStickyContainer: {
            bottom: 0,
            position: 'sticky',
            maxWidth: maxContentWidth
        },
        resultContainer: {
            maxWidth: maxContentWidth
        },
        questionList: {
            "&.Mui-selected": {
                outline: '2px solid',
                outlineOffset: -2
            },
            color: Color(theme.palette.text.primary).alpha(0.87).string()
        },
        questionTitle: {
            color: Color(theme.palette.text.primary).alpha(0.8).string()
        },
        grow: {
            flexGrow: 1,
            width: '100%',
        },
        appBar: {
            color: theme.palette.grey[50],
            marginBottom: 5
        },
        appTitle: {
            marginLeft: theme.spacing(2),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        barButton: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            paddingTop: 0,
            paddingBottom: 0,
            width: theme.spacing(7)
        },
        popover: {
            padding: theme.spacing(2),
        },
        inputRoot: {
            color: 'inherit',
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
        },
        smallText: {
            fontSize: 12,
        }
    })
};
