import React from 'react';

export const CustomerAssetsContext = React.createContext({
    estimateCapital: true,
    setEstimateCapital: () => { },
    oneTimeDeposit: '',
    setOneTimeDeposit: () => { },
    pensionCapital: {
        nationalIncome: '',
        nationalPremium: '',
        occupational: ''
    },
    setPensionCapital: () => { },
    existingPrivatePensionCapital: '',
    setExistingPrivatePensionCapital: () => { },
    downPaymentFunds: '',
    setDownPaymentFunds: () => { },
    zeroRateMortgageAmount: '',
    setZeroRateMortgageAmount: () => { },
    valueOfResidence: '',
    setValueOfResidence: () => { },
    typeOfRealEstate: '',
    setTypeOfRealEstate: () => { },
    mortgageAmount: '',
    setMortgageAmount: () => { },
    mortgageInterestRate: 0,
    setMortgageInterestRate: () => { },
    monthlyInterestPayment: 0,
    investmentPortfolio: [{ code: '', codeType: '', name: '', weight: 1 }],
    setInvestmentPortfolio: () => { }
});

