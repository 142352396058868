import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { AppContainer } from './AppContainer';

// TODO
// components:
//  PersonalInformation
//  RiskProfile
//  RetirementSavings
//  RetirementGoals (lower prio)
//  PensionResult (lower prio)

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <AppContainer />
  </MuiPickersUtilsProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
