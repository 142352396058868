import React from 'react';

export const BasicCustomerInformationContext = React.createContext({
    grossSalary: '',
    setGrossSalary: () => { },
    monthlySpending: '',
    setMonthlySpending: () => { },
    dateOfBirth: '',
    setDateOfBirth: () => { },
    numberOfAdults: 1, // TODO, count number of BasicCustomerInformationContext (or something) instead
    setNumberOfAdults: () => { },
    numberOfChildren: 0, // TODO, count number of BasicCustomerInformationContext (or something) (children are also people...)
    setNumberOfChildren: () => { },
    municipalTax: 0,
    stateTax: 0,
    taxReduction: 0,
    netSalary: 0,
    ordinaryConsumption: 0,
    isValid: false
});


