import React, {useState} from 'react';
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from './appConstants';
import { useAppColors } from './useAppColors';

function PensionFanChart(props) {


    const { currentAge, portfolioDevelopmentTimeSeries } = props;
    const colors = useAppColors();

    const narrowOutcomeLowerBound = portfolioDevelopmentTimeSeries.find(s => s.percentile === narrowOutcomePercentiles[0]);
    const narrowOutcomeUpperBound = portfolioDevelopmentTimeSeries.find(s => s.percentile === narrowOutcomePercentiles[1]);
    const mediumOutcomeLowerBound = portfolioDevelopmentTimeSeries.find(s => s.percentile === mediumOutcomePercentiles[0]);
    const mediumOutcomeUpperBound = portfolioDevelopmentTimeSeries.find(s => s.percentile === mediumOutcomePercentiles[1]);
    const wideOutcomeLowerBound = portfolioDevelopmentTimeSeries.find(s => s.percentile === wideOutcomePercentiles[0]);
    const wideOutcomeUpperBound = portfolioDevelopmentTimeSeries.find(s => s.percentile === wideOutcomePercentiles[1]);

    const highestValue = wideOutcomeUpperBound.outcomes[wideOutcomeUpperBound.outcomes.length - 1].value;

    const maxAsSingleDigit = highestValue / Math.pow(10, Math.floor(Math.log10(highestValue)));
    const maxValue = (maxAsSingleDigit < 5 ? Math.ceil(maxAsSingleDigit) : 10) * Math.pow(10, Math.floor(Math.log10(highestValue))) / 1000000;

    if (portfolioDevelopmentTimeSeries[0].outcomes[0].value === 0) return (<></>);

    const chartData = narrowOutcomeLowerBound.outcomes.map((o, i) => {
        return {
            name: '' + (currentAge + i),
            narrow: [narrowOutcomeLowerBound.outcomes[i].value / 1000000, narrowOutcomeUpperBound.outcomes[i].value / 1000000],
            medium: [mediumOutcomeLowerBound.outcomes[i].value / 1000000, mediumOutcomeUpperBound.outcomes[i].value / 1000000],
            wide: [wideOutcomeLowerBound.outcomes[i].value / 1000000, wideOutcomeUpperBound.outcomes[i].value / 1000000],
        };
    });


    return (<div style={{ height: '10rem' }}>
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData} margin={{
                top: 20, right: 20, left: 10, bottom: 2,
            }}>
                <XAxis fontFamily={'Roboto, sans-serif'} dataKey="name" />
                <YAxis width={30} type="number" domain={[0, maxValue]} />
                <Area animationDuration={500} type="monotone" dataKey="wide" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill={colors.fanChart.wide} />
                <Area animationDuration={500} type="monotone" dataKey="medium" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill={colors.fanChart.medium} />
                <Area animationDuration={500} type="monotone" dataKey="narrow" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill={colors.fanChart.narrow} />
            </AreaChart>
        </ResponsiveContainer>
    </div>);
}

export function areEqual(prevProps, nextProps) {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

export default React.memo(PensionFanChart, areEqual);
