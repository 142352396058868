import React from 'react';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { TextNumberFormat } from './NumberFormatCustom';


export function BreakdownItem(props) {
    const { item, theme } = props;
    return (<div style={{ display: 'flex', height: '3.5rem', marginTop: 4 }}>
        <SubdirectoryArrowRight style={{ paddingLeft: 1, marginRight: 5, marginTop: theme.spacing(1), opacity: 0.3 }} />
        <div style={{ flexGrow: '1' }}>
            {item}
        </div>
    </div>);
}

export function BreakdownItemSlim(props) {
    const { title, value } = props;
    return (<div style={{ display: 'flex', marginTop: 2 }}>
        <SubdirectoryArrowRight style={{ paddingLeft: 1, marginRight: 5, marginTop: 0, opacity: 0.3, width: 15 }} />
        <div style={{ alignItems: 'flex-end', flexGrow: '1' }}>
            <div style={{ display: 'flex' }}>
<Typography display='inline' style={{ paddingTop: 3, flexGrow: '1' }} variant='body2' color='textSecondary'>{title}</Typography>
                <Typography display='inline' style={{ textAlign: 'right' }} variant='body1' color='textPrimary'><TextNumberFormat roundToNearest={1} value={value} /></Typography>
            </div>
        </div>
    </div>);
}
