import React, { useState, useEffect, useContext, useRef } from 'react';
import { getEstimations, getAdvice, grossToNet } from './api_client/forecastClient';
import { calculateMonthlyInterestPayment, calculateMinMortgagePayment } from './mortgageFunctions';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, LinearProgress, CircularProgress, Collapse, Paper, Slider, Typography, FormGroup, FormControl, Grid, TextField, Chip, Divider, Tooltip } from '@material-ui/core';
import { Element, scroller } from 'react-scroll'
import PensionAdviceAppBar from './PensionAdviceAppBar'
import { House, Apartment, Launch, Warning, Info, Error } from '@material-ui/icons';
import { TextNumberFormat, NumberFormatCustom, PercentFormatCustom } from './NumberFormatCustom'
import moment from "moment";
import { styleDelegate } from './useStyles';
import PensionFanChart from './PensionFanChart';
import SpendingCircleChart from './SpendingCircleChart';
import InvestmentGoalFanChart from './InvestmentGoalFanChart';
import { SettingsContext } from './SettingsContext';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { CustomerAssetsContext } from './CustomerAssetsContext';
import { InvestmentGoalContext } from './InvestmentGoalContext'
import { RiskProfileContext } from './RiskProfileContext';
import { RetirementGoalContext } from './RetirementGoalContext';
import { MortgagePlanningContext } from './MortgagePlanningContext';
import { usePrevious } from './usePrevious';
import { useDebounce } from './useDebounce';
import { wideOutcomePercentiles } from './appConstants';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useAppColors } from './useAppColors';
import { Rating } from '@material-ui/lab';
import { BreakdownItem } from './BreakdownItem';
import { financialPlanning, getDefaultInvestmentGoalPlanningResult, investmentAccount, createMortgageRequest, mortgageAssetsAndDebtsItemGroups, getSimpleIncomeTrajectory } from './api_client/tsClient';
import { calculateTotalSpending } from './budgetFunctions';
import GroupResultsChart from './GroupResultsChart';
import IncomeBarChart from './IncomeBarChart';

moment.locale("se");

// TODO
// 2. replace pension with financial planning
// 3. use only 1 effect

const mortgageTerms = [
  {
    title: 'Low Risk',
    interestRate: 1.83,
    mortgageTerm: '40 yrs',
    fixedRateTerm: '10 yrs',
    riskOfDefault: 'Low',
    fixedRatePeriodInMonths: 120
  },
  {
    title: 'Medium Risk',
    interestRate: 1.67,
    mortgageTerm: '40 yrs',
    fixedRateTerm: '5 yrs',
    riskOfDefault: 'Low',
    fixedRatePeriodInMonths: 60
  },
  {
    title: 'High Risk',
    interestRate: 1.58,
    mortgageTerm: '40 yrs',
    fixedRateTerm: '1 yrs',
    riskOfDefault: 'Medium',
    fixedRatePeriodInMonths: 12
  },
];

function MortgagePlanning() {

  const classes = makeStyles(styleDelegate)();
  const theme = useTheme();
  const colors = useAppColors();

  const settings = useContext(SettingsContext);
  const basicCustomerInformation = useContext(BasicCustomerInformationContext);
  const customerAssets = useContext(CustomerAssetsContext);
  const riskProfile = useContext(RiskProfileContext);
  const retirementGoal = useContext(RetirementGoalContext);
  const mortgagePlanning = useContext(MortgagePlanningContext);
  const investmentGoal = useContext(InvestmentGoalContext);

  const debouncedMortgagePlanning = useDebounce(mortgagePlanning, 800);
  const debouncedInvestmentGoal = useDebounce(investmentGoal, 800);
  const debouncedInputState = useDebounce(retirementGoal, 800);
  const debouncedBasicCustomerInformation = useDebounce(basicCustomerInformation, 800);
  const debouncedCustomerAssets = useDebounce(customerAssets, 800);
  const { currentScenarioSet } = settings;

  // const [estimatedState, setEstimatedState] = useState(defaultEstimatedState);

  const [resultState, setResultState] = useState([{ portfolioResult: getDefaultInvestmentGoalPlanningResult(), groupResults: [] }]);
  const [incomeTrajectory, setIncomeTrajectory] = useState([{ in: 0, out: 0, net: 0 }]);

  const [numberOfRequestInFlight, setNumberOfRequestInFlight] = useState(0);

  const availableMonthlySpending = (basicCustomerInformation.netSalary - basicCustomerInformation.ordinaryConsumption - mortgagePlanning.maintenanceCost) < 0
    ? 0
    : Math.round(basicCustomerInformation.netSalary - basicCustomerInformation.ordinaryConsumption - mortgagePlanning.maintenanceCost);

  const { mortgageInterestRate, setMortgageInterestRate } = customerAssets;

  const availableMonthlySpendingAfterInterestPayment = availableMonthlySpending === 0 ? 0 : availableMonthlySpending - customerAssets.monthlyInterestPayment;

  const minMortgagePayment = calculateMinMortgagePayment(customerAssets.valueOfResidence, customerAssets.mortgageAmount, basicCustomerInformation.grossSalary);
  const debouncedMinMortgagePayment = useDebounce(minMortgagePayment, 800);

  const prevMinMortgagePayment = usePrevious(minMortgagePayment);


  const setMonthlyAlternativeInvestmentAmount = (value) => {

    if (availableMonthlySpendingAfterInterestPayment === undefined) return;

    const newValue = Number(value) > availableMonthlySpendingAfterInterestPayment
      ? availableMonthlySpendingAfterInterestPayment
      : Number(value) < 0
        ? 0
        : Number(value);

    investmentGoal.setMonthlyInvestmentAmount(newValue);

  }

  const setMonthlyMortgagePaymentAmount = (value) => {

    if (availableMonthlySpendingAfterInterestPayment === undefined) return;

    const newValue = Number(value);

    if (mortgagePlanning.monthlyMortgagePaymentAmount !== newValue)
      mortgagePlanning.setMonthlyMortgagePaymentAmount(newValue);

    if (newValue + Number(investmentGoal.monthlyInvestmentAmount) > availableMonthlySpendingAfterInterestPayment) {
      investmentGoal.setMonthlyInvestmentAmount(availableMonthlySpendingAfterInterestPayment - newValue);
    }

  }

  useEffect(() => {
    if (minMortgagePayment !== prevMinMortgagePayment) {
      mortgagePlanning.setMonthlyMortgagePaymentAmount(minMortgagePayment);
    }
  }, [minMortgagePayment, prevMinMortgagePayment, mortgagePlanning]);


  const bufferDeposit = Number(availableMonthlySpending) - (Number(investmentGoal.monthlyInvestmentAmount) + Number(mortgagePlanning.monthlyMortgagePaymentAmount) + customerAssets.monthlyInterestPayment);
  const bufferDepositNoInterestPaymentDrawn = Number(availableMonthlySpending) - (Number(investmentGoal.monthlyInvestmentAmount) + Number(mortgagePlanning.monthlyMortgagePaymentAmount));

  const debouncedBufferDepositNoInterestPaymentDrawn = useDebounce(bufferDepositNoInterestPaymentDrawn, 800);

  useEffect(() => {
    if (Number(customerAssets.mortgageAmount) !== Number(customerAssets.valueOfResidence) - Number(customerAssets.downPaymentFunds) - Number(customerAssets.zeroRateMortgageAmount))
      customerAssets.setMortgageAmount(Number(customerAssets.valueOfResidence) - Number(customerAssets.downPaymentFunds) - Number(customerAssets.zeroRateMortgageAmount));
    if (Number(customerAssets.downPaymentFunds) > Number(customerAssets.valueOfResidence))
      customerAssets.setDownPaymentFunds(customerAssets.valueOfResidence);
    if (Number(customerAssets.downPaymentFunds) + Number(customerAssets.zeroRateMortgageAmount) > Number(customerAssets.valueOfResidence))
      customerAssets.setZeroRateMortgageAmount(Number(customerAssets.valueOfResidence) - Number(customerAssets.downPaymentFunds));
    if (Number(customerAssets.zeroRateMortgageAmount) < 0)
      customerAssets.setZeroRateMortgageAmount(0);
  }, [customerAssets]);



  const budgetData = [
    { name: 'Taxes', value: (basicCustomerInformation.municipalTax + basicCustomerInformation.stateTax) - basicCustomerInformation.taxReduction, color: colors.spendingItems.taxes },
    { name: 'Ordinary Consumption', value: basicCustomerInformation.ordinaryConsumption, color: colors.spendingItems.ordinaryConsumption },
    { name: 'Maintenance Cost', value: mortgagePlanning.maintenanceCost, color: colors.spendingItems.maintenanceCost },
    { name: 'Interest Payment', value: customerAssets.monthlyInterestPayment, color: colors.spendingItems.interestPayment },
    { name: 'Monthly Amortisation', value: mortgagePlanning.monthlyMortgagePaymentAmount, color: colors.spendingItems.monthlyAmortisation },
    { name: 'Investment Amount', value: investmentGoal.monthlyInvestmentAmount, color: colors.spendingItems.investmentAmount },
    { name: 'Remaining Funds', value: bufferDeposit, color: colors.spendingItems.personalAccount },
  ];

  useEffect(() => {
    setNumberOfRequestInFlight(state => state + 1);
    getSimpleIncomeTrajectory(basicCustomerInformation.grossSalary, new Date(basicCustomerInformation.dateOfBirth), calculateTotalSpending(mortgagePlanning, basicCustomerInformation, customerAssets))
      .then(result => {
        try {
          setIncomeTrajectory(result);
        } finally {
          setNumberOfRequestInFlight(state => state - 1);
        }
      });
  }, [basicCustomerInformation, mortgagePlanning, customerAssets])

  useEffect(() => {
    if(currentScenarioSet.id === '') return;
    console.log('sdfsdf ' +currentScenarioSet.id);
    setNumberOfRequestInFlight(state => state + 1);

    const portfolioPromises = mortgageTerms.map((p, i) => {
      const request = createMortgageRequest(
        debouncedCustomerAssets.valueOfResidence,
        debouncedCustomerAssets.mortgageAmount,
        debouncedMortgagePlanning.monthlyMortgagePaymentAmount,
        debouncedInvestmentGoal.monthlyInvestmentAmount,
        debouncedBufferDepositNoInterestPaymentDrawn,
        i,
        p.fixedRatePeriodInMonths,
        debouncedMinMortgagePayment,
        p.interestRate / 100
      );
      return financialPlanning(
        currentScenarioSet.id,
        request.horizonInYears,
        request.transactions,
        request.assetsAndDebts,
        request.mortgageAssetsAndDebtsItemGroups
      )
    }
    )

    Promise.all(portfolioPromises)
      .then(result => {
        try {
          const resultWithGroups = result.map(r => (
            {
              portfolioResult: r,
              groupResults: r.financialPlanningResponseModel.groupResults.map(gr => ({ ...gr, name: mortgageAssetsAndDebtsItemGroups.find(g => g.id === gr.groupId).name }))
            })
          );
          setResultState(resultWithGroups);
        } finally {
          setNumberOfRequestInFlight(state => state - 1);
        }
      });
  }, [
    debouncedCustomerAssets.valueOfResidence,
    debouncedCustomerAssets.mortgageAmount,
    debouncedMortgagePlanning.monthlyMortgagePaymentAmount,
    debouncedInvestmentGoal.monthlyInvestmentAmount,
    debouncedBufferDepositNoInterestPaymentDrawn,
    debouncedMinMortgagePayment,
    currentScenarioSet
  ]);

  return <MortgagePlanningViewMemo mortgageTerms={mortgageTerms}
    resultState={resultState}
    incomeTrajectory={incomeTrajectory}
    budgetData={budgetData}
    settings={settings}
    customerAssets={customerAssets}
    availableMonthlySpending={availableMonthlySpending}
    mortgageInterestRate={mortgageInterestRate}
    setMortgageInterestRate={setMortgageInterestRate}
    mortgagePlanning={mortgagePlanning}
    minMortgagePayment={minMortgagePayment}
    setMonthlyMortgagePaymentAmount={setMonthlyMortgagePaymentAmount}
    bufferDeposit={bufferDeposit}
    numberOfRequestInFlight={numberOfRequestInFlight}
    setMonthlyAlternativeInvestmentAmount={setMonthlyAlternativeInvestmentAmount}
    investmentGoal={investmentGoal} />;

}

function MortgagePlanningView({ mortgageTerms,
  resultState,
  incomeTrajectory,
  budgetData,
  settings,
  customerAssets,
  availableMonthlySpending,
  mortgageInterestRate,
  setMortgageInterestRate,
  mortgagePlanning,
  minMortgagePayment,
  setMonthlyMortgagePaymentAmount,
  bufferDeposit,
  numberOfRequestInFlight,
  setMonthlyAlternativeInvestmentAmount,
  investmentGoal
}) {
  const theme = useTheme();
  const classes = makeStyles(styleDelegate)();



  const renderAlternative = (result, incomeTrajectory, i) => {
    const currentMortgageTerms = mortgageTerms[i];
    const portfolio = result.portfolioResult.financialPlanningResponseModel;
    const groupResults = result.groupResults;

    return (<>
      <Element name="resultSticky" style={{ paddingTop: theme.spacing(1) }}>
        <div>
          <Typography style={{ paddingLeft: theme.spacing(2) }} color='textSecondary' display="inline">Alternative {i + 1}: </Typography><Typography color='textPrimary' display="inline"><b>{currentMortgageTerms.title}</b></Typography>
          <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
        </div>
        {/* </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}> */}
        <Collapse in={portfolio.percentileForecast.length > 1} timeout='auto'>
          <div>
            <div style={{ marginBottom: theme.spacing(1), paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }} >
              <Typography color='textSecondary' style={{ marginBottom: theme.spacing(1) }} variant='body1'>Detailed Information</Typography>
              <Grid container orientation="row">
                <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Interest Rate</Typography></Grid>
                <Grid xs={7} item><Typography variant='body2'>{currentMortgageTerms.interestRate} %</Typography></Grid>
                <Grid xs={1} item style={{ textAlign: 'right' }}></Grid>
                <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Mortgage Term</Typography></Grid>
                <Grid xs={7} item><Typography variant='body2'>{currentMortgageTerms.mortgageTerm}</Typography></Grid>
                <Grid xs={1} item style={{ textAlign: 'right' }}></Grid>
                <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Fixed Rate Term</Typography></Grid>
                <Grid xs={7} item><Typography variant='body2'>{currentMortgageTerms.fixedRateTerm}</Typography></Grid>
                <Grid xs={1} item style={{ textAlign: 'right' }}><Typography variant='body2'><Info fontSize={'inherit'} /></Typography></Grid>
                <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Risk of Default</Typography></Grid>
                <Grid xs={7} item><Typography variant='body2'>{currentMortgageTerms.riskOfDefault}</Typography></Grid>
                <Grid xs={1} item style={{ textAlign: 'right' }}>{currentMortgageTerms.riskOfDefault === 'Medium' ? (<Typography variant='body2'><Warning fontSize={'inherit'} /></Typography>) : (<></>)}</Grid>
                {/* <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Utility Score</Typography></Grid>
                <Grid xs={7} item><Rating name={'u' + portfolio.utilityScore} size='small' value={portfolio.utilityScore} readOnly /></Grid>
                <Grid xs={1} item style={{ textAlign: 'right' }}></Grid> */}
                <Grid xs={12} item><Divider></Divider></Grid>
                <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Investment</Typography></Grid>
                <Grid xs={7} item><Typography variant='body2'>{currentMortgageTerms.title} Fund</Typography></Grid>
                {/* TODO */}
                {/* <Grid xs={7} item><Typography variant='body2'>{portfolio.recommendedInvestment.name}</Typography></Grid> */}
                <Grid xs={1} item style={{ textAlign: 'right' }}><Typography variant='body2'><Launch fontSize={'inherit'} /></Typography></Grid>
                <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Account Type</Typography></Grid>
                <Grid xs={8} item><Typography variant='body2'>ISK</Typography></Grid>
                {/* TODO */}
                {/* <Grid xs={8} item><Typography variant='body2'>{portfolio.recommendedInvestment.taxationType}</Typography></Grid> */}
                <Grid xs={12} item><Divider></Divider></Grid>
              </Grid>
            </div>
          </div>
          <div>
            {/* </Grid>
            <Grid item xs={12} sm={12} style={{ width: '100%', height: '100%', maxWidth: 400, textAlign: 'center' }}> */}
            <Typography style={{ paddingLeft: theme.spacing(2) }} color='textSecondary' variant='body1'>Net Asset Value</Typography>
            {/* <PensionFanChart currentAge={41} portfolioDevelopmentTimeSeries={portfolio.percentileForecast} /> */}
            <div style={{ paddingLeft: theme.spacing(2) }}><InvestmentGoalFanChart currentAge={0} portfolioDevelopmentTimeSeries={portfolio.percentileForecast} horizon={20} /></div>
            <div style={{ paddingLeft: theme.spacing(2) }}><GroupResultsChart groupResults={groupResults} horizon={20} /></div>
            <div style={{ paddingLeft: theme.spacing(2) }}><IncomeBarChart cashFlows={incomeTrajectory} horizon={20} /></div>
            <Typography style={{ paddingLeft: theme.spacing(2) }} color='textSecondary' variant='body1'>Budget Breakdown</Typography>
            {portfolio.percentileForecast.length > 1 ? <SpendingCircleChart spendingItemData={budgetData} /> : <></>}
            {/* </Grid>
          </Grid> */}
          </div>
        </Collapse>
      </Element>
    </>)
  };

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3 //resultState.rankedPortfolios.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.backgroundContainer}>
      <Grid container direction="column" justify="flex-start" alignItems='stretch' style={{ minHeight: '100vh' }}>
        <Grid item>
          <PensionAdviceAppBar title='Mortgage Planning' />
        </Grid>
        <Grid item style={{ minHeight: '100%', maxWidth: '100%' }}>
          <Grid container spacing={0} className={classes.paperContainer} style={{ textAlign: 'left', paddingTop: theme.spacing(2) }} direction="row" alignItems='stretch' justify='center'>
            <Grid xs={12} sm={6} item>
              <FormControl style={{ height: '100%', width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }} component="fieldset" autoComplete="off">
                {/* <Paper square className={classes.paper}> */}
                <FormGroup style={{ margin: 0 }}>
                  {/* <Grid container spacing={0} className={classes.formContainer}> */}
                  {/* <Grid item xs={12} sm={12}> */}
                  <div style={{ marginBottom: theme.spacing(1.5) }}>
                    <Typography variant='body2' color='textSecondary'>Type of Property</Typography>
                    <Chip
                      icon={<House fontSize='inherit' />}
                      label="House"
                      size="small"
                      clickable
                      color={customerAssets.typeOfRealEstate === 'House' ? 'primary' : 'default'}
                      onClick={() => { customerAssets.setTypeOfRealEstate('House') }}
                      style={{ marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}
                    />
                    <Chip
                      icon={<Apartment fontSize='inherit' />}
                      label="Apartment"
                      size="small"
                      clickable
                      color={customerAssets.typeOfRealEstate === 'Apartment' ? 'primary' : 'default'}
                      onClick={() => { customerAssets.setTypeOfRealEstate('Apartment') }}
                      style={{ marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}
                    />
                  </div>
                  <div>
                    <TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} variant='filled' size='small' required label="Value of Property" onChange={(e) => { customerAssets.setValueOfResidence(e.target.value) }} value={customerAssets.valueOfResidence} />
                    <Slider
                      min={0}
                      max={15000000}
                      value={Number(customerAssets.valueOfResidence)}
                      onChange={(e, v) => { customerAssets.setValueOfResidence(Number(v)) }}
                      aria-labelledby="input-slider" />
                  </div>
                  <div>
                    <TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} variant='filled' size='small' required label="Down Payment" onChange={(e) => { customerAssets.setDownPaymentFunds(e.target.value) }} value={customerAssets.downPaymentFunds} />
                    <Slider
                      min={0}
                      max={15000000}
                      value={Number(customerAssets.downPaymentFunds)}
                      onChange={(e, v) => { customerAssets.setDownPaymentFunds(Number(v)) }}
                      aria-labelledby="input-slider" />
                  </div>
                  {/* <div>
                    <TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} variant='filled' size='small' required label="Zero-rate Amount" onChange={(e) => { customerAssets.setZeroRateMortgageAmount(e.target.value) }} value={customerAssets.zeroRateMortgageAmount} />
                    <Slider
                      min={0}
                      max={15000000}
                      value={customerAssets.zeroRateMortgageAmount}
                      onChange={(e, v) => { customerAssets.setZeroRateMortgageAmount(Number(v)) }}
                      aria-labelledby="input-slider" />
                  </div> */}
                  <div>
                    <Typography variant='body2' color='textSecondary'>Mortgage Amount</Typography>
                    <Typography variant='h6' color='textPrimary'><TextNumberFormat disableRounding value={customerAssets.mortgageAmount} /></Typography>
                  </div>
                  <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
                  <div style={{ marginBottom: theme.spacing(1.5) }}>
                    <Typography variant='body2' color='textSecondary'>Monthly Housing Budget</Typography>
                    <Typography variant='h6' color='textPrimary'><TextNumberFormat disableRounding value={availableMonthlySpending} /> </Typography>
                  </div>
                  <BreakdownItem theme={theme} item={(<div>
                    <div style={{ float: 'left' }}>
                      <Typography variant='body2' color='textSecondary'>Interest Payment <Tooltip title="Interest payment after 30% tax reduction"><Info fontSize={'inherit'}></Info></Tooltip></Typography>
                      <Typography variant='h6' color='textPrimary'><TextNumberFormat disableRounding value={customerAssets.monthlyInterestPayment} /></Typography>
                    </div>
                    <div style={{ float: 'right', width: theme.spacing(15) }}>
                      <TextField
                        error={mortgageInterestRate < 0}
                        InputProps={{ inputComponent: PercentFormatCustom }}
                        variant='filled'
                        margin='none'
                        size='small'
                        fullWidth={false}
                        required label='Rate'
                        onChange={(e) => { setMortgageInterestRate(e.target.value) }}
                        value={mortgageInterestRate} />
                    </div>
                  </div>)} />
                  <BreakdownItem theme={theme} item={(<div>
                    <TextField
                      error={Number(mortgagePlanning.monthlyMortgagePaymentAmount) < minMortgagePayment}
                      InputProps={{ inputComponent: NumberFormatCustom }}
                      variant='filled'
                      margin='none'
                      size='small'
                      fullWidth={true}
                      required label={(<>Monthly Amortisation (Min. <TextNumberFormat disableRounding value={minMortgagePayment} />)</>)}
                      onChange={(e) => { setMonthlyMortgagePaymentAmount(e.target.value) }}
                      value={mortgagePlanning.monthlyMortgagePaymentAmount} />
                  </div>)} />
                  <BreakdownItem theme={theme} item={(<div>
                    <TextField InputProps={{ inputComponent: NumberFormatCustom }} variant='filled' margin='none' size='small' fullWidth={true} required label="Investment Amount" onChange={(e) => { setMonthlyAlternativeInvestmentAmount(e.target.value) }} value={investmentGoal.monthlyInvestmentAmount} />
                  </div>)} />
                  <BreakdownItem theme={theme} item={(<div>
                    <Typography variant='body2' color='textSecondary'>Remaining Funds</Typography>
                    <Typography variant='h6' color='textPrimary'><TextNumberFormat disableRounding value={bufferDeposit} /> {bufferDeposit < 0 ? <Error fontSize='inherit' color='error' /> : <></>}</Typography>
                  </div>)} />

                  {/* </Grid>
                </Grid> */}
                </FormGroup>
                {/* </Paper> */}
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Collapse in={resultState.length === 1} timeout='auto' style={{ margin: 'auto', width: '100%' }}>
                <div style={{ width: 70, height: 70, margin: 'auto', marginTop: 75 }}>
                  <CircularProgress style={{ width: '100%', height: '100%' }} />
                </div>
              </Collapse>
              <Collapse in={resultState.length > 1} timeout='auto'>
                <Grid container direction="column" alignItems='stretch' justify='center' style={{ width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }} >
                  <Grid item style={{ maxWidth: '100%' }}>
                    <LinearProgress style={{ width: "100%", visibility: numberOfRequestInFlight > 0 ? "visible" : "hidden" }} />
                    {/* {resultState.rankedPortfolios.map((p, i) => renderAlternative(p, i))} */}
                    <Paper style={{ width: "100%", marginBottom: theme.spacing(1) }} className={classes.resultContainer} square>
                      <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents={false}
                      >
                        {mortgageTerms.map((p, i) =>
                          <div key={i}>
                            {Math.abs(activeStep - i) <= 2 && resultState.length > 1 ? (
                              renderAlternative(resultState[i], incomeTrajectory, i)
                            ) : null}
                          </div>
                        )}
                      </SwipeableViews>
                      <MobileStepper
                        variant="dots"
                        style={{ background: theme.palette.background.paper }}
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                          </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
                        }
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
          {/* TODO replace with control/result component */}
          {/* <Snackbar style={{ whiteSpace: 'pre-wrap' }} open={hasError} autoHideDuration={4000} onClose={() => setHasError(false)} message={errorMessage}></Snackbar> */}
        </Grid>
      </Grid>
    </div>
  );
}

function noFunctions(value) {
  return Object.fromEntries(Object.entries(value).filter(([key, value]) => typeof value === "string" || typeof value === "number" || typeof value === "boolean"))
}

function areEqual(prevProps, nextProps) {
  if (JSON.stringify(noFunctions(prevProps)) === JSON.stringify(noFunctions(nextProps))) {
    return true;
  }
  return false;
}

const MortgagePlanningViewMemo = React.memo(MortgagePlanningView, areEqual);



export default MortgagePlanning;
