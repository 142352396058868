export function calculateMonthlyInterestPayment(mortgageAmount, interestRate) {
    const rate = 1 +  interestRate;
    return Math.ceil(mortgageAmount * (Math.pow(rate, 1 / 12) - 1) * 0.7);
}

export function calculateMinMortgagePayment (valueOfResidence, mortgageAmount, grossSalary) {
    const loanToSecurityRatio = valueOfResidence / mortgageAmount;
    const ratioBasedAmount = loanToSecurityRatio > 0.7
      ? mortgageAmount * 0.02
      : loanToSecurityRatio > 0.5
        ? mortgageAmount * 0.01
        : 0;
    const salaryBasedAmount = grossSalary * 4.5 < mortgageAmount
      ? mortgageAmount * 0.01
      : 0;

    return Math.ceil((ratioBasedAmount + salaryBasedAmount) / 12);
  };