import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis } from 'recharts';

function PensionBarChart(props) {

    const { badOutcome, medianOutcome, goodOutcome, colors } = props;
    const chartData = [
        { name: 'Bad', ...badOutcome },
        { name: 'Median', ...medianOutcome },
        { name: 'Good', ...goodOutcome },
    ];

    //TODO fix animation/memo

    return (<div style={{ height: '10rem' }}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{
                top: 15, right: 10, left: 10, bottom: 2,
            }}>
                <defs>
                    <linearGradient id="colorNationalIncome" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.nationalIncome} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.nationalIncome} stopOpacity={0.85} />
                    </linearGradient>
                    <linearGradient id="colorOccupational" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.occupational} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.occupational} stopOpacity={0.85} />
                    </linearGradient>
                    <linearGradient id="colorCurrentPrivate" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.currentPrivate} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.currentPrivate} stopOpacity={0.85} />
                    </linearGradient>
                    <linearGradient id="colorNewPrivate" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.newPrivate} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.newPrivate} stopOpacity={0.85} />
                    </linearGradient>
                </defs>
                <XAxis tickLine={false} dataKey="name" />
                <Bar dataKey="nationalPension" stackId="a" strokeOpacity={0.3} strokeWidth={2} stroke="#333333" fill={"url(#colorNationalIncome)"} />
                <Bar dataKey="occupationalPension" stackId="a" strokeOpacity={0.3} strokeWidth={2} stroke="#333333" fill={"url(#colorOccupational)"} />
                <Bar dataKey="currentPrivatePension" stackId="a" strokeOpacity={0.3} strokeWidth={2} stroke="#333333" fill={"url(#colorCurrentPrivate)"} />
                <Bar dataKey="newPrivatePension" stackId="a" strokeOpacity={0.3}  strokeWidth={2} stroke="#333333" fill={"url(#colorNewPrivate)"} />
            </BarChart>
        </ResponsiveContainer>
    </div>);
}

function areEqual(prevProps, nextProps) {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

export default React.memo(PensionBarChart, areEqual);

