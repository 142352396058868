import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';

function SpendingCircleChart(props) {

    const { spendingItemData } = props;
    
    return (<div style={{ height: '10rem' }}>
        <ResponsiveContainer width="100%" height="100%">
            <PieChart margin={{
                top: 5, right: 10, left: 5, bottom: 2,
            }}>
                <Pie
                    data={spendingItemData}
                    // strokeOpacity={0.3}
                    // stroke="#333333"
                    stroke="none"
                    cx={'42%'}
                    // cy={200}
                    innerRadius={'78%'}
                    outerRadius={'89%'}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    // label={data => data.value}
                >
                    {
                        spendingItemData.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                    }
                </Pie>
                <Legend align='right' layout='vertical' verticalAlign='middle'></Legend>
            </PieChart>
        </ResponsiveContainer>
    </div>);
}

function areEqual(prevProps, nextProps) {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

export default React.memo(SpendingCircleChart, areEqual);

