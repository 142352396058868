import React, { useContext, useState, useEffect } from 'react';
import { CustomerAssetsContext } from './CustomerAssetsContext';
import SliderInput from './SliderInput';
import { namedFundsArray } from './api_client/tsClient';
import { FormControl, InputAdornment, FilledInput, InputLabel, TextField, IconButton, Typography, Collapse, Grid, Paper, LinearProgress, Fade, Divider, Select, MenuItem } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { styleDelegate } from './useStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Add, Delete } from '@material-ui/icons';
import { GoalAppShell } from './GoalAppShell';
import StressedPortfolioBarChart from './StressedPortfolioBarChart'
import { getStressScenarios } from './api_client_portfolio_analysis/portfolioAnalysisClient';
import { getMappedFunds } from './api_client/tsClient';
import { useDebounce } from './useDebounce';
import { SettingsContext } from './SettingsContext';



export default function PortfolioBuilder() {
  const defaultStressResults = [
    {
      scenarioName: "US housing bubble 2008",
      value: 0.8002432289887887
    },
    {
      scenarioName: "Greek government-debt crisis 2011",
      value: 0.9330996095669615
    },
    {
      scenarioName: "COVID-19 recession 2020",
      value: 0.8539331586257981
    }
  ];
  const classes = makeStyles(styleDelegate)();
  const customerAssets = useContext(CustomerAssetsContext);
  const [selectedFund, setSelectedFund] = useState(null);
  const [mappedFunds, setMappedFunds] = useState(null);
  const theme = useTheme();
  const [numberOfRequestInFlight, setNumberOfRequestInFlight] = useState(0);
  const [stressResults, setStressResults] = useState(defaultStressResults);
  const [stressScenarioName, setStressScenarioName] = useState(defaultStressResults[0].scenarioName);
  const debouncedCustomerAssets = useDebounce(customerAssets);
  const settings = useContext(SettingsContext);


  const optionsFilter = (options, state) => {
    return options.filter(o => customerAssets.investmentPortfolio.find(p => p.code === o.id) === undefined &&
      o.name.toLowerCase().includes(state.inputValue.toLowerCase()));
  };

  const availableWeight = Math.max(1 - customerAssets.investmentPortfolio.map(p => p.weight).reduce((prev, p) => p + prev, 0), 0);


  useEffect(() => {
    // setNumberOfRequestInFlight(state => state + 1);
    getMappedFunds(settings.currentScenarioSet.id)
      .then(result => {
        try {
          setMappedFunds(result);
        } finally {
          // setNumberOfRequestInFlight(state => state - 1);
        }
      });
  }, [settings.currentScenarioSet.id])

  useEffect(() => {
    if ((1 - debouncedCustomerAssets.investmentPortfolio.map(p => p.weight).reduce((prev, p) => p + prev, 0)).toFixed(2) !== '0.00') return;
    setNumberOfRequestInFlight(state => state + 1);
    getStressScenarios(settings.currentScenarioSet.id, debouncedCustomerAssets.investmentPortfolio)
      .then(result => {
        try {
          setStressResults(result);
        } finally {
          setNumberOfRequestInFlight(state => state - 1);
        }
      });
  }, [debouncedCustomerAssets.investmentPortfolio, settings.currentScenarioSet])



  const builderInputs = (
    <FormControl style={{ width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: '1' }}>
          <Autocomplete
            style={{ width: '100%' }}
            id="combo-box"
            filterOptions={optionsFilter}
            options={mappedFunds}
            getOptionLabel={(option) => option.name}
            onChange={(e, v) => {
              setSelectedFund(v);
            }}
            value={selectedFund}
            renderInput={(params) => <TextField {...params} label="Available Funds" variant="outlined" />}
          />
        </div>
        <div style={{ paddingRight: theme.spacing(0), paddingBottom: theme.spacing(2) }}>
          <IconButton disabled={selectedFund === null} color="primary" aria-label="add to portfolio"
            onClick={() => {
              const newstate = [...customerAssets.investmentPortfolio, { code: selectedFund.code, codeType: 'Isin', name: selectedFund.name, weight: availableWeight }];
              customerAssets.setInvestmentPortfolio(newstate);
              setSelectedFund(null);
            }}>
            <Add fontSize='large' />
          </IconButton>
        </div>
      </div>
      {customerAssets.investmentPortfolio.map(p => (
        <div style={{ display: 'flex' }} key={p.code}>
          <div style={{ flexGrow: '1' }}>
            <Typography style={{ maxWidth: 180 }}>{p.name}</Typography>
            <Typography variant="subtitle2">{p.code}</Typography>
          </div>
          <div style={{ marginLeft: theme.spacing(2), paddingRight: theme.spacing(0), paddingBottom: theme.spacing(2), whiteSpace:'nowrap' }}>
            <FormControl style={{ width: 100 }} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">Allocation</InputLabel>
              <FilledInput
                id="filled-adornment-weight"
                defaultValue={Math.round(p.weight * 100)}
                onChange={(e) => {
                  const newValue = Number(e.target.value) / 100;
                  const newstate = customerAssets.investmentPortfolio.map(obj => p.code === obj.code ? { code: p.code, codeType: p.codeType, name: p.name, weight: newValue } : obj);
                  customerAssets.setInvestmentPortfolio(newstate);
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="filled-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
            </FormControl>
            <IconButton color="primary" aria-label="remove from portfolio" onClick={() => {
              const newstate = customerAssets.investmentPortfolio.filter(ip => p.code !== ip.code);
              customerAssets.setInvestmentPortfolio(newstate);
            }}>
              <Delete fontSize='large' />
            </IconButton>
          </div>
        </div>
      ))}
    </FormControl>);

  const results = <div style={{ position: 'relative', minHeight: '70vh' }}>
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions === 0} className={classes.stepperContent}> */}
    <Collapse in={availableWeight === 1} >
      <Grid container direction="row" justify="center" alignItems="baseline">
        <Grid item style={{ width: '100%', height: '100%', minHeight: theme.spacing(7), textAlign: 'center' }}>
          <Typography style={{ paddingTop: theme.spacing(2) }} variant='body1'>Add funds to get started!</Typography>
        </Grid>
      </Grid>
    </Collapse>
    {/* </Fade> */}
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions > 0} timeout={2000} className={classes.stepperContent}> */}
    <Collapse in={availableWeight !== 1}>
      <Paper square>
        <Grid container direction="column" alignItems='stretch' justify='center'>
          <Grid item>
            <LinearProgress style={{ width: "100%", visibility: numberOfRequestInFlight > 0 ? "visible" : "hidden" }} />
          </Grid>
        </Grid>
        <Grid container className={classes.resultContainer} direction="column" alignItems='stretch' justify='center'>
          <Grid item>
            <Grid container direction="row"
              // justify="center"
              alignItems="baseline">
              <Grid item style={{ paddingTop: theme.spacing(1), width: '100%', height: '100%', textAlign: 'left' }}>
                <Typography style={{ paddingLeft: theme.spacing(2) }} color='textPrimary' display="inline">Portfolio Statistics</Typography>
                <Fade in={stressResults !== undefined}>
                  <div>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
                    <div style={{ paddingBottom: theme.spacing(1), paddingLeft: theme.spacing(2) }}>
                      {/* <Typography color='textSecondary' variant='body2'>Historical Stress Events</Typography> */}
                      <FormControl style={{ width: '100%', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
                        <InputLabel style={{ width: '100%', paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }} id="account-type-label">Historical Event</InputLabel>
                        <Select
                          labelId="account-type-label"
                          id="account-type-select"
                          variant='filled'
                          margin='dense'
                          value={stressScenarioName}
                          onChange={(event) => { setStressScenarioName(event.target.value) }}>
                          {stressResults.map(r => (<MenuItem key={r.scenarioName} value={r.scenarioName}>{r.scenarioName}</MenuItem>))}
                        </Select>
                      </FormControl>
                      <StressedPortfolioBarChart currentValue={1} stressedValue={stressResults.find(r => r.scenarioName === stressScenarioName) ? stressResults.find(r => r.scenarioName === stressScenarioName).value : 0} />
                      {/* <Typography color='textSecondary' variant='body2'>Income Over Time</Typography>
                      <IncomeBarChart cashFlows={incomeTrajectory} isAnnual={typeOfEvent === 'sickleave' ? false : true} horizon={horizonInYears} /> */}
                    </div>
                  </div>
                </Fade>
              </Grid>
            </Grid>
          </Grid>
        </Grid >
      </Paper >
    </Collapse>
    {/* </Fade> */}
  </div>;

  return (
    <div>
      {/* <PensionFanChart currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} /> */}
      <GoalAppShell title='Portfolio Builder' formView={builderInputs} resultView={results} />
    </div>
  );
}