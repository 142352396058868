import React, { useState, useContext, useEffect } from 'react';
import { FormGroup, Grid, TextField, Select, Typography, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { TextNumberFormat, NumberFormatCustom } from './NumberFormatCustom';
import { calculateMonthlyInterestPayment, calculateMinMortgagePayment } from './mortgageFunctions';
import { useTheme, withStyles } from '@material-ui/core/styles';
import moment from "moment";
import { styleDelegate } from './useStyles';
import { makeStyles } from '@material-ui/core/styles';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { CustomerAssetsContext } from './CustomerAssetsContext';
import { BreakdownItem, BreakdownItemSlim } from './BreakdownItem';
import { MortgagePlanningContext } from './MortgagePlanningContext';
import { SettingsContext } from './SettingsContext';
import { Info, Error } from '@material-ui/icons';



function IncomeSheet() {

    const theme = useTheme();

    const basicCustomerInformation = useContext(BasicCustomerInformationContext);
    const customerAssets = useContext(CustomerAssetsContext);

    const mortgagePlanning = useContext(MortgagePlanningContext);
    const settings = useContext(SettingsContext);

    const interestPayment = calculateMonthlyInterestPayment(customerAssets.mortgageAmount, 0.07);

    const maintenanceCost = (customerAssets.typeOfRealEstate === 'House' ? 4000 : 3100) / settings.rateRelativeSek;

    const bufferDeposit = Number(basicCustomerInformation.grossSalary)
        - Number(basicCustomerInformation.municipalTax)
        - Number(basicCustomerInformation.stateTax)
        + Number(basicCustomerInformation.taxReduction)
        - basicCustomerInformation.ordinaryConsumption
        - maintenanceCost
        - mortgagePlanning.monthlyMortgagePaymentAmount
        - interestPayment;

    return (
        <div style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginBottom: theme.spacing(1) }}>
            <div style={{ alignItems: 'flex-end', flexGrow: '1' }}>
                <div style={{ display: 'flex' }}>
                    <Typography display='inline' style={{ paddingTop: 8, flexGrow: '1' }} variant='body2' color='textSecondary'>Gross Salary</Typography>
                    <Typography display='inline' style={{ textAlign: 'right' }} variant='h6' color='textPrimary'><TextNumberFormat roundToNearest={1} value={basicCustomerInformation.grossSalary} /></Typography>
                </div>
            </div>
            <BreakdownItemSlim title={'Municipal Tax'} value={-basicCustomerInformation.municipalTax} />
            <BreakdownItemSlim title={'State Tax'} value={-basicCustomerInformation.stateTax} />
            <BreakdownItemSlim title={'Tax Reduction'} value={basicCustomerInformation.taxReduction} />
            <BreakdownItemSlim title={'Ordinary Consumption'} value={-basicCustomerInformation.ordinaryConsumption} />
            <BreakdownItemSlim title={'Maintenance Cost'} value={-maintenanceCost} />
            <BreakdownItemSlim title={'Interest Payment (at 7%)'} value={-interestPayment} />
            <BreakdownItemSlim title={'Monthly Amortisation'} value={-mortgagePlanning.monthlyMortgagePaymentAmount} />
            <div style={{ alignItems: 'flex-end', flexGrow: '1' }}>
                <div style={{ display: 'flex' }}>
                    <Typography display='inline' style={{ paddingTop: 6, flexGrow: '1' }} variant='body1' color='textSecondary'>Remaining Funds</Typography>
                    <Typography display='inline' style={{ textAlign: 'right' }} variant='h6' color='textPrimary'>
                        <TextNumberFormat roundToNearest={1} value={bufferDeposit} /> {bufferDeposit < 0 ? <Error fontSize='inherit' color='error' /> : <></>}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default IncomeSheet;
