import moment from "moment";
import { AssetsAndDebts, CashFlowObservationsRequest, FinancialPlanningRequestModel, Frequency, InflationAdjustmentContract, InvestmentAccountTaxationType } from "../api_client/api";
import { investmentAccount } from "../api_client/tsClient";
import { percentiles } from "../appConstants";

export function createRequest(
    scenarioSet: string,
    riskLevel: number,
    investmentAccountTaxationType: InvestmentAccountTaxationType,
    grossMonthlyIncome: number,
    monthlyExpenses: number,
    currentValueSavings: number,
    retrainingInputs: { startMonth: number, endMonth: number, rateOfAbsence: number, expectedPayRaise: number, annualTuitionFee: number },
    inflationAdjustment: InflationAdjustmentContract): FinancialPlanningRequestModel {

    const now = moment("2021-01-01T00:00:00.000Z");

    const numYears = 40;

    const observationDates = [...Array(numYears + 1).keys()].map(index => now.clone().add(index, 'years').toDate());

    const schoolPeriod = { start: now.clone().add(retrainingInputs.startMonth, 'months').toDate(), end: now.clone().add(retrainingInputs.endMonth, 'months').toDate() }

    const investmentAccountId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";

    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccount(investmentAccountId, riskLevel, investmentAccountTaxationType, currentValueSavings),
        ]
    };

    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        cashFlowObservations: CashFlowObservationsRequest.Annual,
        incomesAndExpenses: {
            earnedIncome: {
                recurringTransactions: [
                    { // current salary
                        amount: Number(grossMonthlyIncome),
                        startDate: now.toDate(),
                        endDate: schoolPeriod.start,
                        frequency: Frequency.Monthly,
                        targetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                    },
                    { // salary while studying
                        amount: Number(grossMonthlyIncome) * (1 - retrainingInputs.rateOfAbsence),
                        startDate: schoolPeriod.start,
                        endDate: schoolPeriod.end,
                        frequency: Frequency.Monthly,
                        targetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                    },
                    { // salary after re-education
                        amount: Number(grossMonthlyIncome) * (1 + retrainingInputs.expectedPayRaise),
                        startDate: schoolPeriod.end,
                        endDate: observationDates[numYears],
                        frequency: Frequency.Monthly,
                        targetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                    }
                ],
                oneTimeTransactions: [],
                transactionArrays: []
            },
            taxExempt:
            {
                recurringTransactions: [{
                    amount: monthlyExpenses,
                    startDate: now.toDate(),
                    endDate: observationDates[numYears],
                    frequency: Frequency.Monthly,
                    sourceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                }, {
                    amount: (retrainingInputs.annualTuitionFee / 12),
                    startDate: schoolPeriod.start,
                    endDate: schoolPeriod.end,
                    frequency: Frequency.Monthly,
                    sourceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                },
                ],
                oneTimeTransactions: [],
                transactionArrays: [],
            }
        },
        taxInformation: {
            taxPaymentAssetsAndDebtsItemId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            municipalityKey: "0125"
        },
        observationDates: observationDates,
        inflationAdjustment: inflationAdjustment
    }
    return financialPlanningRequest;
}


export function createBaselineRequest(
    scenarioSet: string,
    riskLevel: number,
    investmentAccountTaxationType: InvestmentAccountTaxationType,
    grossMonthlyIncome: number,
    monthlyExpenses: number,
    currentValueSavings: number,
    inflationAdjustment: InflationAdjustmentContract): FinancialPlanningRequestModel {

    const now = moment("2021-01-01T00:00:00.000Z");
    const numYears = 40;
    const observationDates = [...Array(numYears + 1).keys()].map(index => now.clone().add(index, 'years').toDate());

    const investmentAccountId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";

    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccount(investmentAccountId, riskLevel, investmentAccountTaxationType, currentValueSavings),
        ]
    };

    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        cashFlowObservations: CashFlowObservationsRequest.Annual,
        incomesAndExpenses: {
            earnedIncome: {
                recurringTransactions: [
                    { // current salary
                        amount: Number(grossMonthlyIncome),
                        startDate: now.toDate(),
                        endDate: observationDates[numYears],
                        frequency: Frequency.Monthly,
                        targetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                    }
                ],
                oneTimeTransactions: [],
                transactionArrays: []
            },
            taxExempt:
            {
                recurringTransactions: [{
                    amount: monthlyExpenses,
                    startDate: now.toDate(),
                    endDate: observationDates[numYears],
                    frequency: Frequency.Monthly,
                    sourceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                }
                ],
                oneTimeTransactions: [],
                transactionArrays: [],
            }
        },
        taxInformation: {
            taxPaymentAssetsAndDebtsItemId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            municipalityKey: "0125"
        },
        observationDates: observationDates,
        inflationAdjustment: inflationAdjustment
    }
    return financialPlanningRequest;
}