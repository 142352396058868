/* eslint-disable @typescript-eslint/consistent-type-assertions */
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * OutRank Financial Planning API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AdviceRequestModel
 */
export interface AdviceRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AdviceRequestModel
     */
    scenarioSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdviceRequestModel
     */
    asOfDate: Date;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    grossMonthlySalary?: number;
    /**
     * 
     * @type {Date}
     * @memberof AdviceRequestModel
     */
    dateOfBirth: Date;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    desiredRetirementAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    currentGrossIncomePensionCapital?: number;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    currentGrossPremiumPensionCapital?: number;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    currentGrossOccupationalPensionCapital?: number;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    currentPrivatePensionCapital?: number;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    oneTimeNewPensionDeposit?: number;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    monthlyDeposit?: number;
    /**
     * 
     * @type {number}
     * @memberof AdviceRequestModel
     */
    monthlySavingsCapacity?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdviceRequestModel
     */
    percentiles?: Array<number>;
    /**
     * 
     * @type {ApplicationSpecificRiskLevel}
     * @memberof AdviceRequestModel
     */
    pensionInvestmentRiskLevel: ApplicationSpecificRiskLevel;
    /**
     * 
     * @type {AdviceRequestModelInvestmentRiskLevel}
     * @memberof AdviceRequestModel
     */
    investmentRiskLevel: AdviceRequestModelInvestmentRiskLevel;
    /**
     * 
     * @type {AdviceRequestModelReturnRiskLevel}
     * @memberof AdviceRequestModel
     */
    returnRiskLevel: AdviceRequestModelReturnRiskLevel;
    /**
     * 
     * @type {AdviceRequestModelLossRiskLevel}
     * @memberof AdviceRequestModel
     */
    lossRiskLevel: AdviceRequestModelLossRiskLevel;
    /**
     * 
     * @type {Array<InvestmentAccountRequestModel>}
     * @memberof AdviceRequestModel
     */
    newPrivatePensionPortfolios: Array<InvestmentAccountRequestModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AdviceRequestModelInvestmentRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AdviceRequestModelLossRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AdviceRequestModelReturnRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @interface AgeResponseModel
 */
export interface AgeResponseModel {
    /**
     * 
     * @type {number}
     * @memberof AgeResponseModel
     */
    years?: number;
    /**
     * 
     * @type {number}
     * @memberof AgeResponseModel
     */
    months?: number;
}
/**
 * 
 * @export
 * @interface AggregatedCashFlowResponse
 */
export interface AggregatedCashFlowResponse {
    /**
     * 
     * @type {Date}
     * @memberof AggregatedCashFlowResponse
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof AggregatedCashFlowResponse
     */
    _in?: number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedCashFlowResponse
     */
    out?: number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedCashFlowResponse
     */
    net?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationSpecificRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @interface AssetsAndDebts
 */
export interface AssetsAndDebts {
    /**
     * 
     * @type {Array<InvestmentAccountRequestModel>}
     * @memberof AssetsAndDebts
     */
    investmentAccounts?: Array<InvestmentAccountRequestModel>;
    /**
     * 
     * @type {Array<RealEstateRequest>}
     * @memberof AssetsAndDebts
     */
    realEstate?: Array<RealEstateRequest>;
    /**
     * 
     * @type {Array<MortgageRequest>}
     * @memberof AssetsAndDebts
     */
    mortgages?: Array<MortgageRequest>;
    /**
     * 
     * @type {Array<TransactionAccountRequest>}
     * @memberof AssetsAndDebts
     */
    transactionAccounts?: Array<TransactionAccountRequest>;
    /**
     * 
     * @type {PensionAccountsRequestModel}
     * @memberof AssetsAndDebts
     */
    pensionAccounts?: PensionAccountsRequestModel;
    /**
     * 
     * @type {Array<UnlistedSharesRequestModel>}
     * @memberof AssetsAndDebts
     */
    unlistedShares?: Array<UnlistedSharesRequestModel>;
}
/**
 * 
 * @export
 * @interface AssetsAndDebtsGroupRequest
 */
export interface AssetsAndDebtsGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetsAndDebtsGroupRequest
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetsAndDebtsGroupRequest
     */
    assetsAndDebtsItemIds: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CashFlowObservationsRequest {
    None = <any> 'None',
    Monthly = <any> 'Monthly',
    Annual = <any> 'Annual'
}
/**
 * 
 * @export
 * @interface CashFlowResultResponse
 */
export interface CashFlowResultResponse {
    /**
     * 
     * @type {string}
     * @memberof CashFlowResultResponse
     */
    assetsAndDebtsItemId?: string;
    /**
     * 
     * @type {Array<PointInTimeCashFlowResponse>}
     * @memberof CashFlowResultResponse
     */
    averageCashFlows?: Array<PointInTimeCashFlowResponse>;
}
/**
 * 
 * @export
 * @interface CashFlowsAggregationRequest
 */
export interface CashFlowsAggregationRequest {
    /**
     * 
     * @type {Date}
     * @memberof CashFlowsAggregationRequest
     */
    startDate: Date;
    /**
     * 
     * @type {IncomesAndExpensesRequest}
     * @memberof CashFlowsAggregationRequest
     */
    incomesAndExpenses: IncomesAndExpensesRequest;
    /**
     * 
     * @type {string}
     * @memberof CashFlowsAggregationRequest
     */
    assetsAndDebtsItemId: string;
    /**
     * 
     * @type {Frequency}
     * @memberof CashFlowsAggregationRequest
     */
    frequency: Frequency;
}
/**
 * 
 * @export
 * @interface CashFlowsAggregationResponse
 */
export interface CashFlowsAggregationResponse {
    /**
     * 
     * @type {Array<AggregatedCashFlowResponse>}
     * @memberof CashFlowsAggregationResponse
     */
    cashFlows: Array<AggregatedCashFlowResponse>;
}
/**
 * 
 * @export
 * @interface DesiredPensionResponseModel
 */
export interface DesiredPensionResponseModel {
    /**
     * 
     * @type {IntervalResponseModelDecimal}
     * @memberof DesiredPensionResponseModel
     */
    interval: IntervalResponseModelDecimal;
    /**
     * 
     * @type {number}
     * @memberof DesiredPensionResponseModel
     */
    value?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DividendType {
    SweDividendsK10 = <any> 'SweDividendsK10',
    SweDividendsK12 = <any> 'SweDividendsK12'
}
/**
 * 
 * @export
 * @interface Dividends
 */
export interface Dividends {
    /**
     * 
     * @type {DividendType}
     * @memberof Dividends
     */
    type?: DividendType;
    /**
     * 
     * @type {Transactions}
     * @memberof Dividends
     */
    transactions?: Transactions;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Domicile {
    Swe = <any> 'Swe',
    Nor = <any> 'Nor',
    Fin = <any> 'Fin',
    Dnk = <any> 'Dnk',
    Gbr = <any> 'Gbr'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EmployeeSicknessBenefitInsuranceTypeContract {
    NoInsurance = <any> 'NoInsurance',
    StandardCollectiveAgreement = <any> 'StandardCollectiveAgreement'
}
/**
 * 
 * @export
 * @interface EngageComparisonRequestModel
 */
export interface EngageComparisonRequestModel {
    /**
     * 
     * @type {string}
     * @memberof EngageComparisonRequestModel
     */
    scenarioSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof EngageComparisonRequestModel
     */
    startDate: Date;
    /**
     * 
     * @type {Array<Date>}
     * @memberof EngageComparisonRequestModel
     */
    observationDates?: Array<Date>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EngageComparisonRequestModel
     */
    percentiles?: Array<number>;
    /**
     * 
     * @type {ApplicationSpecificRiskLevel}
     * @memberof EngageComparisonRequestModel
     */
    applicationSpecificRiskLevel: ApplicationSpecificRiskLevel;
    /**
     * 
     * @type {AdviceRequestModelInvestmentRiskLevel}
     * @memberof EngageComparisonRequestModel
     */
    investmentRiskLevel: AdviceRequestModelInvestmentRiskLevel;
    /**
     * 
     * @type {AdviceRequestModelReturnRiskLevel}
     * @memberof EngageComparisonRequestModel
     */
    returnRiskLevel: AdviceRequestModelReturnRiskLevel;
    /**
     * 
     * @type {AdviceRequestModelLossRiskLevel}
     * @memberof EngageComparisonRequestModel
     */
    lossRiskLevel: AdviceRequestModelLossRiskLevel;
    /**
     * 
     * @type {AssetsAndDebts}
     * @memberof EngageComparisonRequestModel
     */
    targetAssetsAndDebts: AssetsAndDebts;
    /**
     * 
     * @type {AssetsAndDebts}
     * @memberof EngageComparisonRequestModel
     */
    currentAssetsAndDebts: AssetsAndDebts;
    /**
     * 
     * @type {number}
     * @memberof EngageComparisonRequestModel
     */
    expectedShortfallPercentile?: number;
    /**
     * 
     * @type {IncomesAndExpensesRequest}
     * @memberof EngageComparisonRequestModel
     */
    incomesAndExpenses: IncomesAndExpensesRequest;
    /**
     * 
     * @type {Domicile}
     * @memberof EngageComparisonRequestModel
     */
    domicile?: Domicile;
    /**
     * 
     * @type {TaxInformationRequest}
     * @memberof EngageComparisonRequestModel
     */
    taxInformation?: TaxInformationRequest;
}
/**
 * 
 * @export
 * @interface EngageComparisonResponseModel
 */
export interface EngageComparisonResponseModel {
    /**
     * 
     * @type {Array<PortfolioTimePercentileResponse>}
     * @memberof EngageComparisonResponseModel
     */
    currentPortfolioDevelopmentTimeSeries: Array<PortfolioTimePercentileResponse>;
    /**
     * 
     * @type {Array<PortfolioTimePercentileResponse>}
     * @memberof EngageComparisonResponseModel
     */
    targetPortfolioDevelopmentTimeSeries: Array<PortfolioTimePercentileResponse>;
    /**
     * 
     * @type {Array<PortfolioOutcomeResponse>}
     * @memberof EngageComparisonResponseModel
     */
    currentDiversificationOutcomes: Array<PortfolioOutcomeResponse>;
    /**
     * 
     * @type {Array<PortfolioOutcomeResponse>}
     * @memberof EngageComparisonResponseModel
     */
    targetDiversificationOutcomes: Array<PortfolioOutcomeResponse>;
    /**
     * 
     * @type {Array<ExpectedShortfallResult>}
     * @memberof EngageComparisonResponseModel
     */
    currentExpectedShortfall: Array<ExpectedShortfallResult>;
    /**
     * 
     * @type {Array<ExpectedShortfallResult>}
     * @memberof EngageComparisonResponseModel
     */
    targetExpectedShortfall: Array<ExpectedShortfallResult>;
}
/**
 * 
 * @export
 * @interface ExpectedShortfallResult
 */
export interface ExpectedShortfallResult {
    /**
     * 
     * @type {Date}
     * @memberof ExpectedShortfallResult
     */
    pointInTime: Date;
    /**
     * 
     * @type {number}
     * @memberof ExpectedShortfallResult
     */
    expectedShortfall: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExpectedShortfallResult
     */
    tailDistributionSamples: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExpectedShortfallResult
     */
    restOfDistributionSamples: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ExpectedShortfallResult
     */
    investedCapital: number;
}
/**
 * 
 * @export
 * @interface FeeRequestModel
 */
export interface FeeRequestModel {
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    annualFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    performanceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    purchaseFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    salesFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    transactionCost?: number;
}
/**
 * 
 * @export
 * @interface FieldErrorModel
 */
export interface FieldErrorModel {
    /**
     * 
     * @type {string}
     * @memberof FieldErrorModel
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldErrorModel
     */
    message?: string;
    /**
     * 
     * @type {FieldErrorModelCode}
     * @memberof FieldErrorModel
     */
    code?: FieldErrorModelCode;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldErrorModelCode {
    Unspecified = <any> 'Unspecified',
    NegativeSalary = <any> 'NegativeSalary',
    DateOfBirthTooLow = <any> 'DateOfBirthTooLow',
    DateOfBirthTooHigh = <any> 'DateOfBirthTooHigh',
    RetirementAgeTooHigh = <any> 'RetirementAgeTooHigh',
    RetirementAgeTooLow = <any> 'RetirementAgeTooLow',
    NegativePensionCapital = <any> 'NegativePensionCapital',
    PensionCapitalTooHigh = <any> 'PensionCapitalTooHigh',
    NegativeDesiredPension = <any> 'NegativeDesiredPension',
    CurrentNationalPensionIsZero = <any> 'CurrentNationalPensionIsZero',
    NegativeMonthlyDeposit = <any> 'NegativeMonthlyDeposit',
    MonthlyDepositHigherThanSavingsCapacity = <any> 'MonthlyDepositHigherThanSavingsCapacity',
    TotalDepositsMustBePositive = <any> 'TotalDepositsMustBePositive',
    NegativeSavingsCapacity = <any> 'NegativeSavingsCapacity',
    NonpositivePensionInvestmentRiskAversion = <any> 'NonpositivePensionInvestmentRiskAversion',
    PensionInvestmentRiskAversionTooHigh = <any> 'PensionInvestmentRiskAversionTooHigh',
    MunicipalityKeyNotRecognized = <any> 'MunicipalityKeyNotRecognized',
    SalaryCapacityTooLow = <any> 'SalaryCapacityTooLow',
    InvalidGeneralRiskAversion = <any> 'InvalidGeneralRiskAversion',
    SumOfMonthlyDepositAndOneTimeDepositTooLow = <any> 'SumOfMonthlyDepositAndOneTimeDepositTooLow',
    InvestmentCapacityTooHigh = <any> 'InvestmentCapacityTooHigh',
    DesiredPensionTooHigh = <any> 'DesiredPensionTooHigh',
    UnknownScenarioSetId = <any> 'UnknownScenarioSetId',
    PecentileOutOfRange = <any> 'PecentileOutOfRange',
    GrossSalaryTooHigh = <any> 'GrossSalaryTooHigh',
    InvalidCurrencyCode = <any> 'InvalidCurrencyCode',
    NegativeFee = <any> 'NegativeFee',
    InvalidProductCode = <any> 'InvalidProductCode',
    InvalidAllocationWeight = <any> 'InvalidAllocationWeight',
    NegativeOutperformanceAssumption = <any> 'NegativeOutperformanceAssumption',
    UnknownSimulatedModelId = <any> 'UnknownSimulatedModelId',
    UnknownFactorId = <any> 'UnknownFactorId',
    NullArgument = <any> 'NullArgument',
    InvalidFormat = <any> 'InvalidFormat',
    OutOfRange = <any> 'OutOfRange',
    UnknownType = <any> 'UnknownType',
    ImportError = <any> 'ImportError',
    InvalidTransactionDate = <any> 'InvalidTransactionDate',
    InvalidMunicipalityKey = <any> 'InvalidMunicipalityKey',
    NoObservationDates = <any> 'NoObservationDates'
}
/**
 * 
 * @export
 * @interface FinancialPlanningRequestModel
 */
export interface FinancialPlanningRequestModel {
    /**
     * 
     * @type {string}
     * @memberof FinancialPlanningRequestModel
     */
    scenarioSetId: string;
    /**
     * 
     * @type {Date}
     * @memberof FinancialPlanningRequestModel
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof FinancialPlanningRequestModel
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {Array<Date>}
     * @memberof FinancialPlanningRequestModel
     */
    observationDates?: Array<Date>;
    /**
     * 
     * @type {CashFlowObservationsRequest}
     * @memberof FinancialPlanningRequestModel
     */
    cashFlowObservations?: CashFlowObservationsRequest;
    /**
     * 
     * @type {Array<number>}
     * @memberof FinancialPlanningRequestModel
     */
    percentiles?: Array<number>;
    /**
     * 
     * @type {AssetsAndDebts}
     * @memberof FinancialPlanningRequestModel
     */
    assetsAndDebts: AssetsAndDebts;
    /**
     * 
     * @type {Array<AssetsAndDebtsGroupRequest>}
     * @memberof FinancialPlanningRequestModel
     */
    assetsAndDebtsItemGroups?: Array<AssetsAndDebtsGroupRequest>;
    /**
     * 
     * @type {PortfolioStrategiesRequest}
     * @memberof FinancialPlanningRequestModel
     */
    portfolioStrategies?: PortfolioStrategiesRequest;
    /**
     * 
     * @type {IncomesAndExpensesRequest}
     * @memberof FinancialPlanningRequestModel
     */
    incomesAndExpenses: IncomesAndExpensesRequest;
    /**
     * 
     * @type {TaxInformationRequest}
     * @memberof FinancialPlanningRequestModel
     */
    taxInformation?: TaxInformationRequest;
    /**
     * 
     * @type {Domicile}
     * @memberof FinancialPlanningRequestModel
     */
    domicile?: Domicile;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof FinancialPlanningRequestModel
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface FinancialPlanningResponseModel
 */
export interface FinancialPlanningResponseModel {
    /**
     * 
     * @type {Array<PortfolioTimePercentileResponse>}
     * @memberof FinancialPlanningResponseModel
     */
    percentileForecast?: Array<PortfolioTimePercentileResponse>;
    /**
     * 
     * @type {Array<GroupResultRequest>}
     * @memberof FinancialPlanningResponseModel
     */
    groupResults: Array<GroupResultRequest>;
    /**
     * 
     * @type {Array<CashFlowResultResponse>}
     * @memberof FinancialPlanningResponseModel
     */
    cashFlows: Array<CashFlowResultResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Frequency {
    Monthly = <any> 'Monthly',
    Annual = <any> 'Annual'
}
/**
 * 
 * @export
 * @interface GeneralAversionLossRiskHighResponseModel
 */
export interface GeneralAversionLossRiskHighResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionLossRiskHighResponseModel
     */
    drawdownAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionLossRiskLowResponseModel
 */
export interface GeneralAversionLossRiskLowResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionLossRiskLowResponseModel
     */
    drawdownAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionLossRiskResponseModel
 */
export interface GeneralAversionLossRiskResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionLossRiskResponseModel
     */
    investmentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionLossRiskResponseModel
     */
    investmentAmountIn3Years?: number;
    /**
     * 
     * @type {GeneralAversionLossRiskLowResponseModel}
     * @memberof GeneralAversionLossRiskResponseModel
     */
    low?: GeneralAversionLossRiskLowResponseModel;
    /**
     * 
     * @type {GeneralAversionLossRiskHighResponseModel}
     * @memberof GeneralAversionLossRiskResponseModel
     */
    high?: GeneralAversionLossRiskHighResponseModel;
}
/**
 * 
 * @export
 * @interface GeneralAversionOptionsResponseModel
 */
export interface GeneralAversionOptionsResponseModel {
    /**
     * 
     * @type {GeneralAversionReturnRiskResponseModel}
     * @memberof GeneralAversionOptionsResponseModel
     */
    returnRisk?: GeneralAversionReturnRiskResponseModel;
    /**
     * 
     * @type {GeneralAversionLossRiskResponseModel}
     * @memberof GeneralAversionOptionsResponseModel
     */
    lossRisk?: GeneralAversionLossRiskResponseModel;
    /**
     * 
     * @type {GeneralAversionPensionSpecificRiskResponseModel}
     * @memberof GeneralAversionOptionsResponseModel
     */
    pensionSpecificRisk?: GeneralAversionPensionSpecificRiskResponseModel;
}
/**
 * 
 * @export
 * @interface GeneralAversionPensionSpecificRiskHighResponseModel
 */
export interface GeneralAversionPensionSpecificRiskHighResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionPensionSpecificRiskHighResponseModel
     */
    badPensionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionPensionSpecificRiskHighResponseModel
     */
    medianPensionAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionPensionSpecificRiskLowResponseModel
 */
export interface GeneralAversionPensionSpecificRiskLowResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionPensionSpecificRiskLowResponseModel
     */
    pensionAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionPensionSpecificRiskMediumResponseModel
 */
export interface GeneralAversionPensionSpecificRiskMediumResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionPensionSpecificRiskMediumResponseModel
     */
    badPensionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionPensionSpecificRiskMediumResponseModel
     */
    medianPensionAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionPensionSpecificRiskResponseModel
 */
export interface GeneralAversionPensionSpecificRiskResponseModel {
    /**
     * 
     * @type {GeneralAversionPensionSpecificRiskLowResponseModel}
     * @memberof GeneralAversionPensionSpecificRiskResponseModel
     */
    low?: GeneralAversionPensionSpecificRiskLowResponseModel;
    /**
     * 
     * @type {GeneralAversionPensionSpecificRiskMediumResponseModel}
     * @memberof GeneralAversionPensionSpecificRiskResponseModel
     */
    medium?: GeneralAversionPensionSpecificRiskMediumResponseModel;
    /**
     * 
     * @type {GeneralAversionPensionSpecificRiskHighResponseModel}
     * @memberof GeneralAversionPensionSpecificRiskResponseModel
     */
    high?: GeneralAversionPensionSpecificRiskHighResponseModel;
}
/**
 * 
 * @export
 * @interface GeneralAversionReturnRiskHighResponseModel
 */
export interface GeneralAversionReturnRiskHighResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionReturnRiskHighResponseModel
     */
    badReturnAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionReturnRiskHighResponseModel
     */
    medianReturnAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionReturnRiskLowResponseModel
 */
export interface GeneralAversionReturnRiskLowResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionReturnRiskLowResponseModel
     */
    returnAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionReturnRiskMediumResponseModel
 */
export interface GeneralAversionReturnRiskMediumResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionReturnRiskMediumResponseModel
     */
    badReturnAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionReturnRiskMediumResponseModel
     */
    medianReturnAmount?: number;
}
/**
 * 
 * @export
 * @interface GeneralAversionReturnRiskResponseModel
 */
export interface GeneralAversionReturnRiskResponseModel {
    /**
     * 
     * @type {number}
     * @memberof GeneralAversionReturnRiskResponseModel
     */
    investmentAmount?: number;
    /**
     * 
     * @type {GeneralAversionReturnRiskLowResponseModel}
     * @memberof GeneralAversionReturnRiskResponseModel
     */
    low?: GeneralAversionReturnRiskLowResponseModel;
    /**
     * 
     * @type {GeneralAversionReturnRiskMediumResponseModel}
     * @memberof GeneralAversionReturnRiskResponseModel
     */
    medium?: GeneralAversionReturnRiskMediumResponseModel;
    /**
     * 
     * @type {GeneralAversionReturnRiskHighResponseModel}
     * @memberof GeneralAversionReturnRiskResponseModel
     */
    high?: GeneralAversionReturnRiskHighResponseModel;
}
/**
 * 
 * @export
 * @interface GeneralRiskAversion
 */
export interface GeneralRiskAversion {
    /**
     * 
     * @type {GeneralRiskAversionLevel}
     * @memberof GeneralRiskAversion
     */
    level?: GeneralRiskAversionLevel;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum GeneralRiskAversionLevel {
    NoRisk = <any> 'NoRisk',
    VeryLowRisk = <any> 'VeryLowRisk',
    LowRisk = <any> 'LowRisk',
    MediumLowRisk = <any> 'MediumLowRisk',
    MediumHighRisk = <any> 'MediumHighRisk',
    HighRisk = <any> 'HighRisk',
    VeryHighRisk = <any> 'VeryHighRisk'
}
/**
 * 
 * @export
 * @interface GroupResultForecastResponse
 */
export interface GroupResultForecastResponse {
    /**
     * 
     * @type {Date}
     * @memberof GroupResultForecastResponse
     */
    pointInTime: Date;
    /**
     * 
     * @type {number}
     * @memberof GroupResultForecastResponse
     */
    expectedValue: number;
}
/**
 * 
 * @export
 * @interface GroupResultRequest
 */
export interface GroupResultRequest {
    /**
     * 
     * @type {string}
     * @memberof GroupResultRequest
     */
    groupId?: string;
    /**
     * 
     * @type {Array<GroupResultForecastResponse>}
     * @memberof GroupResultRequest
     */
    forecast?: Array<GroupResultForecastResponse>;
}
/**
 * 
 * @export
 * @interface IncomeGrossToNetSalaryFromTrajectoryRequest
 */
export interface IncomeGrossToNetSalaryFromTrajectoryRequest {
    /**
     * 
     * @type {Array<SweGrossEarnedIncomePayment>}
     * @memberof IncomeGrossToNetSalaryFromTrajectoryRequest
     */
    salaryTrajectory: Array<SweGrossEarnedIncomePayment>;
    /**
     * 
     * @type {string}
     * @memberof IncomeGrossToNetSalaryFromTrajectoryRequest
     */
    officialMunicipalityKey: string;
}
/**
 * 
 * @export
 * @interface IncomesAndExpensesRequest
 */
export interface IncomesAndExpensesRequest {
    /**
     * 
     * @type {Transactions}
     * @memberof IncomesAndExpensesRequest
     */
    taxExempt?: Transactions;
    /**
     * 
     * @type {Transactions}
     * @memberof IncomesAndExpensesRequest
     */
    earnedIncome?: Transactions;
    /**
     * 
     * @type {Array<Dividends>}
     * @memberof IncomesAndExpensesRequest
     */
    dividends?: Array<Dividends>;
    /**
     * 
     * @type {PensionRequest}
     * @memberof IncomesAndExpensesRequest
     */
    pension?: PensionRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InflationAdjustmentContract {
    NoAdjustment = <any> 'NoAdjustment',
    ValueAsOfStartDate = <any> 'ValueAsOfStartDate'
}
/**
 * 
 * @export
 * @interface IntervalResponseModelDecimal
 */
export interface IntervalResponseModelDecimal {
    /**
     * 
     * @type {number}
     * @memberof IntervalResponseModelDecimal
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof IntervalResponseModelDecimal
     */
    min?: number;
}
/**
 * 
 * @export
 * @interface IntervalResponseModelInt32
 */
export interface IntervalResponseModelInt32 {
    /**
     * 
     * @type {number}
     * @memberof IntervalResponseModelInt32
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof IntervalResponseModelInt32
     */
    min?: number;
}
/**
 * 
 * @export
 * @interface InvestmentAccountRequestModel
 */
export interface InvestmentAccountRequestModel {
    /**
     * 
     * @type {string}
     * @memberof InvestmentAccountRequestModel
     */
    id: string;
    /**
     * 
     * @type {Array<InvestmentAllocationRequestModel>}
     * @memberof InvestmentAccountRequestModel
     */
    investmentAllocations: Array<InvestmentAllocationRequestModel>;
    /**
     * 
     * @type {InvestmentAccountTaxationType}
     * @memberof InvestmentAccountRequestModel
     */
    taxationType: InvestmentAccountTaxationType;
    /**
     * 
     * @type {InvestmentStrategyType}
     * @memberof InvestmentAccountRequestModel
     */
    strategy: InvestmentStrategyType;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAccountRequestModel
     */
    annualAccountFee?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentAccountTaxationType {
    NoTax = <any> 'NoTax',
    SweIsk = <any> 'SweIsk',
    SweEndowmentInsurance = <any> 'SweEndowmentInsurance',
    GbrIsa = <any> 'GbrIsa',
    GbrSipp = <any> 'GbrSipp',
    SweCapitalGains = <any> 'SweCapitalGains'
}
/**
 * 
 * @export
 * @interface InvestmentAllocationRequestModel
 */
export interface InvestmentAllocationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof InvestmentAllocationRequestModel
     */
    currencyCode: string;
    /**
     * 
     * @type {FeeRequestModel}
     * @memberof InvestmentAllocationRequestModel
     */
    fees: FeeRequestModel;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    outperformanceAssumption?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    weight?: number;
    /**
     * 
     * @type {ProductCodeContract}
     * @memberof InvestmentAllocationRequestModel
     */
    productCode: ProductCodeContract;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    acquisitionValue?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    currentValue?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentStrategyType {
    RebalanceToPlan = <any> 'RebalanceToPlan',
    BuyAndHold = <any> 'BuyAndHold'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IsaContributionStrategy {
    OnlyBondsAndSharesIsa = <any> 'OnlyBondsAndSharesIsa',
    PreferLifetimeIsa = <any> 'PreferLifetimeIsa'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LossRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @interface MonthlyTaxesAndReductionsResponse
 */
export interface MonthlyTaxesAndReductionsResponse {
    /**
     * 
     * @type {number}
     * @memberof MonthlyTaxesAndReductionsResponse
     */
    municipalTax?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyTaxesAndReductionsResponse
     */
    stateTax?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyTaxesAndReductionsResponse
     */
    jobbskatteavdrag?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyTaxesAndReductionsResponse
     */
    netSalary?: number;
}
/**
 * 
 * @export
 * @interface MortgageRequest
 */
export interface MortgageRequest {
    /**
     * 
     * @type {string}
     * @memberof MortgageRequest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageRequest
     */
    fixedRatePeriodInMonths: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageRequest
     */
    annualInterestRate: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageRequest
     */
    minimumMonthlyAmortization?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgageRequest
     */
    interestRatePaymentAccountId: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageRequest
     */
    currentValue?: number;
}
/**
 * 
 * @export
 * @interface OneTimeTransaction
 */
export interface OneTimeTransaction {
    /**
     * 
     * @type {string}
     * @memberof OneTimeTransaction
     */
    sourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeTransaction
     */
    targetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof OneTimeTransaction
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTransaction
     */
    amount?: number;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof OneTimeTransaction
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface PensionAccountsRequestModel
 */
export interface PensionAccountsRequestModel {
    /**
     * 
     * @type {Array<SweIncomePensionAccountRequestModel>}
     * @memberof PensionAccountsRequestModel
     */
    sweIncomePensionAccounts?: Array<SweIncomePensionAccountRequestModel>;
    /**
     * 
     * @type {Array<SweAllocationPensionAccountRequestModel>}
     * @memberof PensionAccountsRequestModel
     */
    swePremiumPensionAccounts?: Array<SweAllocationPensionAccountRequestModel>;
    /**
     * 
     * @type {Array<SweAllocationPensionAccountRequestModel>}
     * @memberof PensionAccountsRequestModel
     */
    sweOccupationalPensionAccounts?: Array<SweAllocationPensionAccountRequestModel>;
}
/**
 * 
 * @export
 * @interface PensionForecastResponseModel
 */
export interface PensionForecastResponseModel {
    /**
     * 
     * @type {DesiredPensionResponseModel}
     * @memberof PensionForecastResponseModel
     */
    desiredPension: DesiredPensionResponseModel;
    /**
     * 
     * @type {RetirementAgeResponseModel}
     * @memberof PensionForecastResponseModel
     */
    desiredRetirementAge: RetirementAgeResponseModel;
    /**
     * 
     * @type {number}
     * @memberof PensionForecastResponseModel
     */
    defaultRetirementAge: number;
    /**
     * 
     * @type {Array<PensionPaymentOutcomeResponseModel>}
     * @memberof PensionForecastResponseModel
     */
    estimatedMonthlySalaryEquivalentPensionPaymentOutcomes: Array<PensionPaymentOutcomeResponseModel>;
    /**
     * 
     * @type {AgeResponseModel}
     * @memberof PensionForecastResponseModel
     */
    pensionCutoffAge: AgeResponseModel;
    /**
     * 
     * @type {number}
     * @memberof PensionForecastResponseModel
     */
    salaryAtDefaultRetirementAge?: number;
    /**
     * 
     * @type {number}
     * @memberof PensionForecastResponseModel
     */
    salaryAtDesiredRetirementAge?: number;
    /**
     * 
     * @type {number}
     * @memberof PensionForecastResponseModel
     */
    suggestedPensionPayment?: number;
    /**
     * 
     * @type {PensionPotsCapitalResponseModel}
     * @memberof PensionForecastResponseModel
     */
    estimatedNetPensionCapital: PensionPotsCapitalResponseModel;
    /**
     * 
     * @type {number}
     * @memberof PensionForecastResponseModel
     */
    requiredMonthlyDeposit?: number;
    /**
     * 
     * @type {Array<PortfolioTimePercentileResponse>}
     * @memberof PensionForecastResponseModel
     */
    portfolioDevelopmentTimeSeries?: Array<PortfolioTimePercentileResponse>;
    /**
     * 
     * @type {string}
     * @memberof PensionForecastResponseModel
     */
    scenarioSetId: string;
    /**
     * 
     * @type {number}
     * @memberof PensionForecastResponseModel
     */
    utility: number;
    /**
     * 
     * @type {string}
     * @memberof PensionForecastResponseModel
     */
    assemblyVersion?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PensionInvestmentRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @interface PensionPaymentOutcomeResponseModel
 */
export interface PensionPaymentOutcomeResponseModel {
    /**
     * 
     * @type {number}
     * @memberof PensionPaymentOutcomeResponseModel
     */
    percentile?: number;
    /**
     * 
     * @type {PensionPaymentResponseModel}
     * @memberof PensionPaymentOutcomeResponseModel
     */
    averageOutcomes?: PensionPaymentResponseModel;
    /**
     * 
     * @type {PensionPaymentResponseModel}
     * @memberof PensionPaymentOutcomeResponseModel
     */
    actualOutcomes?: PensionPaymentResponseModel;
}
/**
 * 
 * @export
 * @interface PensionPaymentResponseModel
 */
export interface PensionPaymentResponseModel {
    /**
     * 
     * @type {number}
     * @memberof PensionPaymentResponseModel
     */
    nationalPension?: number;
    /**
     * 
     * @type {number}
     * @memberof PensionPaymentResponseModel
     */
    occupationalPension?: number;
    /**
     * NewPrivate pension payment based existing private capital
     * @type {number}
     * @memberof PensionPaymentResponseModel
     */
    currentPrivatePension?: number;
    /**
     * NewPrivate pension payment based on new, and currently non-existing, monthly savings and one-time deposit (if such exists)
     * @type {number}
     * @memberof PensionPaymentResponseModel
     */
    newPrivatePension?: number;
}
/**
 * 
 * @export
 * @interface PensionPotsCapitalResponseModel
 */
export interface PensionPotsCapitalResponseModel {
    /**
     * 
     * @type {number}
     * @memberof PensionPotsCapitalResponseModel
     */
    nationalIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof PensionPotsCapitalResponseModel
     */
    nationalPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof PensionPotsCapitalResponseModel
     */
    occupational?: number;
}
/**
 * 
 * @export
 * @interface PensionRequest
 */
export interface PensionRequest {
    /**
     * 
     * @type {Date}
     * @memberof PensionRequest
     */
    dateOfRetirement: Date;
}
/**
 * 
 * @export
 * @interface PensionSpecificRiskAversion
 */
export interface PensionSpecificRiskAversion {
    /**
     * 
     * @type {number}
     * @memberof PensionSpecificRiskAversion
     */
    value?: number;
    /**
     * 
     * @type {PensionSpecificRiskAversionLevel}
     * @memberof PensionSpecificRiskAversion
     */
    level?: PensionSpecificRiskAversionLevel;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PensionSpecificRiskAversionLevel {
    NoRisk = <any> 'NoRisk',
    VeryLowRisk = <any> 'VeryLowRisk',
    LowRisk = <any> 'LowRisk',
    MediumLowRisk = <any> 'MediumLowRisk',
    MediumHighRisk = <any> 'MediumHighRisk',
    HighRisk = <any> 'HighRisk',
    VeryHighRisk = <any> 'VeryHighRisk'
}
/**
 * 
 * @export
 * @interface PointInTimeCashFlowResponse
 */
export interface PointInTimeCashFlowResponse {
    /**
     * 
     * @type {Date}
     * @memberof PointInTimeCashFlowResponse
     */
    pointInTime: Date;
    /**
     * 
     * @type {number}
     * @memberof PointInTimeCashFlowResponse
     */
    _in: number;
    /**
     * 
     * @type {number}
     * @memberof PointInTimeCashFlowResponse
     */
    out: number;
    /**
     * 
     * @type {number}
     * @memberof PointInTimeCashFlowResponse
     */
    net: number;
}
/**
 * 
 * @export
 * @interface PongModel
 */
export interface PongModel {
    /**
     * 
     * @type {string}
     * @memberof PongModel
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof PongModel
     */
    pongedAtUtc?: Date;
}
/**
 * 
 * @export
 * @interface PortfolioOutcomeResponse
 */
export interface PortfolioOutcomeResponse {
    /**
     * 
     * @type {Date}
     * @memberof PortfolioOutcomeResponse
     */
    pointInTime: Date;
    /**
     * 
     * @type {number}
     * @memberof PortfolioOutcomeResponse
     */
    value: number;
}
/**
 * 
 * @export
 * @interface PortfolioStrategiesRequest
 */
export interface PortfolioStrategiesRequest {
    /**
     * 
     * @type {Array<WaterfallAllocationStrategyRequest>}
     * @memberof PortfolioStrategiesRequest
     */
    waterfallAllocationStrategies?: Array<WaterfallAllocationStrategyRequest>;
}
/**
 * 
 * @export
 * @interface PortfolioTimePercentileResponse
 */
export interface PortfolioTimePercentileResponse {
    /**
     * 
     * @type {number}
     * @memberof PortfolioTimePercentileResponse
     */
    percentile: number;
    /**
     * 
     * @type {Array<PortfolioOutcomeResponse>}
     * @memberof PortfolioTimePercentileResponse
     */
    outcomes: Array<PortfolioOutcomeResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductCodeCodeType {
    Insurance = <any> 'Insurance',
    Isin = <any> 'Isin'
}
/**
 * 
 * @export
 * @interface ProductCodeContract
 */
export interface ProductCodeContract {
    /**
     * 
     * @type {ProductCodeCodeType}
     * @memberof ProductCodeContract
     */
    codeType: ProductCodeCodeType;
    /**
     * 
     * @type {string}
     * @memberof ProductCodeContract
     */
    code: string;
}
/**
 * 
 * @export
 * @interface RankedPortfolioResponseModel
 */
export interface RankedPortfolioResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RankedPortfolioResponseModel
     */
    portfolioId: string;
    /**
     * 
     * @type {PensionForecastResponseModel}
     * @memberof RankedPortfolioResponseModel
     */
    pensionForecast?: PensionForecastResponseModel;
}
/**
 * 
 * @export
 * @interface RankedPortfoliosResponseModel
 */
export interface RankedPortfoliosResponseModel {
    /**
     * 
     * @type {Array<RankedPortfolioResponseModel>}
     * @memberof RankedPortfoliosResponseModel
     */
    rankedPortfolios: Array<RankedPortfolioResponseModel>;
    /**
     * 
     * @type {RiskAversionParameterResponseModel}
     * @memberof RankedPortfoliosResponseModel
     */
    riskAversion?: RiskAversionParameterResponseModel;
}
/**
 * 
 * @export
 * @interface RealEstateRequest
 */
export interface RealEstateRequest {
    /**
     * 
     * @type {string}
     * @memberof RealEstateRequest
     */
    id: string;
    /**
     * 
     * @type {TypeOfRealEstateRequest}
     * @memberof RealEstateRequest
     */
    typeOfProperty: TypeOfRealEstateRequest;
    /**
     * 
     * @type {number}
     * @memberof RealEstateRequest
     */
    acquisitionValue?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateRequest
     */
    currentValue?: number;
}
/**
 * 
 * @export
 * @interface RecurringTransaction
 */
export interface RecurringTransaction {
    /**
     * 
     * @type {string}
     * @memberof RecurringTransaction
     */
    sourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringTransaction
     */
    targetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RecurringTransaction
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof RecurringTransaction
     */
    endDate: Date;
    /**
     * 
     * @type {Frequency}
     * @memberof RecurringTransaction
     */
    frequency: Frequency;
    /**
     * 
     * @type {number}
     * @memberof RecurringTransaction
     */
    amount: number;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof RecurringTransaction
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface ReportMissingProductCodeContract
 */
export interface ReportMissingProductCodeContract {
    /**
     * 
     * @type {string}
     * @memberof ReportMissingProductCodeContract
     */
    scenarioSetId?: string;
    /**
     * 
     * @type {Array<ProductCodeContract>}
     * @memberof ReportMissingProductCodeContract
     */
    productCodes?: Array<ProductCodeContract>;
}
/**
 * 
 * @export
 * @interface RequiredFundsFactorByObservationDateResponse
 */
export interface RequiredFundsFactorByObservationDateResponse {
    /**
     * 
     * @type {Date}
     * @memberof RequiredFundsFactorByObservationDateResponse
     */
    observationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof RequiredFundsFactorByObservationDateResponse
     */
    factor: number;
    /**
     * 
     * @type {number}
     * @memberof RequiredFundsFactorByObservationDateResponse
     */
    probabilityOfReachingTargetUsingExistingFunds: number;
}
/**
 * 
 * @export
 * @interface RequiredFundsFactorRequestModel
 */
export interface RequiredFundsFactorRequestModel {
    /**
     * 
     * @type {string}
     * @memberof RequiredFundsFactorRequestModel
     */
    scenarioSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RequiredFundsFactorRequestModel
     */
    startDate: Date;
    /**
     * 
     * @type {Array<Date>}
     * @memberof RequiredFundsFactorRequestModel
     */
    observationDates?: Array<Date>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequiredFundsFactorRequestModel
     */
    percentiles?: Array<number>;
    /**
     * 
     * @type {AssetsAndDebts}
     * @memberof RequiredFundsFactorRequestModel
     */
    assetsAndDebts: AssetsAndDebts;
    /**
     * 
     * @type {IncomesAndExpensesRequest}
     * @memberof RequiredFundsFactorRequestModel
     */
    incomesAndExpenses: IncomesAndExpensesRequest;
    /**
     * 
     * @type {number}
     * @memberof RequiredFundsFactorRequestModel
     */
    targetNetAssetValue?: number;
    /**
     * 
     * @type {string}
     * @memberof RequiredFundsFactorRequestModel
     */
    itemIdForFactorInAssetsAndDebts?: string;
    /**
     * 
     * @type {Domicile}
     * @memberof RequiredFundsFactorRequestModel
     */
    domicile?: Domicile;
    /**
     * 
     * @type {TaxInformationRequest}
     * @memberof RequiredFundsFactorRequestModel
     */
    taxInformation?: TaxInformationRequest;
}
/**
 * 
 * @export
 * @interface RequiredFundsFactorResponseModel
 */
export interface RequiredFundsFactorResponseModel {
    /**
     * 
     * @type {Array<RequiredFundsFactorByObservationDateResponse>}
     * @memberof RequiredFundsFactorResponseModel
     */
    requiredFundsFactors?: Array<RequiredFundsFactorByObservationDateResponse>;
}
/**
 * 
 * @export
 * @interface Response
 */
export interface Response {
    /**
     * 
     * @type {Array<ProductCodeContract>}
     * @memberof Response
     */
    missingMappings?: Array<ProductCodeContract>;
}
/**
 * 
 * @export
 * @interface RetirementAgeResponseModel
 */
export interface RetirementAgeResponseModel {
    /**
     * 
     * @type {IntervalResponseModelInt32}
     * @memberof RetirementAgeResponseModel
     */
    interval: IntervalResponseModelInt32;
    /**
     * 
     * @type {number}
     * @memberof RetirementAgeResponseModel
     */
    value?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReturnRiskLevel {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @interface RiskAversionParameterResponseModel
 */
export interface RiskAversionParameterResponseModel {
    /**
     * 
     * @type {GeneralRiskAversion}
     * @memberof RiskAversionParameterResponseModel
     */
    generalRiskAversion?: GeneralRiskAversion;
    /**
     * 
     * @type {PensionSpecificRiskAversion}
     * @memberof RiskAversionParameterResponseModel
     */
    pensionSpecificRiskAversion?: PensionSpecificRiskAversion;
}
/**
 * 
 * @export
 * @interface ScenarioSetResponseModel
 */
export interface ScenarioSetResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ScenarioSetResponseModel
     */
    scenarioSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ScenarioSetResponseModel
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ScenarioSetResponseModel
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface SweAllocationPensionAccountRequestModel
 */
export interface SweAllocationPensionAccountRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SweAllocationPensionAccountRequestModel
     */
    id: string;
    /**
     * 
     * @type {Array<InvestmentAllocationRequestModel>}
     * @memberof SweAllocationPensionAccountRequestModel
     */
    investmentAllocations?: Array<InvestmentAllocationRequestModel>;
    /**
     * 
     * @type {InvestmentStrategyType}
     * @memberof SweAllocationPensionAccountRequestModel
     */
    strategy?: InvestmentStrategyType;
}
/**
 * 
 * @export
 * @interface SweEmployeeSicknessCompensationRequest
 */
export interface SweEmployeeSicknessCompensationRequest {
    /**
     * 
     * @type {Array<SweGrossEarnedIncomePayment>}
     * @memberof SweEmployeeSicknessCompensationRequest
     */
    salaryTrajectory: Array<SweGrossEarnedIncomePayment>;
    /**
     * 
     * @type {Date}
     * @memberof SweEmployeeSicknessCompensationRequest
     */
    sickLeaveStartDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof SweEmployeeSicknessCompensationRequest
     */
    sickLeaveEndDate: Date;
    /**
     * 
     * @type {number}
     * @memberof SweEmployeeSicknessCompensationRequest
     */
    rateOfAbsence: number;
    /**
     * 
     * @type {EmployeeSicknessBenefitInsuranceTypeContract}
     * @memberof SweEmployeeSicknessCompensationRequest
     */
    employeeSicknessBenefitInsuranceType: EmployeeSicknessBenefitInsuranceTypeContract;
}
/**
 * 
 * @export
 * @interface SweGrossEarnedIncomePayment
 */
export interface SweGrossEarnedIncomePayment {
    /**
     * 
     * @type {Date}
     * @memberof SweGrossEarnedIncomePayment
     */
    dateOfPayment: Date;
    /**
     * 
     * @type {number}
     * @memberof SweGrossEarnedIncomePayment
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface SweIncomePensionAccountRequestModel
 */
export interface SweIncomePensionAccountRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SweIncomePensionAccountRequestModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SweIncomePensionAccountRequestModel
     */
    currentValue?: number;
}
/**
 * 
 * @export
 * @interface SwePensionPayment
 */
export interface SwePensionPayment {
    /**
     * 
     * @type {Date}
     * @memberof SwePensionPayment
     */
    dateOfPayment?: Date;
    /**
     * 
     * @type {number}
     * @memberof SwePensionPayment
     */
    incomePensionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SwePensionPayment
     */
    premiumPensionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SwePensionPayment
     */
    occupationalPensionAmount?: number;
}
/**
 * 
 * @export
 * @interface SwePermanentSicknessCompensationRequest
 */
export interface SwePermanentSicknessCompensationRequest {
    /**
     * 
     * @type {Array<SweGrossEarnedIncomePayment>}
     * @memberof SwePermanentSicknessCompensationRequest
     */
    salaryTrajectory: Array<SweGrossEarnedIncomePayment>;
    /**
     * 
     * @type {Date}
     * @memberof SwePermanentSicknessCompensationRequest
     */
    sickLeaveStartDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof SwePermanentSicknessCompensationRequest
     */
    sickLeaveEndDate: Date;
    /**
     * 
     * @type {number}
     * @memberof SwePermanentSicknessCompensationRequest
     */
    rateOfAbsence: number;
}
/**
 * 
 * @export
 * @interface TaxInformationRequest
 */
export interface TaxInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof TaxInformationRequest
     */
    municipalityKey?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationRequest
     */
    taxPaymentAssetsAndDebtsItemId: string;
}
/**
 * 
 * @export
 * @interface TransactionAccountRequest
 */
export interface TransactionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionAccountRequest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionAccountRequest
     */
    currentValue?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionAccountRequest
     */
    currencyCode: string;
}
/**
 * 
 * @export
 * @interface TransactionArray
 */
export interface TransactionArray {
    /**
     * 
     * @type {string}
     * @memberof TransactionArray
     */
    sourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionArray
     */
    targetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof TransactionArray
     */
    startDate?: Date;
    /**
     * 
     * @type {Frequency}
     * @memberof TransactionArray
     */
    frequency?: Frequency;
    /**
     * 
     * @type {Array<number>}
     * @memberof TransactionArray
     */
    amounts?: Array<number>;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof TransactionArray
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface Transactions
 */
export interface Transactions {
    /**
     * 
     * @type {Array<OneTimeTransaction>}
     * @memberof Transactions
     */
    oneTimeTransactions: Array<OneTimeTransaction>;
    /**
     * 
     * @type {Array<RecurringTransaction>}
     * @memberof Transactions
     */
    recurringTransactions: Array<RecurringTransaction>;
    /**
     * 
     * @type {Array<TransactionArray>}
     * @memberof Transactions
     */
    transactionArrays: Array<TransactionArray>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfRealEstateRequest {
    PrivateHome = <any> 'PrivateHome'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfSharesRequest {
    K10 = <any> 'K10',
    K12 = <any> 'K12'
}
/**
 * 
 * @export
 * @interface UnlistedSharesRequestModel
 */
export interface UnlistedSharesRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UnlistedSharesRequestModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UnlistedSharesRequestModel
     */
    currentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof UnlistedSharesRequestModel
     */
    acquisitionValue?: number;
    /**
     * 
     * @type {TypeOfSharesRequest}
     * @memberof UnlistedSharesRequestModel
     */
    typeOfShares: TypeOfSharesRequest;
    /**
     * 
     * @type {ProductCodeContract}
     * @memberof UnlistedSharesRequestModel
     */
    productCode: ProductCodeContract;
}
/**
 * 
 * @export
 * @interface ValidationErrorModel
 */
export interface ValidationErrorModel {
    /**
     * 
     * @type {Array<FieldErrorModel>}
     * @memberof ValidationErrorModel
     */
    errors?: Array<FieldErrorModel>;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorModel
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface WaterfallAllocationStrategyRequest
 */
export interface WaterfallAllocationStrategyRequest {
    /**
     * 
     * @type {Array<WaterfallAllocationTierRequest>}
     * @memberof WaterfallAllocationStrategyRequest
     */
    tiers: Array<WaterfallAllocationTierRequest>;
}
/**
 * 
 * @export
 * @interface WaterfallAllocationTierRequest
 */
export interface WaterfallAllocationTierRequest {
    /**
     * 
     * @type {string}
     * @memberof WaterfallAllocationTierRequest
     */
    assetsAndDebtsItemId: string;
    /**
     * 
     * @type {number}
     * @memberof WaterfallAllocationTierRequest
     */
    priority: number;
    /**
     * 
     * @type {number}
     * @memberof WaterfallAllocationTierRequest
     */
    targetValue: number;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof WaterfallAllocationTierRequest
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * OutRankFinancialPlanningApi - fetch parameter creator
 * @export
 */
export const OutRankFinancialPlanningApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EngageComparisonRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1EngageComparisonPost(body: EngageComparisonRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1EngageComparisonPost.');
            }
            const localVarPath = `/financialplanning/v1/engage-comparison`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EngageComparisonRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinancialPlanningRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1FinancialPlanningPost(body: FinancialPlanningRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1FinancialPlanningPost.');
            }
            const localVarPath = `/financialplanning/v1/financial-planning`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FinancialPlanningRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SweEmployeeSicknessCompensationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweEmployeeSicknessCompensationPost(body: SweEmployeeSicknessCompensationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1IncomeSweEmployeeSicknessCompensationPost.');
            }
            const localVarPath = `/financialplanning/v1/income/swe/employee-sickness-compensation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SweEmployeeSicknessCompensationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} grossMonthlySalaryAtStartDate 
         * @param {Date} dateOfBirth 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalaryAtStartDate: number, dateOfBirth: Date, startDate: Date, endDate: Date, options: any = {}): FetchArgs {
            // verify required parameter 'grossMonthlySalaryAtStartDate' is not null or undefined
            if (grossMonthlySalaryAtStartDate === null || grossMonthlySalaryAtStartDate === undefined) {
                throw new RequiredError('grossMonthlySalaryAtStartDate','Required parameter grossMonthlySalaryAtStartDate was null or undefined when calling financialplanningV1IncomeSweGrossSalaryPredictionGet.');
            }
            // verify required parameter 'dateOfBirth' is not null or undefined
            if (dateOfBirth === null || dateOfBirth === undefined) {
                throw new RequiredError('dateOfBirth','Required parameter dateOfBirth was null or undefined when calling financialplanningV1IncomeSweGrossSalaryPredictionGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling financialplanningV1IncomeSweGrossSalaryPredictionGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling financialplanningV1IncomeSweGrossSalaryPredictionGet.');
            }
            const localVarPath = `/financialplanning/v1/income/swe/gross-salary-prediction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (grossMonthlySalaryAtStartDate !== undefined) {
                localVarQueryParameter['grossMonthlySalaryAtStartDate'] = grossMonthlySalaryAtStartDate;
            }

            if (dateOfBirth !== undefined) {
                localVarQueryParameter['dateOfBirth'] = (dateOfBirth as any).toISOString();
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IncomeGrossToNetSalaryFromTrajectoryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost(body: IncomeGrossToNetSalaryFromTrajectoryRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost.');
            }
            const localVarPath = `/financialplanning/v1/income/swe/gross-to-net-salary-from-trajectory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"IncomeGrossToNetSalaryFromTrajectoryRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} grossMonthlySalary 
         * @param {string} officialMunicipalityKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossToNetSalaryGet(grossMonthlySalary: number, officialMunicipalityKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'grossMonthlySalary' is not null or undefined
            if (grossMonthlySalary === null || grossMonthlySalary === undefined) {
                throw new RequiredError('grossMonthlySalary','Required parameter grossMonthlySalary was null or undefined when calling financialplanningV1IncomeSweGrossToNetSalaryGet.');
            }
            // verify required parameter 'officialMunicipalityKey' is not null or undefined
            if (officialMunicipalityKey === null || officialMunicipalityKey === undefined) {
                throw new RequiredError('officialMunicipalityKey','Required parameter officialMunicipalityKey was null or undefined when calling financialplanningV1IncomeSweGrossToNetSalaryGet.');
            }
            const localVarPath = `/financialplanning/v1/income/swe/gross-to-net-salary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (grossMonthlySalary !== undefined) {
                localVarQueryParameter['grossMonthlySalary'] = grossMonthlySalary;
            }

            if (officialMunicipalityKey !== undefined) {
                localVarQueryParameter['officialMunicipalityKey'] = officialMunicipalityKey;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<SweGrossEarnedIncomePayment>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost(body: Array<SweGrossEarnedIncomePayment>, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost.');
            }
            const localVarPath = `/financialplanning/v1/income/swe/pension-cash-flow-from-gross-salary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;SweGrossEarnedIncomePayment&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwePermanentSicknessCompensationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSwePermanentSicknessCompensationPost(body: SwePermanentSicknessCompensationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1IncomeSwePermanentSicknessCompensationPost.');
            }
            const localVarPath = `/financialplanning/v1/income/swe/permanent-sickness-compensation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SwePermanentSicknessCompensationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} monthlyContributionAmountInGbp 
         * @param {string} bondsAndSharesIsaAccountId 
         * @param {string} lifetimeIsaAccountId 
         * @param {IsaContributionStrategy} isaContributionStrategy 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1InvestmentAccountGbrIsaTransactionsGet(monthlyContributionAmountInGbp: number, bondsAndSharesIsaAccountId: string, lifetimeIsaAccountId: string, isaContributionStrategy: IsaContributionStrategy, startDate: Date, endDate: Date, options: any = {}): FetchArgs {
            // verify required parameter 'monthlyContributionAmountInGbp' is not null or undefined
            if (monthlyContributionAmountInGbp === null || monthlyContributionAmountInGbp === undefined) {
                throw new RequiredError('monthlyContributionAmountInGbp','Required parameter monthlyContributionAmountInGbp was null or undefined when calling financialplanningV1InvestmentAccountGbrIsaTransactionsGet.');
            }
            // verify required parameter 'bondsAndSharesIsaAccountId' is not null or undefined
            if (bondsAndSharesIsaAccountId === null || bondsAndSharesIsaAccountId === undefined) {
                throw new RequiredError('bondsAndSharesIsaAccountId','Required parameter bondsAndSharesIsaAccountId was null or undefined when calling financialplanningV1InvestmentAccountGbrIsaTransactionsGet.');
            }
            // verify required parameter 'lifetimeIsaAccountId' is not null or undefined
            if (lifetimeIsaAccountId === null || lifetimeIsaAccountId === undefined) {
                throw new RequiredError('lifetimeIsaAccountId','Required parameter lifetimeIsaAccountId was null or undefined when calling financialplanningV1InvestmentAccountGbrIsaTransactionsGet.');
            }
            // verify required parameter 'isaContributionStrategy' is not null or undefined
            if (isaContributionStrategy === null || isaContributionStrategy === undefined) {
                throw new RequiredError('isaContributionStrategy','Required parameter isaContributionStrategy was null or undefined when calling financialplanningV1InvestmentAccountGbrIsaTransactionsGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling financialplanningV1InvestmentAccountGbrIsaTransactionsGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling financialplanningV1InvestmentAccountGbrIsaTransactionsGet.');
            }
            const localVarPath = `/financialplanning/v1/investment-account/gbr/isa-transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (monthlyContributionAmountInGbp !== undefined) {
                localVarQueryParameter['monthlyContributionAmountInGbp'] = monthlyContributionAmountInGbp;
            }

            if (bondsAndSharesIsaAccountId !== undefined) {
                localVarQueryParameter['bondsAndSharesIsaAccountId'] = bondsAndSharesIsaAccountId;
            }

            if (lifetimeIsaAccountId !== undefined) {
                localVarQueryParameter['lifetimeIsaAccountId'] = lifetimeIsaAccountId;
            }

            if (isaContributionStrategy !== undefined) {
                localVarQueryParameter['isaContributionStrategy'] = isaContributionStrategy;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} grossYearlySalaryInGbp 
         * @param {number} monthlyContributionAmountInGbp 
         * @param {string} accountId 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1InvestmentAccountGbrSippTransactionsGet(grossYearlySalaryInGbp: number, monthlyContributionAmountInGbp: number, accountId: string, startDate: Date, endDate: Date, options: any = {}): FetchArgs {
            // verify required parameter 'grossYearlySalaryInGbp' is not null or undefined
            if (grossYearlySalaryInGbp === null || grossYearlySalaryInGbp === undefined) {
                throw new RequiredError('grossYearlySalaryInGbp','Required parameter grossYearlySalaryInGbp was null or undefined when calling financialplanningV1InvestmentAccountGbrSippTransactionsGet.');
            }
            // verify required parameter 'monthlyContributionAmountInGbp' is not null or undefined
            if (monthlyContributionAmountInGbp === null || monthlyContributionAmountInGbp === undefined) {
                throw new RequiredError('monthlyContributionAmountInGbp','Required parameter monthlyContributionAmountInGbp was null or undefined when calling financialplanningV1InvestmentAccountGbrSippTransactionsGet.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling financialplanningV1InvestmentAccountGbrSippTransactionsGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling financialplanningV1InvestmentAccountGbrSippTransactionsGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling financialplanningV1InvestmentAccountGbrSippTransactionsGet.');
            }
            const localVarPath = `/financialplanning/v1/investment-account/gbr/sipp-transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (grossYearlySalaryInGbp !== undefined) {
                localVarQueryParameter['grossYearlySalaryInGbp'] = grossYearlySalaryInGbp;
            }

            if (monthlyContributionAmountInGbp !== undefined) {
                localVarQueryParameter['monthlyContributionAmountInGbp'] = monthlyContributionAmountInGbp;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdviceRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1PensionSweAdvicePost(body: AdviceRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1PensionSweAdvicePost.');
            }
            const localVarPath = `/financialplanning/v1/pension/swe/advice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdviceRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1PingGet(options: any = {}): FetchArgs {
            const localVarPath = `/financialplanning/v1/ping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} scenarioSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductMappedFundsGet(scenarioSetId: string, options: any = {}): FetchArgs {
            // verify required parameter 'scenarioSetId' is not null or undefined
            if (scenarioSetId === null || scenarioSetId === undefined) {
                throw new RequiredError('scenarioSetId','Required parameter scenarioSetId was null or undefined when calling financialplanningV1ProductMappedFundsGet.');
            }
            const localVarPath = `/financialplanning/v1/product/mapped-funds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (scenarioSetId !== undefined) {
                localVarQueryParameter['scenarioSetId'] = scenarioSetId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportMissingProductCodeContract} body Report which mappings are required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductReportMappingsPost(body: ReportMissingProductCodeContract, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1ProductReportMappingsPost.');
            }
            const localVarPath = `/financialplanning/v1/product/report-mappings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportMissingProductCodeContract" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductionEnvironmentGet(options: any = {}): FetchArgs {
            const localVarPath = `/financialplanning/v1/production-environment`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequiredFundsFactorRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RequiredFundsFactorPost(body: RequiredFundsFactorRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1RequiredFundsFactorPost.');
            }
            const localVarPath = `/financialplanning/v1/required-funds-factor`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RequiredFundsFactorRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} desiredTotalSalaryEquivalentMonthlyPensionPayment 
         * @param {number} grossMonthlySalary 
         * @param {Date} dateOfBirth 
         * @param {Date} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RiskGeneralAversionOptionsGet(desiredTotalSalaryEquivalentMonthlyPensionPayment: number, grossMonthlySalary: number, dateOfBirth: Date, asOfDate: Date, options: any = {}): FetchArgs {
            // verify required parameter 'desiredTotalSalaryEquivalentMonthlyPensionPayment' is not null or undefined
            if (desiredTotalSalaryEquivalentMonthlyPensionPayment === null || desiredTotalSalaryEquivalentMonthlyPensionPayment === undefined) {
                throw new RequiredError('desiredTotalSalaryEquivalentMonthlyPensionPayment','Required parameter desiredTotalSalaryEquivalentMonthlyPensionPayment was null or undefined when calling financialplanningV1RiskGeneralAversionOptionsGet.');
            }
            // verify required parameter 'grossMonthlySalary' is not null or undefined
            if (grossMonthlySalary === null || grossMonthlySalary === undefined) {
                throw new RequiredError('grossMonthlySalary','Required parameter grossMonthlySalary was null or undefined when calling financialplanningV1RiskGeneralAversionOptionsGet.');
            }
            // verify required parameter 'dateOfBirth' is not null or undefined
            if (dateOfBirth === null || dateOfBirth === undefined) {
                throw new RequiredError('dateOfBirth','Required parameter dateOfBirth was null or undefined when calling financialplanningV1RiskGeneralAversionOptionsGet.');
            }
            // verify required parameter 'asOfDate' is not null or undefined
            if (asOfDate === null || asOfDate === undefined) {
                throw new RequiredError('asOfDate','Required parameter asOfDate was null or undefined when calling financialplanningV1RiskGeneralAversionOptionsGet.');
            }
            const localVarPath = `/financialplanning/v1/risk/general-aversion-options`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (desiredTotalSalaryEquivalentMonthlyPensionPayment !== undefined) {
                localVarQueryParameter['desiredTotalSalaryEquivalentMonthlyPensionPayment'] = desiredTotalSalaryEquivalentMonthlyPensionPayment;
            }

            if (grossMonthlySalary !== undefined) {
                localVarQueryParameter['grossMonthlySalary'] = grossMonthlySalary;
            }

            if (dateOfBirth !== undefined) {
                localVarQueryParameter['dateOfBirth'] = (dateOfBirth as any).toISOString();
            }

            if (asOfDate !== undefined) {
                localVarQueryParameter['asOfDate'] = (asOfDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PensionInvestmentRiskLevel} pensionInvestmentRiskLevel 
         * @param {InvestmentRiskLevel} investmentRiskLevel 
         * @param {ReturnRiskLevel} returnRiskLevel 
         * @param {LossRiskLevel} lossRiskLevel 
         * @param {Date} dateOfBirth 
         * @param {number} retirementAge 
         * @param {number} grossMonthlySalary 
         * @param {number} investmentCapacity 
         * @param {Date} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RiskRiskAversionGet(pensionInvestmentRiskLevel: PensionInvestmentRiskLevel, investmentRiskLevel: InvestmentRiskLevel, returnRiskLevel: ReturnRiskLevel, lossRiskLevel: LossRiskLevel, dateOfBirth: Date, retirementAge: number, grossMonthlySalary: number, investmentCapacity: number, asOfDate: Date, options: any = {}): FetchArgs {
            // verify required parameter 'pensionInvestmentRiskLevel' is not null or undefined
            if (pensionInvestmentRiskLevel === null || pensionInvestmentRiskLevel === undefined) {
                throw new RequiredError('pensionInvestmentRiskLevel','Required parameter pensionInvestmentRiskLevel was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'investmentRiskLevel' is not null or undefined
            if (investmentRiskLevel === null || investmentRiskLevel === undefined) {
                throw new RequiredError('investmentRiskLevel','Required parameter investmentRiskLevel was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'returnRiskLevel' is not null or undefined
            if (returnRiskLevel === null || returnRiskLevel === undefined) {
                throw new RequiredError('returnRiskLevel','Required parameter returnRiskLevel was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'lossRiskLevel' is not null or undefined
            if (lossRiskLevel === null || lossRiskLevel === undefined) {
                throw new RequiredError('lossRiskLevel','Required parameter lossRiskLevel was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'dateOfBirth' is not null or undefined
            if (dateOfBirth === null || dateOfBirth === undefined) {
                throw new RequiredError('dateOfBirth','Required parameter dateOfBirth was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'retirementAge' is not null or undefined
            if (retirementAge === null || retirementAge === undefined) {
                throw new RequiredError('retirementAge','Required parameter retirementAge was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'grossMonthlySalary' is not null or undefined
            if (grossMonthlySalary === null || grossMonthlySalary === undefined) {
                throw new RequiredError('grossMonthlySalary','Required parameter grossMonthlySalary was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'investmentCapacity' is not null or undefined
            if (investmentCapacity === null || investmentCapacity === undefined) {
                throw new RequiredError('investmentCapacity','Required parameter investmentCapacity was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            // verify required parameter 'asOfDate' is not null or undefined
            if (asOfDate === null || asOfDate === undefined) {
                throw new RequiredError('asOfDate','Required parameter asOfDate was null or undefined when calling financialplanningV1RiskRiskAversionGet.');
            }
            const localVarPath = `/financialplanning/v1/risk/risk-aversion`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pensionInvestmentRiskLevel !== undefined) {
                localVarQueryParameter['pensionInvestmentRiskLevel'] = pensionInvestmentRiskLevel;
            }

            if (investmentRiskLevel !== undefined) {
                localVarQueryParameter['investmentRiskLevel'] = investmentRiskLevel;
            }

            if (returnRiskLevel !== undefined) {
                localVarQueryParameter['returnRiskLevel'] = returnRiskLevel;
            }

            if (lossRiskLevel !== undefined) {
                localVarQueryParameter['lossRiskLevel'] = lossRiskLevel;
            }

            if (dateOfBirth !== undefined) {
                localVarQueryParameter['dateOfBirth'] = (dateOfBirth as any).toISOString();
            }

            if (retirementAge !== undefined) {
                localVarQueryParameter['retirementAge'] = retirementAge;
            }

            if (grossMonthlySalary !== undefined) {
                localVarQueryParameter['grossMonthlySalary'] = grossMonthlySalary;
            }

            if (investmentCapacity !== undefined) {
                localVarQueryParameter['investmentCapacity'] = investmentCapacity;
            }

            if (asOfDate !== undefined) {
                localVarQueryParameter['asOfDate'] = (asOfDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1SimulationScenarioSetsGet(options: any = {}): FetchArgs {
            const localVarPath = `/financialplanning/v1/simulation/scenario-sets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CashFlowsAggregationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1TransactionsCashFlowsAggregationPost(body: CashFlowsAggregationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financialplanningV1TransactionsCashFlowsAggregationPost.');
            }
            const localVarPath = `/financialplanning/v1/transactions/cash-flows-aggregation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CashFlowsAggregationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutRankFinancialPlanningApi - functional programming interface
 * @export
 */
export const OutRankFinancialPlanningApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EngageComparisonRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1EngageComparisonPost(body: EngageComparisonRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EngageComparisonResponseModel> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1EngageComparisonPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {FinancialPlanningRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1FinancialPlanningPost(body: FinancialPlanningRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancialPlanningResponseModel> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1FinancialPlanningPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SweEmployeeSicknessCompensationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweEmployeeSicknessCompensationPost(body: SweEmployeeSicknessCompensationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SweGrossEarnedIncomePayment>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1IncomeSweEmployeeSicknessCompensationPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} grossMonthlySalaryAtStartDate 
         * @param {Date} dateOfBirth 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalaryAtStartDate: number, dateOfBirth: Date, startDate: Date, endDate: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SweGrossEarnedIncomePayment>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalaryAtStartDate, dateOfBirth, startDate, endDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {IncomeGrossToNetSalaryFromTrajectoryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost(body: IncomeGrossToNetSalaryFromTrajectoryRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SweGrossEarnedIncomePayment>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} grossMonthlySalary 
         * @param {string} officialMunicipalityKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossToNetSalaryGet(grossMonthlySalary: number, officialMunicipalityKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MonthlyTaxesAndReductionsResponse> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1IncomeSweGrossToNetSalaryGet(grossMonthlySalary, officialMunicipalityKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<SweGrossEarnedIncomePayment>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost(body: Array<SweGrossEarnedIncomePayment>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SwePensionPayment>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SwePermanentSicknessCompensationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSwePermanentSicknessCompensationPost(body: SwePermanentSicknessCompensationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SweGrossEarnedIncomePayment>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1IncomeSwePermanentSicknessCompensationPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} monthlyContributionAmountInGbp 
         * @param {string} bondsAndSharesIsaAccountId 
         * @param {string} lifetimeIsaAccountId 
         * @param {IsaContributionStrategy} isaContributionStrategy 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1InvestmentAccountGbrIsaTransactionsGet(monthlyContributionAmountInGbp: number, bondsAndSharesIsaAccountId: string, lifetimeIsaAccountId: string, isaContributionStrategy: IsaContributionStrategy, startDate: Date, endDate: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RecurringTransaction>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1InvestmentAccountGbrIsaTransactionsGet(monthlyContributionAmountInGbp, bondsAndSharesIsaAccountId, lifetimeIsaAccountId, isaContributionStrategy, startDate, endDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} grossYearlySalaryInGbp 
         * @param {number} monthlyContributionAmountInGbp 
         * @param {string} accountId 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1InvestmentAccountGbrSippTransactionsGet(grossYearlySalaryInGbp: number, monthlyContributionAmountInGbp: number, accountId: string, startDate: Date, endDate: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RecurringTransaction>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1InvestmentAccountGbrSippTransactionsGet(grossYearlySalaryInGbp, monthlyContributionAmountInGbp, accountId, startDate, endDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdviceRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1PensionSweAdvicePost(body: AdviceRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RankedPortfoliosResponseModel> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1PensionSweAdvicePost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1PingGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PongModel> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1PingGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} scenarioSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductMappedFundsGet(scenarioSetId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductCodeContract>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1ProductMappedFundsGet(scenarioSetId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportMissingProductCodeContract} body Report which mappings are required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductReportMappingsPost(body: ReportMissingProductCodeContract, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1ProductReportMappingsPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductionEnvironmentGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1ProductionEnvironmentGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RequiredFundsFactorRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RequiredFundsFactorPost(body: RequiredFundsFactorRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RequiredFundsFactorResponseModel> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1RequiredFundsFactorPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} desiredTotalSalaryEquivalentMonthlyPensionPayment 
         * @param {number} grossMonthlySalary 
         * @param {Date} dateOfBirth 
         * @param {Date} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RiskGeneralAversionOptionsGet(desiredTotalSalaryEquivalentMonthlyPensionPayment: number, grossMonthlySalary: number, dateOfBirth: Date, asOfDate: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GeneralAversionOptionsResponseModel> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1RiskGeneralAversionOptionsGet(desiredTotalSalaryEquivalentMonthlyPensionPayment, grossMonthlySalary, dateOfBirth, asOfDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PensionInvestmentRiskLevel} pensionInvestmentRiskLevel 
         * @param {InvestmentRiskLevel} investmentRiskLevel 
         * @param {ReturnRiskLevel} returnRiskLevel 
         * @param {LossRiskLevel} lossRiskLevel 
         * @param {Date} dateOfBirth 
         * @param {number} retirementAge 
         * @param {number} grossMonthlySalary 
         * @param {number} investmentCapacity 
         * @param {Date} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RiskRiskAversionGet(pensionInvestmentRiskLevel: PensionInvestmentRiskLevel, investmentRiskLevel: InvestmentRiskLevel, returnRiskLevel: ReturnRiskLevel, lossRiskLevel: LossRiskLevel, dateOfBirth: Date, retirementAge: number, grossMonthlySalary: number, investmentCapacity: number, asOfDate: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RiskAversionParameterResponseModel> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1RiskRiskAversionGet(pensionInvestmentRiskLevel, investmentRiskLevel, returnRiskLevel, lossRiskLevel, dateOfBirth, retirementAge, grossMonthlySalary, investmentCapacity, asOfDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1SimulationScenarioSetsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ScenarioSetResponseModel>> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1SimulationScenarioSetsGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CashFlowsAggregationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1TransactionsCashFlowsAggregationPost(body: CashFlowsAggregationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CashFlowsAggregationResponse> {
            const localVarFetchArgs = OutRankFinancialPlanningApiFetchParamCreator(configuration).financialplanningV1TransactionsCashFlowsAggregationPost(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OutRankFinancialPlanningApi - factory interface
 * @export
 */
export const OutRankFinancialPlanningApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {EngageComparisonRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1EngageComparisonPost(body: EngageComparisonRequestModel, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1EngageComparisonPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {FinancialPlanningRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1FinancialPlanningPost(body: FinancialPlanningRequestModel, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1FinancialPlanningPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SweEmployeeSicknessCompensationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweEmployeeSicknessCompensationPost(body: SweEmployeeSicknessCompensationRequest, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1IncomeSweEmployeeSicknessCompensationPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} grossMonthlySalaryAtStartDate 
         * @param {Date} dateOfBirth 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalaryAtStartDate: number, dateOfBirth: Date, startDate: Date, endDate: Date, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalaryAtStartDate, dateOfBirth, startDate, endDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {IncomeGrossToNetSalaryFromTrajectoryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost(body: IncomeGrossToNetSalaryFromTrajectoryRequest, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} grossMonthlySalary 
         * @param {string} officialMunicipalityKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSweGrossToNetSalaryGet(grossMonthlySalary: number, officialMunicipalityKey: string, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1IncomeSweGrossToNetSalaryGet(grossMonthlySalary, officialMunicipalityKey, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<SweGrossEarnedIncomePayment>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost(body: Array<SweGrossEarnedIncomePayment>, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SwePermanentSicknessCompensationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1IncomeSwePermanentSicknessCompensationPost(body: SwePermanentSicknessCompensationRequest, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1IncomeSwePermanentSicknessCompensationPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} monthlyContributionAmountInGbp 
         * @param {string} bondsAndSharesIsaAccountId 
         * @param {string} lifetimeIsaAccountId 
         * @param {IsaContributionStrategy} isaContributionStrategy 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1InvestmentAccountGbrIsaTransactionsGet(monthlyContributionAmountInGbp: number, bondsAndSharesIsaAccountId: string, lifetimeIsaAccountId: string, isaContributionStrategy: IsaContributionStrategy, startDate: Date, endDate: Date, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1InvestmentAccountGbrIsaTransactionsGet(monthlyContributionAmountInGbp, bondsAndSharesIsaAccountId, lifetimeIsaAccountId, isaContributionStrategy, startDate, endDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} grossYearlySalaryInGbp 
         * @param {number} monthlyContributionAmountInGbp 
         * @param {string} accountId 
         * @param {Date} startDate 
         * @param {Date} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1InvestmentAccountGbrSippTransactionsGet(grossYearlySalaryInGbp: number, monthlyContributionAmountInGbp: number, accountId: string, startDate: Date, endDate: Date, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1InvestmentAccountGbrSippTransactionsGet(grossYearlySalaryInGbp, monthlyContributionAmountInGbp, accountId, startDate, endDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdviceRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1PensionSweAdvicePost(body: AdviceRequestModel, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1PensionSweAdvicePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1PingGet(options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1PingGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} scenarioSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductMappedFundsGet(scenarioSetId: string, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1ProductMappedFundsGet(scenarioSetId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportMissingProductCodeContract} body Report which mappings are required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductReportMappingsPost(body: ReportMissingProductCodeContract, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1ProductReportMappingsPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1ProductionEnvironmentGet(options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1ProductionEnvironmentGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {RequiredFundsFactorRequestModel} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RequiredFundsFactorPost(body: RequiredFundsFactorRequestModel, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1RequiredFundsFactorPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} desiredTotalSalaryEquivalentMonthlyPensionPayment 
         * @param {number} grossMonthlySalary 
         * @param {Date} dateOfBirth 
         * @param {Date} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RiskGeneralAversionOptionsGet(desiredTotalSalaryEquivalentMonthlyPensionPayment: number, grossMonthlySalary: number, dateOfBirth: Date, asOfDate: Date, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1RiskGeneralAversionOptionsGet(desiredTotalSalaryEquivalentMonthlyPensionPayment, grossMonthlySalary, dateOfBirth, asOfDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PensionInvestmentRiskLevel} pensionInvestmentRiskLevel 
         * @param {InvestmentRiskLevel} investmentRiskLevel 
         * @param {ReturnRiskLevel} returnRiskLevel 
         * @param {LossRiskLevel} lossRiskLevel 
         * @param {Date} dateOfBirth 
         * @param {number} retirementAge 
         * @param {number} grossMonthlySalary 
         * @param {number} investmentCapacity 
         * @param {Date} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1RiskRiskAversionGet(pensionInvestmentRiskLevel: PensionInvestmentRiskLevel, investmentRiskLevel: InvestmentRiskLevel, returnRiskLevel: ReturnRiskLevel, lossRiskLevel: LossRiskLevel, dateOfBirth: Date, retirementAge: number, grossMonthlySalary: number, investmentCapacity: number, asOfDate: Date, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1RiskRiskAversionGet(pensionInvestmentRiskLevel, investmentRiskLevel, returnRiskLevel, lossRiskLevel, dateOfBirth, retirementAge, grossMonthlySalary, investmentCapacity, asOfDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1SimulationScenarioSetsGet(options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1SimulationScenarioSetsGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {CashFlowsAggregationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialplanningV1TransactionsCashFlowsAggregationPost(body: CashFlowsAggregationRequest, options?: any) {
            return OutRankFinancialPlanningApiFp(configuration).financialplanningV1TransactionsCashFlowsAggregationPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * OutRankFinancialPlanningApi - object-oriented interface
 * @export
 * @class OutRankFinancialPlanningApi
 * @extends {BaseAPI}
 */
export class OutRankFinancialPlanningApi extends BaseAPI {
    /**
     * 
     * @param {EngageComparisonRequestModel} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1EngageComparisonPost(body: EngageComparisonRequestModel, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1EngageComparisonPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {FinancialPlanningRequestModel} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1FinancialPlanningPost(body: FinancialPlanningRequestModel, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1FinancialPlanningPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SweEmployeeSicknessCompensationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1IncomeSweEmployeeSicknessCompensationPost(body: SweEmployeeSicknessCompensationRequest, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1IncomeSweEmployeeSicknessCompensationPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} grossMonthlySalaryAtStartDate 
     * @param {Date} dateOfBirth 
     * @param {Date} startDate 
     * @param {Date} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalaryAtStartDate: number, dateOfBirth: Date, startDate: Date, endDate: Date, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalaryAtStartDate, dateOfBirth, startDate, endDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {IncomeGrossToNetSalaryFromTrajectoryRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost(body: IncomeGrossToNetSalaryFromTrajectoryRequest, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} grossMonthlySalary 
     * @param {string} officialMunicipalityKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1IncomeSweGrossToNetSalaryGet(grossMonthlySalary: number, officialMunicipalityKey: string, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1IncomeSweGrossToNetSalaryGet(grossMonthlySalary, officialMunicipalityKey, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<SweGrossEarnedIncomePayment>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost(body: Array<SweGrossEarnedIncomePayment>, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1IncomeSwePensionCashFlowFromGrossSalaryPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SwePermanentSicknessCompensationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1IncomeSwePermanentSicknessCompensationPost(body: SwePermanentSicknessCompensationRequest, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1IncomeSwePermanentSicknessCompensationPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} monthlyContributionAmountInGbp 
     * @param {string} bondsAndSharesIsaAccountId 
     * @param {string} lifetimeIsaAccountId 
     * @param {IsaContributionStrategy} isaContributionStrategy 
     * @param {Date} startDate 
     * @param {Date} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1InvestmentAccountGbrIsaTransactionsGet(monthlyContributionAmountInGbp: number, bondsAndSharesIsaAccountId: string, lifetimeIsaAccountId: string, isaContributionStrategy: IsaContributionStrategy, startDate: Date, endDate: Date, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1InvestmentAccountGbrIsaTransactionsGet(monthlyContributionAmountInGbp, bondsAndSharesIsaAccountId, lifetimeIsaAccountId, isaContributionStrategy, startDate, endDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} grossYearlySalaryInGbp 
     * @param {number} monthlyContributionAmountInGbp 
     * @param {string} accountId 
     * @param {Date} startDate 
     * @param {Date} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1InvestmentAccountGbrSippTransactionsGet(grossYearlySalaryInGbp: number, monthlyContributionAmountInGbp: number, accountId: string, startDate: Date, endDate: Date, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1InvestmentAccountGbrSippTransactionsGet(grossYearlySalaryInGbp, monthlyContributionAmountInGbp, accountId, startDate, endDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdviceRequestModel} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1PensionSweAdvicePost(body: AdviceRequestModel, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1PensionSweAdvicePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1PingGet(options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1PingGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} scenarioSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1ProductMappedFundsGet(scenarioSetId: string, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1ProductMappedFundsGet(scenarioSetId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportMissingProductCodeContract} body Report which mappings are required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1ProductReportMappingsPost(body: ReportMissingProductCodeContract, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1ProductReportMappingsPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1ProductionEnvironmentGet(options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1ProductionEnvironmentGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RequiredFundsFactorRequestModel} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1RequiredFundsFactorPost(body: RequiredFundsFactorRequestModel, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1RequiredFundsFactorPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} desiredTotalSalaryEquivalentMonthlyPensionPayment 
     * @param {number} grossMonthlySalary 
     * @param {Date} dateOfBirth 
     * @param {Date} asOfDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1RiskGeneralAversionOptionsGet(desiredTotalSalaryEquivalentMonthlyPensionPayment: number, grossMonthlySalary: number, dateOfBirth: Date, asOfDate: Date, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1RiskGeneralAversionOptionsGet(desiredTotalSalaryEquivalentMonthlyPensionPayment, grossMonthlySalary, dateOfBirth, asOfDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PensionInvestmentRiskLevel} pensionInvestmentRiskLevel 
     * @param {InvestmentRiskLevel} investmentRiskLevel 
     * @param {ReturnRiskLevel} returnRiskLevel 
     * @param {LossRiskLevel} lossRiskLevel 
     * @param {Date} dateOfBirth 
     * @param {number} retirementAge 
     * @param {number} grossMonthlySalary 
     * @param {number} investmentCapacity 
     * @param {Date} asOfDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1RiskRiskAversionGet(pensionInvestmentRiskLevel: PensionInvestmentRiskLevel, investmentRiskLevel: InvestmentRiskLevel, returnRiskLevel: ReturnRiskLevel, lossRiskLevel: LossRiskLevel, dateOfBirth: Date, retirementAge: number, grossMonthlySalary: number, investmentCapacity: number, asOfDate: Date, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1RiskRiskAversionGet(pensionInvestmentRiskLevel, investmentRiskLevel, returnRiskLevel, lossRiskLevel, dateOfBirth, retirementAge, grossMonthlySalary, investmentCapacity, asOfDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1SimulationScenarioSetsGet(options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1SimulationScenarioSetsGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CashFlowsAggregationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutRankFinancialPlanningApi
     */
    public financialplanningV1TransactionsCashFlowsAggregationPost(body: CashFlowsAggregationRequest, options?: any) {
        return OutRankFinancialPlanningApiFp(this.configuration).financialplanningV1TransactionsCashFlowsAggregationPost(body, options)(this.fetch, this.basePath);
    }

}
