import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Collapse, InputBase, Paper, Slider, Typography, DialogTitle, Dialog, FormControl, Grid, TextField, IconButton, InputLabel, Select, MenuItem, Fade, Button, Divider, Tooltip, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions, List, ListItem, ListItemText } from '@material-ui/core';
import { Element, scroller } from 'react-scroll'
import { AssessmentOutlined, Launch, ArrowBack, Settings, ReportProblem, CheckCircleTwoTone, NotificationImportantTwoTone, WarningTwoTone } from '@material-ui/icons';
import { TextNumberFormat, NumberFormatCustom, CurrencyFormatCustom } from './NumberFormatCustom'
import moment from "moment";
import { styleDelegate } from './useStyles';
import InvestmentOutcomeBarChart from './InvestmentOutcomeBarChart';
import PensionFanChart from './PensionFanChart';
import InvestmentGoalFanChart from './InvestmentGoalFanChart';
import ExpectedShortfallChart from './ExpectedShortfallChart';
import { InvestmentGoalContext } from './InvestmentGoalContext';
import { PensionResultTable } from './PensionResultTable';
import { SettingsContext } from './SettingsContext';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { useDebounce } from './useDebounce';
import { useAppColors } from './useAppColors';
import { AdvisorViewShell } from './AdvisorViewShell';
import SpendingSummary from './SpendingSummary';
import { engageComparison, getDefaultEngageComparisonResult } from './api_client/tsClient';
import PortfolioComparisonBarChart from './PortfolioComparisonBarChart';
import customerSplit, { customerDataString } from './customerSplit';


moment.locale("se");

function GoalTracking() {

  const classes = makeStyles(styleDelegate)();
  const theme = useTheme();
  const colors = useAppColors();

  const settings = useContext(SettingsContext);
  const investmentGoal = useContext(InvestmentGoalContext);
  const basicCustomerInformation = useContext(BasicCustomerInformationContext);


  const [resultState, setResultState] = useState(getDefaultEngageComparisonResult());

  const currentPortfolioDevelopmentTimeSeries = resultState.engageComparisonResponseModel.currentPortfolioDevelopmentTimeSeries;
  const targetPortfolioDevelopmentTimeSeries = resultState.engageComparisonResponseModel.targetPortfolioDevelopmentTimeSeries;

  const [numberOfRequestInFlight, setNumberOfRequestInFlight] = useState(0);

  const { setErrorMessage, currentScenarioSet } = settings;

  // const [horizonInYears, setHorizonInYears] = useState(8);
  const [targetAmount, setTargetAmount] = useState(0);
  const [initialContribution, setInitialContribution] = useState(0);
  const [riskLevel, setRiskLevel] = useState(3);

  const debouncedInitialContribution = useDebounce(initialContribution, 800);
  const debouncedMonthlyContributions = useDebounce(investmentGoal.monthlyInvestmentAmount, 800);

  const [customerData, setCustomerData] = useState(customerSplit(customerDataString)[0]);


  const [allCustomers, setAllCustomers] = useState(customerSplit(customerDataString));

  // const debouncedCustomerData = useDebounce(customerData, 800);


  // useEffect(() => {
  //   const dateAt65 =  moment(customerData.dateOfBirth).add(65, 'years');
  //   const horizon = dateAt65.diff(moment(), 'year') 
  //   // setHorizonInYears(horizon);
  //   setInitialContribution(customerData.totalPremiumPaid);
  //   investmentGoal.setMonthlyInvestmentAmount(customerData.averageRegularPremium);
  // },[customerData, investmentGoal]);

  const dateAt65 = moment(customerData.dateOfBirth).add(65, 'years');
  const horizonInYears = dateAt65.diff(moment(), 'year')
  const debouncedHorizonInYears = useDebounce(horizonInYears, 800);

  useEffect(() => {
    if(currentScenarioSet.id === '') return;
    // if (prevGrossSalary !== debouncedBasicCustomerInformation.grossSalary || prevDateOfBirth !== debouncedBasicCustomerInformation.dateOfBirth) {
    setNumberOfRequestInFlight(state => state + 1);
    engageComparison(currentScenarioSet.id,
      debouncedHorizonInYears,
      customerData.portfolioValue,
      customerData.averageRegularPremium,
      investmentGoal.accountType,
      investmentGoal.isaContributionStrategy,
      basicCustomerInformation.grossSalary,
      customerData.currentPortfolio,
      customerData.referencePortfolio)
      .then(result => {
        try {
          handleResult(result, setResultState, setErrorMessage);
        } finally {
          setNumberOfRequestInFlight(state => state - 1);
        }
      });
    // }
  }, [
    debouncedHorizonInYears,
    debouncedInitialContribution,
    debouncedMonthlyContributions,
    investmentGoal.accountType,
    investmentGoal.isaContributionStrategy,
    basicCustomerInformation.grossSalary,
    setErrorMessage,
    customerData
  ]);

  useEffect(() => {
    setTargetAmount(resultState.outcomesAtHorizon.median);
  }, [resultState]);


  // const marks = [
  //   {
  //     value: 0,
  //     label: 'No risk',
  //   },
  //   {
  //     value: 1,
  //     label: 'Low',
  //   },
  //   {
  //     value: 2,
  //     label: 'Low-medium',
  //   },
  //   {
  //     value: 3,
  //     label: 'Medium',
  //   },
  //   {
  //     value: 4,
  //     label: 'Medium-high',
  //   },
  //   {
  //     value: 5,
  //     label: 'High',
  //   },
  // ];

  const requiredEnabled = resultState.requiredInitialContribution > initialContribution;

  const [isaContributionStrategiesOpen, setIsaContributionStrategiesOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsaContributionStrategiesOpen(true);
  };

  const handleClose = () => {
    setIsaContributionStrategiesOpen(false);
  };

  const isaContributionStrategies = [
    { value: 'OnlyBondsAndSharesIsa', text: 'Only bonds and shares ISA' },
    { value: 'PreferLifetimeIsa', text: 'Prefer lifetime ISA' }
  ];

  const formInputs = <div>
    <Grid item id='backgroundid' style={{ minHeight: '100%', maxWidth: '100%' }}>
      <Grid container spacing={0} className={classes.paperContainer} style={{ textAlign: 'left', paddingTop: theme.spacing(2) }} direction="row" alignItems='stretch' justify='center'>
        <Grid style={{display:'none'}} item xs={12} sm={6}>
          <FormControl style={{ width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
            {/* <SliderInput value={horizonInYears} onChange={setHorizonInYears} minValue={0} maxValue={30} title={'Time Frame (years)'} /> */}
            <SliderInput value={targetAmount} showCurrency onChange={setTargetAmount} minValue={0} maxValue={1000000} title={'Target Amount'} />

            <RequiredInput
              title={'Monthly Contribution'}
              requiredEnabled={requiredEnabled}
              requiredContribution={resultState.requiredMonthlyContribution}
              contribution={investmentGoal.monthlyInvestmentAmount}
              setContribution={investmentGoal.setMonthlyInvestmentAmount}
              maxValue={9999} />

            <RequiredInput
              title={'Initial Contribution'}
              requiredEnabled={requiredEnabled}
              requiredContribution={resultState.requiredInitialContribution}
              contribution={initialContribution}
              setContribution={setInitialContribution}
              maxValue={999999} />
          </FormControl>
        </Grid>
        <Grid style={{display:'none'}} item xs={12} sm={6}>
          <FormControl style={{ width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
            {/* <Typography id="risklevel">
              Risk Level
        </Typography>
            <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
              <Slider
                classes={{ markLabel: classes.smallText }}
                min={0}
                max={5}
                value={riskLevel}
                onChange={(e, v) => { setRiskLevel(Number(v)); }}
                marks={marks}
                aria-labelledby="risklevel"
              />
            </div> */}
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: '1' }}>
                <FormControl style={{ width: '100%', marginLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
                  <InputLabel style={{ width: '100%', paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }} id="account-type-label">Account Type</InputLabel>
                  <Select
                    labelId="account-type-label"
                    id="account-type-select"
                    variant='filled'
                    margin='dense'
                    value={investmentGoal.accountType}
                    onChange={(event) => { investmentGoal.setAccountType(event.target.value) }}>
                    <MenuItem value={'SweIsk'}>ISK (Sweden)</MenuItem>
                    <MenuItem value={'SweEndowmentInsurance'}>Endowment insurance (Sweden)</MenuItem>
                    <MenuItem value={'GbrIsa'}>Isa (UK)</MenuItem>
                    <MenuItem value={'GbrSipp'}>Sipp (UK)</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <IconButton disabled={investmentGoal.accountType !== 'GbrIsa'} onClick={handleClickOpen}><Settings /></IconButton>
                <ContributionStrategyDialog title='ISA Contribution Strategy' values={isaContributionStrategies} setSelectedValue={investmentGoal.setIsaContributionStrategy} selectedValue={investmentGoal.isaContributionStrategy} open={isaContributionStrategiesOpen} onClose={handleClose} />
              </div>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <List component="nav" aria-label="all-customers" style={{ overflow: 'auto', maxHeight: 250 }}>
            {allCustomers.map(c =>
              <ListItem key={c.contractNumber} button selected={c.name === customerData.name} onClick={(event) => setCustomerData(c)}>
                <ListItemText primary={c.name + ', ' + c.dateOfBirth}  />
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </Grid>
  </div>;





  const chartLabel = <Paper>{'Time frame ' + horizonInYears + 'yrs, target ' + targetAmount}</Paper>;

  // const currentExpectedShortfall = resultState.engageComparisonResponseModel.currentExpectedShortfall[Math.min(resultState.engageComparisonResponseModel.currentExpectedShortfall.length - 1, horizonInYears)];
  // const targetExpectedShortfall = resultState.engageComparisonResponseModel.targetExpectedShortfall[Math.min(resultState.engageComparisonResponseModel.targetExpectedShortfall.length - 1, horizonInYears)];


  const currentExpectedShortfall = resultState.currentExpectedShortfallAtHorizon;
  const targetExpectedShortfall = resultState.targetExpectedShortfallAtHorizon;


  const shortfallPercentage = Math.round(((currentExpectedShortfall.expectedShortfall / targetExpectedShortfall.expectedShortfall) - 1) * 100);
  const shortfallMarkup = <div style={{ marginTop: theme.spacing(1) }}>
    <TrackingItem iconType={shortfallPercentage > 10 ? 'error' : shortfallPercentage < -10 ? 'notify' : 'ok'} bigText={Math.abs(shortfallPercentage) + '%'} smallText={shortfallPercentage > 0 ? ' higher risk' : ' lower risk'} />
    <div>
      <ExpectedShortfallChart
        investedCapital={currentExpectedShortfall.investedCapital}
        currentTailDistributionSamples={currentExpectedShortfall.tailDistributionSamples}
        currentRestOfDistributionSamples={currentExpectedShortfall.restOfDistributionSamples}
        targetTailDistributionSamples={targetExpectedShortfall.tailDistributionSamples}
        targetRestOfDistributionSamples={targetExpectedShortfall.restOfDistributionSamples}
      /></div>
  </div>
  const yearsSinceReallocation = moment().diff(moment(customerData.lastReallocationDate), 'year');
  const historicalReturn = (customerData.portfolioValue / customerData.totalPremiumPaid - 1) * 100;

  const expectedReturnPerYear = ((Math.pow((((resultState.outcomesAtHorizon.median - (currentExpectedShortfall.investedCapital - customerData.totalPremiumPaid)) / customerData.totalPremiumPaid)), 1 / horizonInYears)) - 1) * 100; // TODO do this right

  const results = <div style={{ minHeight: '70vh' }}>
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions === 0} className={classes.stepperContent}> */}
    {/* <Collapse in={debouncedInitialContribution + debouncedMonthlyContributions === 0} >
      <Grid container direction="row" justify="center" alignItems="baseline">
        <Grid item style={{ width: '100%', height: '100%', minHeight: theme.spacing(7), textAlign: 'center' }}>
          <Typography style={{ paddingTop: theme.spacing(2) }} variant='body1'>Enter an initial or a monthly contribution to get started!</Typography>
        </Grid>
      </Grid>
    </Collapse> */}
    {/* </Fade> */}
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions > 0} timeout={2000} className={classes.stepperContent}> */}
    <Collapse in={true}>
      <Grid container direction="column" alignItems='stretch' justify='center'>
        <Grid item>
          <LinearProgress style={{ width: "100%", visibility: numberOfRequestInFlight > 0 ? "visible" : "hidden" }} />
        </Grid>
      </Grid>
      <Grid container className={classes.resultContainer} direction="column" alignItems='stretch' justify='center'>
        <Grid item>
          <Grid container direction="row" spacing={2} alignItems="baseline">
            <Grid xs={12} sm={12} item style={{ paddingTop: theme.spacing(3), width: '100%', height: '100%', textAlign: 'left' }}>
              <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                <div style={{ display: 'flex' }}>
                  <ReadOnlyField text={customerData.name} label='Name' id='namefield' />
                  <ReadOnlyField text={customerData.dateOfBirth} label='Date of Birth' id='dateofbirthfield' />
                </div>
                <div style={{ display: 'flex' }}>
                  <ReadOnlyField text={customerData.insuranceNumber} label='Insurance Number' id='insuranceNofield' />
                  <ReadOnlyField text={customerData.contractNumber} label='Contract Number' id='ContractNumberfield' />
                </div>
                <div style={{ display: 'flex', marginBottom: theme.spacing(3) }}>
                  <ReadOnlyField text={customerData.portfolioValue} isNumber={true} label='Portfolio Value' id='PortfolioValuefield' />
                  <ReadOnlyField text={customerData.totalPremiumPaid} isNumber={true} label='Total Premium Paid' id='TotalPremiumPaidfield' />
                </div>
                <TrackingItem style={{ marginTop: theme.spacing(1) }} iconType={horizonInYears < 4 ? 'notify' : 'ok'} bigText={horizonInYears} smallText='years until retirement' />
                <TrackingItem style={{ marginTop: theme.spacing(1) }} iconType={yearsSinceReallocation > 3 ? 'notify' : 'ok'} bigText={yearsSinceReallocation} smallText='years since reallocation' />
              </div>
            </Grid>
            <Grid xs={12} sm={6} item style={{ paddingTop: theme.spacing(1), width: '100%', height: '100%', textAlign: 'left' }}>
              <Paper variant="outlined" style={{ paddingTop: theme.spacing(1) }} square>
                <Typography style={{ paddingLeft: theme.spacing(2) }} color='textPrimary' display="inline">Return Analysis</Typography>
                <Fade in={currentPortfolioDevelopmentTimeSeries.length > 1}>
                  <div>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
                    <div style={{ paddingBottom: theme.spacing(1), paddingLeft: theme.spacing(2) }}>
                      <TrackingItem style={{ marginTop: theme.spacing(1) }} iconType={historicalReturn < 0 ? 'waring' : historicalReturn < 10 ? 'notify' : 'ok'} bigText={(<><TextNumberFormat hidePrefix disableRounding decimals={1} value={historicalReturn} />%</>)} smallText='historical return' />
                      <TrackingItem style={{ marginTop: theme.spacing(1) }} iconType={expectedReturnPerYear < 2 ? 'notify' : 'ok'} bigText={(<><TextNumberFormat hidePrefix disableRounding decimals={1} value={expectedReturnPerYear} />%</>)} smallText='expected return per year' />
                      <Typography style={{ marginTop: theme.spacing(1) }} color='textSecondary' variant='body2'>Value Over Time ({settings.currency})</Typography>
                      <InvestmentGoalFanChart targetLabel={chartLabel} targetAmount={targetAmount} horizon={horizonInYears} currentAge={0} portfolioDevelopmentTimeSeries={currentPortfolioDevelopmentTimeSeries} referencePortfolioDevelopmentTimeSeries={targetPortfolioDevelopmentTimeSeries} />
                      <ChartLegend />
                    </div>
                  </div>
                </Fade>
              </Paper>
            </Grid>
            <Grid xs={12} sm={6} item style={{ width: '100%', height: '100%', textAlign: 'left' }}>
              <Paper variant="outlined" style={{ paddingTop: theme.spacing(1) }} square>
                <Typography style={{ paddingLeft: theme.spacing(2) }} color='textPrimary' display="inline">Risk Analysis</Typography>
                <Fade in={currentPortfolioDevelopmentTimeSeries.length > 1}>
                  <div>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
                    <div style={{ paddingBottom: theme.spacing(1), paddingLeft: theme.spacing(2) }}>
                      <Typography color='textSecondary' variant='body2'>Diversification</Typography>
                      <TrackingItem style={{ marginTop: theme.spacing(1) }} iconType={resultState.currentDiversificationOutcomeAtHorizon < 0.25 ? 'warning' : resultState.currentDiversificationOutcomeAtHorizon < 0.75 ? 'notify' : 'ok'} bigText={(<><TextNumberFormat hidePrefix decimals={0} disableRounding value={resultState.currentDiversificationOutcomeAtHorizon * 100} />%</>)} smallText='current portfolio diversification' />
                      <TrackingItem style={{ marginTop: theme.spacing(1) }} iconType={resultState.targetDiversificationOutcomeAtHorizon < 0.25 ? 'warning' : resultState.targetDiversificationOutcomeAtHorizon < 0.75 ? 'notify' : 'ok'} bigText={(<><TextNumberFormat hidePrefix decimals={0} disableRounding value={resultState.targetDiversificationOutcomeAtHorizon * 100} />%</>)} smallText='reference portfolio diversification' />
                      {/* <InvestmentGoalFanChart targetLabel={chartLabel} targetAmount={targetAmount} horizon={horizonInYears} currentAge={0} portfolioDevelopmentTimeSeries={currentPortfolioDevelopmentTimeSeries} referencePortfolioDevelopmentTimeSeries={targetPortfolioDevelopmentTimeSeries} /> */}
                      <PortfolioComparisonBarChart currentAllocations={customerData.currentPortfolio} referenceAllocations={customerData.referencePortfolio} />
                      <Typography color='textSecondary' variant='body2'>Expected Shortfall</Typography>
                      {shortfallMarkup}
                      <ChartLegend />
                      {/* <Typography style={{ paddingLeft: theme.spacing(2) }} color='textSecondary' variant='body2'>Budget Breakdown</Typography>
                    <SpendingSummary /> */}
                    </div>
                  </div>
                </Fade>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </Collapse>
    {/* </Fade> */}
  </div >;


  return (
    <div>
      <AdvisorViewShell title='Goal Tracking' advisorView={formInputs} customerView={results} />
    </div>
  );
}

export default GoalTracking;

const handleResult = (result, setStateFunc, setErrorMessage) => {
  if (result instanceof Response) {
    if (result !== undefined && result.errors !== undefined)
      setErrorMessage(result.errors);
    else
      result.text().then(text => setErrorMessage(text));
    return;
  }
  else {
    setStateFunc(result);
    // setErrorMessage(undefined);
  }
}

const RequiredInput = (props) => {
  const theme = useTheme();
  const classes = makeStyles(styleDelegate)();

  const { requiredEnabled, title, requiredContribution, contribution, setContribution, maxValue } = props;
  return (<div>
    <div style={{ float: 'left', width: '55%' }}>
      <SliderInput value={contribution} showCurrency onChange={setContribution} minValue={0} maxValue={maxValue} title={title} />
    </div>
    <div style={{ float: 'right', width: '40%' }}>
      <Typography style={{ textAlign: 'left' }} className={classes.smallText} variant='body2' color='textSecondary'>Required Amount</Typography>
      <Button onClick={() => { setContribution(requiredContribution); }} size='small' style={{ minWidth: theme.spacing(14), textAlign: 'left', justifyContent: 'left' }} variant={requiredEnabled ? "contained" : "outlined"} color='primary' disabled={!requiredEnabled}>
        <ArrowBack fontSize="small" style={{ visibility: requiredEnabled ? 'visible' : 'hidden', marginRight: theme.spacing(1), marginBottom: 1 }} />
        {requiredEnabled ? <TextNumberFormat roundToNearest={1} value={requiredContribution} /> : ''}

      </Button>
    </div>
  </div>);
};

function SliderInput(props) {
  const theme = useTheme();
  const { value, onChange, minValue, maxValue, title, showCurrency } = props;
  return (<>
    <TextField InputProps={{ inputComponent: showCurrency ? CurrencyFormatCustom : NumberFormatCustom }} fullWidth={true} required label={title} variant='filled' size='small' onChange={(e) => { onChange(Number(e.target.value)); }} value={value} />
    <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
      <Slider
        min={minValue}
        max={maxValue}
        value={value}
        onChange={(e, v) => { onChange(Number(v)); }} />
    </div>
  </>);
}

function ContributionStrategyDialog(props) {
  const theme = useTheme();


  const { onClose, setSelectedValue, selectedValue, open, values, title } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog style={{ padding: theme.spacing(2) }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <RadioGroup style={{ padding: theme.spacing(2) }} aria-label="gender" name="gender1" value={selectedValue} onChange={(event) => setSelectedValue(event.target.value)}>
        {values.map((value) => (
          <FormControlLabel key={value.value} value={value.value} control={<Radio />} label={value.text} />
        ))}
      </RadioGroup>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );


}

function ChartLegend() {

  const theme = useTheme();
  const colors = useAppColors();
  return (<div style={{
    textAlign: 'center'
  }}>
    <svg width="250" viewBox="0 0 250 20" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="pattern-stripe-legend" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
          <rect width="4" height="8" transform="translate(0,0)" fill={theme.palette.text.secondary}></rect>
        </pattern>
      </defs>
      <rect fill={colors.fanChart.medium} style={{
        strokeWidth: 0.5,
        stroke: theme.palette.text.secondary
      }} width="15" height="15" />
      <text style={{
        fontSize: 12
      }} fill={theme.palette.text.secondary} x="20" y="12">Current portfolio</text>
      <rect fill="url(#pattern-stripe-legend)" style={{
        strokeWidth: 0.5,
        stroke: theme.palette.text.secondary
      }} x="120" width="15" height="15" />
      <text style={{
        fontSize: 12
      }} fill={theme.palette.text.secondary} x="140" y="12">Reference portfolio</text>
    </svg>
  </div>);


}

function TrackingItem({ iconType, bigText, smallText, style }) {
  const theme = useTheme();
  const colors = useAppColors();

  const icon = iconType === 'ok' ? <CheckCircleTwoTone style={{
    fill: colors.status.ok,
    display: "inline-block",
    marginBottom: "-4px",
    marginRight: theme.spacing(1)
  }} fontSize='inherit' /> : iconType === 'notify' ? <NotificationImportantTwoTone style={{
    fill: colors.status.notify,
    display: "inline-block",
    marginBottom: "-4px",
    marginRight: theme.spacing(1)
  }} fontSize='inherit' /> :
      <WarningTwoTone color='error' style={{
        display: "inline-block",
        marginBottom: "-4px",
        marginRight: theme.spacing(1)
      }} fontSize='inherit' />;

  return (<div style={style}>
    <Typography variant='h4' display="inline" style={{
      marginRight: 2
    }}>
      {icon}
      {bigText}

    </Typography>
    <Typography variant='body2' display="inline">{' ' + smallText}</Typography>
  </div>);


}

function ReadOnlyField({ text, id, label, isNumber }) {
  const theme = useTheme();

  return (
    <div style={{ minWidth: theme.spacing(20), maxWidth: theme.spacing(26), width: '100%' }}>
      <Typography color='textSecondary' display="inline" style={{ fontSize: 12, width: theme.spacing(24) }}>
        {label}
      </Typography>
      <br />
      <Typography variant='body1' display="inline">
        {isNumber === true ? <TextNumberFormat roundToNearest={1} value={text} /> : text}
      </Typography>
    </div>
  );
}