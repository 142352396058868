import { Checkbox, Slider, Typography, useTheme, FormControlLabel, Switch } from "@material-ui/core"
import React, { useState, useEffect, useContext, useRef } from 'react';
import { AssessmentOutlined, Launch, ArrowBack, Settings, Work, School } from '@material-ui/icons';
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import SliderInput from './../SliderInput';



export default function SicknessPension(props) {

    const theme = useTheme();

    const { sicknessPension, setSicknessPension } = props;

    return (<div>
        <SliderInput value={sicknessPension.monthlySpending} showCurrency onChange={(newValue) => setSicknessPension(state => ({ ...state, monthlySpending: newValue }))} minValue={0} maxValue={25000} title={'Monthly Spending'} />
        <SliderInput value={sicknessPension.housingInvestment} showCurrency onChange={(newValue) => setSicknessPension(state => ({ ...state, housingInvestment: newValue }))} minValue={0} maxValue={2500000} title={'Housing Investment'} />
        <SliderInput value={sicknessPension.bufferAmount} showCurrency onChange={(newValue) => setSicknessPension(state => ({ ...state, bufferAmount: newValue }))} minValue={0} maxValue={500000} title={'Buffer Amount'} />
        <FormControlLabel
            control={<Switch color="primary" checked={sicknessPension.includeValueOfRealEstate} onChange={event => { setSicknessPension(state => ({ ...state, includeValueOfRealEstate: event.target.checked })) }} value={sicknessPension.includeValueOfRealEstate} />}
              label={<Typography variant='body2'>Include value of home</Typography>}
            />
        {/* <Typography id="schoolabsenceLabel" >
            Percentage Equities (Risk)
            </Typography>
        <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            <Slider
                // style={{ marginTop: theme.spacing(3) }}
                value={retirement.riskRatio}
                onChange={(event, newValue) => { setSicknessPension(state => ({ ...state, riskRatio: newValue })) }}
                // valueLabelDisplay="auto"
                aria-labelledby="schoolabsenceLabel"
                // ValueLabelComponent={ValueLabelComponent}
                step={0.05}
                max={1}
                min={0}
                marks={rateOfAbsenceMarks}
            />
        </div> */}
    </div>)
};


