import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, ReferenceLine, Cell, YAxis, Tooltip } from 'recharts';
import { useAppColors } from './useAppColors';
import { TextNumberFormat } from './NumberFormatCustom'
import { PanToolOutlined } from '@material-ui/icons';
import { interpolateRgb } from 'd3-interpolate';


function StressedPortfolioBarChart(props) {

    const colors = useAppColors();

    const { currentValue, stressedValue } = props;
    const chartData = [
        { name: 'Current value', value: currentValue },
        { name: 'Stressed Value', value: stressedValue },
    ];

    const maxValue = 1; //Math.ceil((goodOutcome > targetAmount ? goodOutcome : targetAmount) / 1000) * 1000;


    const stressedColor = interpolateRgb(colors.outcomes.bad, colors.outcomes.good)(Math.pow(stressedValue, 5));

    return (<div style={{ height: '10rem' }}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{
                top: 20, right: 20, left: 18, bottom: 2,
            }}>
                <defs>
                    <linearGradient id="colorStress" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={stressedColor} stopOpacity={1} />
                        <stop offset="95%" stopColor={stressedColor} stopOpacity={0.75} />
                    </linearGradient>
                    <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.outcomes.good} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.outcomes.good} stopOpacity={0.75} />
                    </linearGradient>
                </defs>
                {/* <Tooltip isAnimationActive={false} content={<CustomizedTooltip />}></Tooltip> */}
                <XAxis tickLine={false} dataKey="name" />
                {/* <YAxis width={30} type="number" tick={<CustomizedAxisTick />} domain={[0, maxValue]} /> */}
                <YAxis width={0} domain={[0, maxValue]} />
                <Bar barSize={60} dataKey="value" stackId="a" strokeOpacity={0.3} stroke="#333333" label={<CustomizedLabel />}>
                    {renderCell(chartData, "url(#colorCurrent)", "url(#colorStress)")}
                </Bar>

                {/* <ReferenceLine stroke={colors.fanChart.referenceLine} strokeDasharray="3 3" y={1} /> */}
            </BarChart>
        </ResponsiveContainer>
    </div>);
}

function CustomizedLabel(props) {
    const { x, y, fill, value } = props;
    return (<text
        x={x}
        y={y}
        dy={-4}
        dx={33}
        fontSize='16'
        fill={'#666'}
        textAnchor="middle">{Math.round(value * 100)}%</text>);

};

const renderCell = (data, colorCurrent, colorStress) => {
    return data.map((coordinate, i) => {
        const { value } = coordinate
        return (
            <Cell
                key={i}
                fill={
                    value >= 1 ? colorCurrent : colorStress
                }
            />
        )
    })
}


function CustomizedAxisTick(props) {
    const {
        x, y, stroke, payload, coordinate,
    } = props;


    return (
        <>
            <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: 10 }}
                renderText={value =>
                    <svg>
                        <g transform={`translate(${x},${y})`}>
                            <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: 10 }} fill="#666" >{value}</text>
                        </g>
                    </svg>
                }
            />

        </>
    );
}

function CustomizedTooltip(props) {
    const {
        payload, coordinate,
    } = props;

    const value = payload.length > 0 ? payload[0].value : 0;

    return (
        <>
            <TextNumberFormat roundToNearest={1} value={value}
                renderText={value =>
                    <svg>
                        <g transform={`translate(${coordinate.x},${coordinate.y})`}>
                            <text x={0} y={0} textAnchor="end" fill="#666" >{value}</text>
                        </g>
                    </svg>
                }
            />

        </>
    );
}


function areEqual(prevProps, nextProps) {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

export default React.memo(StressedPortfolioBarChart, areEqual);

