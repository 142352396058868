import React, { useContext } from 'react';
import { Paper, Grid, FormControl, Typography, IconButton, Toolbar, AppBar, Divider } from '@material-ui/core';
import { styleDelegate } from './useStyles';
import CustomerAssets from './CustomerAssets';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import RiskProfile from './RiskProfile';
import BasicCustomerInformation from './BasicCustomerInformation';
import IncomeSheet from './IncomeSheet';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { SettingsContext } from './SettingsContext';


function CustomerProfile() {
    const classes = makeStyles(styleDelegate)();
    const settings = useContext(SettingsContext);
    const theme = useTheme();

    return (
        <div className={classes.backgroundContainer}>
            <AppBar style={{background: settings.isLightTheme ? theme.palette.primary.main : theme.palette.grey[900]}} className={classes.appBar} position="static">
                <Toolbar>
                    <Grid className={classes.appTitle} direction="row" alignItems="center" container>
                        <Grid item><IconButton color='inherit' style={{ marginRight: '1rem' }} onClick={() => settings.gotoPreviousToFullScreenComponent()}><ArrowBack variant='body2' /></IconButton></Grid>
                        <Grid item><Typography variant="body1">Customer Profile</Typography></Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.paperContainer}>
                {/* These are used to counter faulty negative margin calc:  overflowX: 'hidden', overflow: 'hidden' */}
                <FormControl margin="dense" component="fieldset" style={{ width: '100%', textAlign: 'left', overflowX: 'hidden', overflow: 'hidden' }} autoComplete="off">
                    <Grid spacing={1} container>
                        <Grid item xs={12} sm={12}>
                            <Paper square className={classes.card} >
                                <Typography className={classes.title} variant='body2'>User Information</Typography>
                                <Divider></Divider>
                                <Grid spacing={1} container>
                                    <Grid item xs={12} sm={6}>
                                        <BasicCustomerInformation />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.title} variant='body2'>Remaining Funds Calculation</Typography>
                                        <IncomeSheet />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Paper square className={classes.card} >
                                <Typography className={classes.title} variant='body2'>Assets</Typography>
                                <Divider></Divider>
                                <CustomerAssets />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Paper square className={classes.card}>
                                <Typography className={classes.title} variant='body2'>Risk Profile</Typography>
                                <Divider></Divider>
                                <RiskProfile />
                            </Paper>
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
        </div >
    );
}
export default CustomerProfile;
