import React from 'react';
import { AreaChart, ReferenceLine, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';
import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from './appConstants';
import { useAppColors } from './useAppColors';
import { TextNumberFormat } from './NumberFormatCustom'


function InvestmentGoalFanChart(props: any) {
    const { currentAge, portfolioDevelopmentTimeSeries, referencePortfolioDevelopmentTimeSeries, targetAmount, horizon, height } = props;

    const historicalTimeSeries: any = undefined;
    //  [
    //     {pointInTime: "2012-01-01T00:00:00Z", value: 1985585},
    //     {pointInTime: "2013-01-01T00:00:00Z", value: 2215585},
    //     {pointInTime: "2014-01-01T00:00:00Z", value: 2105585},
    //     {pointInTime: "2015-01-01T00:00:00Z", value: 2365585},
    //     {pointInTime: "2016-01-01T00:00:00Z", value: 2655585},
    //     {pointInTime: "2017-01-01T00:00:00Z", value: 2805585},
    //     {pointInTime: "2018-01-01T00:00:00Z", value: 3005585},
    //     {pointInTime: "2019-01-01T00:00:00Z", value: 3405585},
    //     {pointInTime: "2020-01-01T00:00:00Z", value: 3205585},
    //     {pointInTime: "2021-01-01T00:00:00Z", value: 3445740},
    // ];

    const colors = useAppColors();

    const narrowOutcomeLowerBound = portfolioDevelopmentTimeSeries.find((s: any) => s.percentile === narrowOutcomePercentiles[0]);
    const narrowOutcomeUpperBound = portfolioDevelopmentTimeSeries.find((s: any) => s.percentile === narrowOutcomePercentiles[1]);
    const mediumOutcomeLowerBound = portfolioDevelopmentTimeSeries.find((s: any) => s.percentile === mediumOutcomePercentiles[0]);
    const mediumOutcomeUpperBound = portfolioDevelopmentTimeSeries.find((s: any) => s.percentile === mediumOutcomePercentiles[1]);
    const wideOutcomeLowerBound = portfolioDevelopmentTimeSeries.find((s: any) => s.percentile === wideOutcomePercentiles[0]);
    const wideOutcomeUpperBound = portfolioDevelopmentTimeSeries.find((s: any) => s.percentile === wideOutcomePercentiles[1]);
    let referenceOutcomeLowerBound: any = undefined;
    let referenceOutcomeUpperBound: any = undefined;
    if (referencePortfolioDevelopmentTimeSeries !== undefined) {
        referenceOutcomeLowerBound = referencePortfolioDevelopmentTimeSeries.find((s: any) => s.percentile === wideOutcomePercentiles[0]);
        referenceOutcomeUpperBound = referencePortfolioDevelopmentTimeSeries.find((s: any) => s.percentile === wideOutcomePercentiles[1]);
    }
    const xAxisLength = (Math.ceil(horizon / 10) * 10) + 1;

    const lastOutcomeIndex = xAxisLength > wideOutcomeUpperBound.outcomes.length ? wideOutcomeUpperBound.outcomes.length - 1 : xAxisLength - 1;

    const lastOutComeHighestOutcome = wideOutcomeUpperBound.outcomes[lastOutcomeIndex].value;
    const highestValue = lastOutComeHighestOutcome > targetAmount ? lastOutComeHighestOutcome : targetAmount;


    const maxAsSingleDigit = highestValue / Math.pow(10, Math.floor(Math.log10(highestValue)));
    const maxValue = (maxAsSingleDigit < 5 ? Math.ceil(maxAsSingleDigit) : 10) * Math.pow(10, Math.floor(Math.log10(highestValue)));

    console.log(portfolioDevelopmentTimeSeries);

    if (portfolioDevelopmentTimeSeries[0].outcomes.length === 1) return (<></>);

    const historicalChartData = historicalTimeSeries !== undefined ? historicalTimeSeries.map((o: any, i: number) => {
        return {
            name: '' + (currentAge + i - historicalTimeSeries.length),
            narrow: undefined,
            medium: undefined,
            wide: undefined,
            historical: [historicalTimeSeries[i].value - 50000, historicalTimeSeries[i].value + 50000],
            reference: undefined,
        };
    }) : [];

    const chartData = historicalChartData.concat(narrowOutcomeLowerBound.outcomes.slice(0, xAxisLength).map((o: any, i: number) => {
        return {
            name: '' + (currentAge + i),
            narrow: [narrowOutcomeLowerBound.outcomes[i].value, narrowOutcomeUpperBound.outcomes[i].value],
            medium: [mediumOutcomeLowerBound.outcomes[i].value, mediumOutcomeUpperBound.outcomes[i].value],
            wide: [wideOutcomeLowerBound.outcomes[i].value, wideOutcomeUpperBound.outcomes[i].value],
            historical: i === 0 && historicalTimeSeries !== undefined ? [historicalTimeSeries[historicalTimeSeries.length - 1].value - 50000, historicalTimeSeries[historicalTimeSeries.length - 1].value + 50000] : undefined,
            reference: referencePortfolioDevelopmentTimeSeries !== undefined ? [referenceOutcomeLowerBound.outcomes[i].value, referenceOutcomeUpperBound.outcomes[i].value] : undefined,
        };
    }));

    return (<div style={{ height: height === undefined ? '10rem' : height }}>
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData} margin={{
                top: 20, right: 20, left: 23, bottom: 2,
            }}>
                <defs>
                    <pattern id="pattern-stripe-fan"
                        width="8" height="8"
                        patternUnits="userSpaceOnUse"
                        patternTransform="rotate(45)">
                        <rect width="4" height="8" transform="translate(0,0)" fill={colors.fanChart.referenceLine}></rect>
                    </pattern>
                    <linearGradient id="fanChartWide" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.fanChart.wide} stopOpacity={0.3} />
                        <stop offset="95%" stopColor={colors.fanChart.wide} stopOpacity={0.3} />
                    </linearGradient>
                    <linearGradient id="fanChartMedium" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.fanChart.medium} stopOpacity={0.5} />
                        <stop offset="95%" stopColor={colors.fanChart.medium} stopOpacity={0.5} />
                    </linearGradient>
                    <linearGradient id="fanChartNarrow" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.fanChart.narrow} stopOpacity={0.5} />
                        <stop offset="95%" stopColor={colors.fanChart.narrow} stopOpacity={0.5} />
                    </linearGradient>
                </defs>
                {/* <XAxis fontFamily={'Roboto, sans-serif'} type={'number'} tickCount={3}  tick={{ fontSize: 10 }} interval={'preserveStartEnd'} dataKey="name" /> */}
                <CartesianGrid strokeOpacity={0.3} vertical={false} />
                <XAxis fontFamily={'Roboto, sans-serif'} tick={{ fontSize: 10 }} ticks={[-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90]} interval={'preserveStartEnd'} dataKey="name" />
                <YAxis width={35} type="number" domain={[0, maxValue]} tick={<CustomizedAxisTick />} />
                {referencePortfolioDevelopmentTimeSeries !== undefined ? <Area animationDuration={500} type="monotone" dataKey="reference" strokeOpacity={0.2} stroke={colors.fanChart.referenceLine} fillOpacity={0.05} fill={"url(#pattern-stripe-fan)"} /> : <></>}
                {historicalTimeSeries !== undefined ? <Area animationDuration={500} type="monotone" dataKey="historical" strokeOpacity={0.5} stroke="#333333" fillOpacity={1} fill={"url(#fanChartNarrow)"} /> : <></>}
                <Area animationDuration={500} type="monotone" dataKey="wide" strokeOpacity={1} strokeWidth={2} stroke={colors.fanChart.wide} fillOpacity={1} fill={"url(#fanChartWide)"} />
                <Area animationDuration={500} type="monotone" dataKey="medium" strokeOpacity={1} strokeWidth={2} stroke={colors.fanChart.medium} fillOpacity={1} fill={"url(#fanChartMedium)"} />
                <Area animationDuration={500} type="monotone" dataKey="narrow" strokeOpacity={1} strokeWidth={2} stroke={colors.fanChart.narrow} fillOpacity={1} fill={"url(#fanChartNarrow)"} />
                <ReferenceLine stroke={colors.fanChart.referenceLine} strokeDasharray="3 3" x={horizon} />
                <ReferenceLine stroke={colors.fanChart.referenceLine} strokeDasharray="3 3" y={targetAmount} />
            </AreaChart>
        </ResponsiveContainer>
    </div>);
}

function CustomizedAxisTick(props: any) {
    const {
        x, y, payload,
    } = props;

    return (
        <>
            <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: 10 }}
                renderText={(value: any) =>
                    <g transform={`translate(${x},${y})`}>
                        <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: 10 }} fill="#666" >{value}</text>
                    </g>
                }
            />

        </>
    );
}

export function areEqual(prevProps : any, nextProps : any) {
    var prevProps2 = Object.assign({}, prevProps);
    delete prevProps2.targetLabel;

    var nextProps2 = Object.assign({}, nextProps);
    delete nextProps2.targetLabel;

    if (JSON.stringify(prevProps2) === JSON.stringify(nextProps2)) {

        return true;
    }
    return false;
}

export default React.memo(InvestmentGoalFanChart, areEqual);
