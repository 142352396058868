import React, { useState, useEffect, useContext, useRef } from 'react';
import { getEstimations, getAdvice } from './api_client/forecastClient';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Collapse, Paper, Slider, Typography, DialogTitle, Dialog, FormControl, Grid, TextField, IconButton, InputLabel, Select, MenuItem, Fade, Button, Divider, Tooltip, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions } from '@material-ui/core';
import { Element, scroller } from 'react-scroll'
import { AssessmentOutlined, Launch, ArrowBack, Settings } from '@material-ui/icons';
import { TextNumberFormat, NumberFormatCustom, CurrencyFormatCustom } from './NumberFormatCustom'
import moment from "moment";
import { styleDelegate } from './useStyles';
import InvestmentOutcomeBarChart from './InvestmentOutcomeBarChart';
import PensionFanChart from './PensionFanChart';
import InvestmentGoalFanChart from './InvestmentGoalFanChart';
import { InvestmentGoalContext } from './InvestmentGoalContext'
import { PensionResultTable } from './PensionResultTable';
import { SettingsContext } from './SettingsContext';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { useDebounce } from './useDebounce';
import { GoalAppShell } from './GoalAppShell';
import SpendingSummary from './SpendingSummary';
import { investmentGoalPlanning, getDefaultInvestmentGoalPlanningResult, namedFunds } from './api_client/tsClient';
import SliderInput from './SliderInput';


moment.locale("se");

function InvestmentSavingsGoal() {

  const classes = makeStyles(styleDelegate)();
  const theme = useTheme();

  const settings = useContext(SettingsContext);
  const investmentGoal = useContext(InvestmentGoalContext);
  const basicCustomerInformation = useContext(BasicCustomerInformationContext);


  const [resultState, setResultState] = useState(getDefaultInvestmentGoalPlanningResult());

  const portfolioDevelopmentTimeSeries = resultState.financialPlanningResponseModel.percentileForecast;

  const [numberOfRequestInFlight, setNumberOfRequestInFlight] = useState(0);

  const { setErrorMessage, currentScenarioSet } = settings;

  const [horizonInYears, setHorizonInYears] = useState(8);
  const [targetAmount, setTargetAmount] = useState(0);
  const [initialContribution, setInitialContribution] = useState(0);
  const [riskLevel, setRiskLevel] = useState(3);

  const debouncedHorizonInYears = useDebounce(horizonInYears, 800);
  const debouncedTargetAmount = useDebounce(targetAmount, 800);
  const debouncedInitialContribution = useDebounce(initialContribution, 800);
  const debouncedMonthlyContributions = useDebounce(investmentGoal.monthlyInvestmentAmount, 800);
  const debouncedRiskLevel = useDebounce(riskLevel, 800);


  useEffect(() => {
    if(currentScenarioSet.id === '') return;
    // if (prevGrossSalary !== debouncedBasicCustomerInformation.grossSalary || prevDateOfBirth !== debouncedBasicCustomerInformation.dateOfBirth) {
    setNumberOfRequestInFlight(state => state + 1);
    investmentGoalPlanning(currentScenarioSet.id,
      debouncedHorizonInYears,
      debouncedTargetAmount,
      debouncedInitialContribution,
      debouncedMonthlyContributions,
      debouncedRiskLevel,
      investmentGoal.accountType,
      investmentGoal.isaContributionStrategy,
      basicCustomerInformation.grossSalary)
      .then(result => {
        try {
          handleResult(result, setResultState, setErrorMessage);
        } finally {
          setNumberOfRequestInFlight(state => state - 1);
        }
      });
    // }
  }, [
    debouncedHorizonInYears,
    debouncedTargetAmount,
    debouncedInitialContribution,
    debouncedMonthlyContributions,
    debouncedRiskLevel,
    investmentGoal.accountType,
    investmentGoal.isaContributionStrategy,
    basicCustomerInformation.grossSalary,
    setErrorMessage,
    currentScenarioSet
  ]);


  const marks = [
    {
      value: 0,
      label: 'No risk',
    },
    {
      value: 1,
      label: 'Low',
    },
    {
      value: 2,
      label: 'Low-medium',
    },
    {
      value: 3,
      label: 'Medium',
    },
    {
      value: 4,
      label: 'Medium-high',
    },
    {
      value: 5,
      label: 'High',
    },
  ];

  const requiredEnabled = resultState.requiredInitialContribution > initialContribution;

  const [isaContributionStrategiesOpen, setIsaContributionStrategiesOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsaContributionStrategiesOpen(true);
  };

  const handleClose = () => {
    setIsaContributionStrategiesOpen(false);
  };

  const isaContributionStrategies = [
    { value: 'OnlyBondsAndSharesIsa', text: 'Only bonds and shares ISA' },
    { value: 'PreferLifetimeIsa', text: 'Prefer lifetime ISA' }
  ];

  const formInputs = <div>
    <FormControl style={{ width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
      <SliderInput value={horizonInYears} onChange={setHorizonInYears} minValue={1} maxValue={30} title={'Time Frame (years)'} />
      <SliderInput value={targetAmount} showCurrency onChange={setTargetAmount} minValue={0} maxValue={1000000} title={'Target Amount'} />

      <RequiredInput
        title={'Monthly Contribution'}
        requiredEnabled={requiredEnabled}
        requiredContribution={resultState.requiredMonthlyContribution}
        contribution={investmentGoal.monthlyInvestmentAmount}
        setContribution={investmentGoal.setMonthlyInvestmentAmount}
        maxValue={9999} />

      <RequiredInput
        title={'Initial Contribution'}
        requiredEnabled={requiredEnabled}
        requiredContribution={resultState.requiredInitialContribution}
        contribution={initialContribution}
        setContribution={setInitialContribution}
        maxValue={999999} />

      <Typography id="risklevel">
        Risk Level
        </Typography>
      <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
        <Slider
          classes={{ markLabel: classes.smallText }}
          min={0}
          max={5}
          value={riskLevel}
          onChange={(e, v) => { setRiskLevel(Number(v)); }}
          marks={marks}
          aria-labelledby="risklevel"
        />
      </div>
      {/* <Typography id="risklevel" style={{ paddingBottom: theme.spacing(1)}}> */}
      {/* {namedFunds['' + riskLevel].name}, {namedFunds['' + riskLevel].id} */}
        {/* </Typography> */}
    </FormControl>
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: '1' }}>
        <FormControl style={{ width: '100%', marginLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
          <InputLabel style={{ width: '100%', paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }} id="account-type-label">Account Type</InputLabel>
          <Select
            labelId="account-type-label"
            id="account-type-select"
            variant='filled'
            margin='dense'
            value={investmentGoal.accountType}
            onChange={(event) => { investmentGoal.setAccountType(event.target.value) }}>
            <MenuItem value={'SweIsk'}>ISK (Sweden)</MenuItem>
            <MenuItem value={'SweEndowmentInsurance'}>Endowment insurance (Sweden)</MenuItem>
            <MenuItem value={'GbrIsa'}>Isa (UK)</MenuItem>
            <MenuItem value={'GbrSipp'}>Sipp (UK)</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
        <IconButton disabled={investmentGoal.accountType !== 'GbrIsa'} onClick={handleClickOpen}><Settings /></IconButton>
        <ContributionStrategyDialog title='ISA Contribution Strategy' values={isaContributionStrategies} setSelectedValue={investmentGoal.setIsaContributionStrategy} selectedValue={investmentGoal.isaContributionStrategy} open={isaContributionStrategiesOpen} onClose={handleClose} />
      </div>
    </div>
  </div>;



  const chartLabel = <Paper>{'Time frame ' + horizonInYears + 'yrs, target ' + targetAmount}</Paper>;

  const results = <div style={{ position: 'relative', minHeight: '70vh' }}>
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions === 0} className={classes.stepperContent}> */}
    <Collapse in={debouncedInitialContribution + debouncedMonthlyContributions === 0} >
      <Grid container direction="row" justify="center" alignItems="baseline">
        <Grid item style={{ width: '100%', height: '100%', minHeight: theme.spacing(7), textAlign: 'center' }}>
          <Typography style={{ paddingTop: theme.spacing(2) }} variant='body1'>Enter an initial or a monthly contribution to get started!</Typography>
        </Grid>
      </Grid>
    </Collapse>
    {/* </Fade> */}
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions > 0} timeout={2000} className={classes.stepperContent}> */}
    <Collapse in={debouncedInitialContribution + debouncedMonthlyContributions > 0}>
      <Paper square>
        <Grid container direction="column" alignItems='stretch' justify='center'>
          <Grid item>
            <LinearProgress style={{ width: "100%", visibility: numberOfRequestInFlight > 0 ? "visible" : "hidden" }} />
          </Grid>
        </Grid>
        <Grid container className={classes.resultContainer} direction="column" alignItems='stretch' justify='center'>
          <Grid item>
            {/* <div style={{ textAlign: 'left', marginBottom: theme.spacing(1) }} className={classes.customTable}>
            <Grid container orientation="row">
              <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Investment</Typography></Grid>
              <Grid xs={7} item><Typography variant='body2'>fees etc...</Typography></Grid>
              <Grid xs={1} item style={{ textAlign: 'right' }}><Launch fontSize={'small'} /></Grid>
              <Grid xs={12} item><Divider></Divider></Grid>
            </Grid>
          </div> */}
            <Grid container direction="row"
              // justify="center"
              alignItems="baseline">
              <Grid item style={{ paddingTop: theme.spacing(1), width: '100%', height: '100%', textAlign: 'left' }}>
                <Typography style={{ paddingLeft: theme.spacing(2) }} color='textPrimary' display="inline">Plan Summary</Typography>
                <Fade in={portfolioDevelopmentTimeSeries.length > 1}>
                  <div>
                  <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
                    <div style={{ paddingBottom: theme.spacing(1), paddingLeft: theme.spacing(2) }}>
                      <Typography color='textSecondary' variant='body2'>Expected Return</Typography>
                      <InvestmentOutcomeBarChart badOutcome={resultState.outcomesAtHorizon.bad} targetAmount={targetAmount} medianOutcome={resultState.outcomesAtHorizon.median} goodOutcome={resultState.outcomesAtHorizon.good} />
                      <Typography color='textSecondary' variant='body2'>Value Over Time ({settings.currency}/year)</Typography>
                      <InvestmentGoalFanChart targetLabel={chartLabel} targetAmount={targetAmount} horizon={horizonInYears} currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} />
                      <Typography color='textSecondary' variant='body2'>Budget Breakdown</Typography>
                      <SpendingSummary />
                    </div>
                  </div>
                </Fade>
              </Grid>
              <Grid item style={{ width: '100%', height: '100%', maxWidth: 400, textAlign: 'center' }}>
                {/* <Typography color='textSecondary' variant='body2'>Pension Outcomes</Typography> */}
                {/* <PensionBarChart badOutcome={badOutcome} medianOutcome={medianOutcome} goodOutcome={goodOutcome} colors={colors.pensionPots} /> */}
              </Grid>
            </Grid>
            {/* <PensionResultTable colors={colors.pensionPots} classes={classes} badOutcome={badOutcome} medianOutcome={medianOutcome} goodOutcome={goodOutcome} /> */}
          </Grid>
        </Grid >
      </Paper >
    </Collapse>
    {/* </Fade> */}
  </div>;


  return (
    <div>
      {/* <PensionFanChart currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} /> */}
      <GoalAppShell title='Investment Goal' formView={formInputs} resultView={results} />
    </div>
  );
}

export default InvestmentSavingsGoal;

const handleResult = (result, setStateFunc, setErrorMessage) => {
  if (result instanceof Response) {
    if (result !== undefined && result.errors !== undefined)
      setErrorMessage(result.errors);
    else
      result.text().then(text => setErrorMessage(text));
    return;
  }
  else {
    setStateFunc(result);
    // setErrorMessage(undefined);
  }
}

const RequiredInput = (props) => {
  const theme = useTheme();
  const classes = makeStyles(styleDelegate)();

  const { requiredEnabled, title, requiredContribution, contribution, setContribution, maxValue } = props;
  return (<div>
    <div style={{ float: 'left', width: '55%' }}>
      <SliderInput value={contribution} showCurrency onChange={setContribution} minValue={0} maxValue={maxValue} title={title} />
    </div>
    <div style={{ float: 'right', width: '40%' }}>
      <Typography style={{ textAlign: 'left' }} className={classes.smallText} variant='body2' color='textSecondary'>Required Amount</Typography>
      <Button onClick={() => { setContribution(requiredContribution); }} size='small' style={{ minWidth: theme.spacing(14), textAlign: 'left', justifyContent: 'left' }} variant={requiredEnabled ? "contained" : "outlined"} color='primary' disabled={!requiredEnabled}>
        <ArrowBack fontSize="small" style={{ visibility: requiredEnabled ? 'visible' : 'hidden', marginRight: theme.spacing(1), marginBottom: 1 }} />
        {requiredEnabled ? <TextNumberFormat roundToNearest={1} value={requiredContribution} /> : ''}

      </Button>
    </div>
  </div>);
};

function ContributionStrategyDialog(props) {
  const theme = useTheme();


  const { onClose, setSelectedValue, selectedValue, open, values, title } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog style={{ padding: theme.spacing(2) }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <RadioGroup style={{ padding: theme.spacing(2) }} aria-label="gender" name="gender1" value={selectedValue} onChange={(event) => setSelectedValue(event.target.value)}>
        {values.map((value) => (
          <FormControlLabel key={value.value} value={value.value} control={<Radio />} label={value.text} />
        ))}
      </RadioGroup>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}