import React, { useContext, useState, useEffect } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, Grid, TextField, Button, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, Switch, ListItemIcon, Collapse, IconButton, ListSubheader, Paper, Slider, Tooltip } from '@material-ui/core';
import { CheckCircleTwoTone, NotificationImportantTwoTone, WarningTwoTone, ExpandLess, ExpandMore, Delete, Add } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InvestmentGoalFanChart from './InvestmentGoalFanChart';
import { createRequest } from './LifeEventComponents/MultiGoalRequestFactory';
import { getMappedFunds, callFinancialPlanning, getDefaultInvestmentGoalPlanningResult, InvestmentGoalPlanningResult } from './api_client/tsClient';
import { useAppColors } from './useAppColors';
import { ProductCodeContract } from './api_client';
import { TextNumberFormat } from './NumberFormatCustom'
// import PensionAdviceAppBar from './PensionAdviceAppBar';
import { styleDelegate } from './useStyles';
import { SettingsContext } from './SettingsContext';
import { useDebounce } from './useDebounce';
import SliderInput from './SliderInput';

export default function MultiGoalLifeEvents() {
  const theme = useTheme();
  const classes = makeStyles(styleDelegate as any)();
  const settings = useContext(SettingsContext);
  const { errorMessage, setErrorMessage } = settings;

  const [monthlyContribution, setMonthlyContribution] = useState(1000);
  const [selectedFund, setSelectedFund] = useState<ProductCodeContract | null>(null);
  const [mappedFunds, setMappedFunds] = useState<ProductCodeContract[] | null>(null);
  const [resultState, setResultState] = useState<{ forecast: InvestmentGoalPlanningResult | undefined, referenceForecast: InvestmentGoalPlanningResult | undefined }>({ forecast: getDefaultInvestmentGoalPlanningResult(), referenceForecast: getDefaultInvestmentGoalPlanningResult() });
  const portfolioDevelopmentTimeSeries = resultState?.forecast?.financialPlanningResponseModel.percentileForecast;

  const medianOutcome = resultState.forecast?.financialPlanningResponseModel?.percentileForecast?.find(p => p.percentile === 0.5);
  const notificationStatus = medianOutcome === undefined ? 'ok' : Math.min(...medianOutcome.outcomes.map(o => o.value)) < 0 ? 'notify' : 'ok';

  const [goals, setGoals] = useState([
    {
      id: 1,
      name: 'Save for business',
      type: 'OneTime',
      horizonInYears: 5,
      durationInYears: 0,
      amount: 120000,
      chosenMonthlyContribution: 0,
      enabled: true
    },
    {
      id: 2,
      name: 'Retirement',
      type: 'Recurring',
      horizonInYears: 20,
      durationInYears: 20,
      amount: 45000,
      chosenMonthlyContribution: 0,
      enabled: true
    }
  ]);

  const debouncedMonthlyContributions = useDebounce(monthlyContribution, 800);
  const debouncedGoals = useDebounce(goals, 800);

  const horizons = goals.map(g => g.horizonInYears);
  const horizonSliderUpdated = (event: any, values: any) => {
    // console.log('sliderindex');
    // console.log(currentSliderIndex);
    // console.log(event);
    // console.log(event.path[0].attributes[3].value);
    // path[0].attributes[3]
    // const newGoals = goals.map(a => { return { ...a } });
    // const index =  parseInt(event.target.attributes[3].value);
    // newGoals[index].horizonInYears = values[index];
    // setGoals(newGoals);

    const changedValue = goals.filter(x => !values.includes(x.horizonInYears))[0];
    const newValue = values.filter((x: any) => !goals.map(g => g.horizonInYears).includes(x))[0];
    if (changedValue !== undefined) {
      const newGoals = goals.map(a => { return { ...a } });
      newGoals.find(g => g.id === changedValue.id)!.horizonInYears = newValue;
      setGoals(newGoals);
    }
  };

  useEffect(() => {
    if (settings.currentScenarioSet == null || selectedFund == null)
      return;
    // setNumberOfRequestInFlight(state => state + 1);
    const request = createRequest(
      settings.currentScenarioSet.id,
      0,
      selectedFund.code,
      debouncedMonthlyContributions, debouncedGoals);
    callFinancialPlanning(settings.currentScenarioSet.id,
      30,
      request)
      .then(result => {
        try {
          console.log(result);
          setResultState({ forecast: result as InvestmentGoalPlanningResult, referenceForecast: undefined });
        } finally {
          // setNumberOfRequestInFlight(state => state - 1);
        }
      });
  }, [settings.currentScenarioSet, selectedFund, debouncedGoals, debouncedMonthlyContributions])

  useEffect(() => {
    // setNumberOfRequestInFlight(state => state + 1);
    getMappedFunds(settings.currentScenarioSet.id)
      .then(result => {
        try {
          setMappedFunds(result);
          setSelectedFund(result[0]);
        } finally {
          // setNumberOfRequestInFlight(state => state - 1);
        }
      });
  }, [settings.currentScenarioSet.id])

  const optionsFilter = (options: any, state: any) => {
    return options.filter((o: any) => o.name.toLowerCase().includes(state.inputValue.toLowerCase()));
  };

  const renderGoals = goals.map(goal => (<>
    <ListItem>
      <ListItemIcon style={{ paddingRight: theme.spacing(2) }}>
        <Switch color='primary' checked={goal.enabled} />
      </ListItemIcon>
      <ListItemText primary={goal.name} secondary={goal.horizonInYears + " years"} style={{ minWidth: theme.spacing(20), maxWidth: theme.spacing(30) }} />
      <ListItemText primary='Amount' secondary={<TextNumberFormat decimals={0} value={goal.amount} />} />
      {false ? <ExpandLess /> : <ExpandMore />}
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
    <Collapse in={false} timeout="auto" unmountOnExit>
      Editor here
    </Collapse>
  </>));



  return (
    <div className={classes.backgroundContainer}>
      <Grid container direction="column" justify="flex-start" alignItems='stretch' style={{ minHeight: '100vh' }}>
        <Grid item>
          {/* <PensionAdviceAppBar title='Smart saver demo' /> */}
        </Grid>
        <Grid item style={{ minHeight: '100%', maxWidth: '100%' }}>
          <Grid container spacing={0} className={classes.paperContainer} style={{ textAlign: 'left', paddingTop: theme.spacing(2) }} direction="row" alignItems='stretch' justify='center'>
            <Grid item xs={12} sm={6}>
              <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <Autocomplete
                  style={{ width: '100%' }}
                  id="combo-box"
                  filterOptions={optionsFilter}
                  options={mappedFunds as any[]}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, v) => {
                    setSelectedFund(v);
                  }}
                  value={selectedFund}
                  renderInput={(params) => <TextField {...params} label="Selected fund" variant="outlined" />}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <SliderInput value={monthlyContribution} showCurrency onChange={setMonthlyContribution} minValue={0} maxValue={10000} title={'Monthly contribution'} />
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Paper square>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: theme.spacing(2) }}>
                      <InvestmentGoalFanChart height={theme.spacing(30)} targetAmount={0} horizon={30} currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} />
                      <div style={{ paddingLeft: theme.spacing(7), paddingRight: 20 }}>
                        <Slider
                          track={false}
                          // aria-labelledby="track-false-range-slider"
                          // getAriaValueText={valuetext}
                          onChange={horizonSliderUpdated}
                          // onChangeCommitted={horizonSliderUpdated}

                          value={horizons}
                          ValueLabelComponent={ValueLabelComponent}
                          valueLabelDisplay={'on'}
                          valueLabelFormat={(value, index) => {
                            // setCurrentSliderIndex(index);
                            // console.log('label ' + index)
                            return goals[index].name + ' in ' + value + ' years';
                          }}
                          max={30}
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 10,
                              label: '10',
                            },
                            {
                              value: 20,
                              label: '20',
                            },
                            {
                              value: 30,
                              label: '30',
                            }
                          ]}

                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs>
                    <List
                      //sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} todo
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          Investment goals
                        </ListSubheader>
                      }
                    >
                      {renderGoals}
                    </List>
                    <Grid container style={{ marginBottom: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                      <Grid item xs></Grid>
                      <Grid item xs='auto'>
                        <Button >
                          <Add />Add Goal
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ padding: theme.spacing(2) }}>
                    <TrackingItem style={{ height: theme.spacing(11) }} iconType={notificationStatus} bigText={notificationStatus === 'ok' ? 'On track' : 'Caution'} smallText={notificationStatus === 'ok' ? 'Your current plan is expected to fund your goals.' : 'An increase in monthly contribution is needed to fund your goals.'} />
                    outcome in 30 yrs here
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ marginTop: theme.spacing(4), paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <Grid container>
                  <Grid item xs>
                  </Grid>
                  <Grid item xs='auto'>
                    <Button size="large" style={{ marginRight: theme.spacing(2) }}>Discard changes</Button>
                    <Button variant="contained" color="primary" size="large">
                      Update savings
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar style={{ whiteSpace: 'pre-wrap' }} open={errorMessage !== undefined} autoHideDuration={4000} onClose={() => setErrorMessage(undefined)} message={errorMessage}></Snackbar>
      </Grid>
    </div >
  );
}

const StyledTooltip = withStyles({
  tooltipPlacementTop: {

    top: "8px",
  },
})(Tooltip);
function ValueLabelComponent(props: any) {
  const { children, open, value } = props;

  // console.log(props);

  return (
    <StyledTooltip open={open} enterTouchDelay={0} placement="top" arrow title={value}>
      {children}
    </StyledTooltip>
  );
}

function TrackingItem({ iconType, bigText, smallText, style }: any) {
  const theme = useTheme();
  const colors = useAppColors();



  const icon = iconType === 'ok' ? <CheckCircleTwoTone style={{
    fill: colors.status.ok,
    display: "inline-block",
    marginBottom: "-4px",
    marginRight: theme.spacing(1)
  }} fontSize='inherit' /> : iconType === 'notify' ? <NotificationImportantTwoTone style={{
    fill: colors.status.notify,
    display: "inline-block",
    marginBottom: "-4px",
    marginRight: theme.spacing(1)
  }} fontSize='inherit' /> :
    <WarningTwoTone color='error' style={{
      display: "inline-block",
      marginBottom: "-4px",
      marginRight: theme.spacing(1)
    }} fontSize='inherit' />;

  return (<div style={style}>
    <Typography variant='h4' style={{
      marginRight: 2
    }}>
      {icon}
      {bigText}
    </Typography>
    <Typography variant='body2'>{smallText}</Typography>
  </div>);


}
