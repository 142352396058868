import { OutRankFinancialPlanningApi } from './index'
import { percentiles, wideOutcomePercentiles } from '../appConstants'
import {
    AssetsAndDebts,
    FinancialPlanningRequestModel,
    FinancialPlanningResponseModel,
    Frequency,
    InvestmentAccountTaxationType,
    InvestmentStrategyType,
    ProductCodeCodeType,
    Transactions,
    InflationAdjustmentContract,
    Domicile,
    PortfolioStrategiesRequest,
    CashFlowsAggregationResponse
} from './api';
import moment from 'moment';

let baseUri = "https://kdbrk-or-api-dev-wa.azurewebsites.net";

if (process.env.NODE_ENV === 'development') {
    baseUri = "https://localhost:5001";
}
else if (window.location.hostname.startsWith("demoortestsa") || window.location.hostname.startsWith("demo.outrank.cloud")) {
    baseUri = "https://outrankproxyapp.azurewebsites.net";
}

const investmentAccount = (id: string, isin: string) => {
    return {
        id: id,
        investmentAllocations: [
            {
                currencyCode: "SEK",
                outperformanceAssumption: 0,
                fees: {
                    annualFee: 0,
                    performanceFee: 0,
                    purchaseFee: 0,
                    salesFee: 0,
                    transactionCost: 0
                },
                weight: 1,
                productCode: {
                    code: isin,
                    codeType: ProductCodeCodeType.Isin
                }
            }
        ],
        taxationType: InvestmentAccountTaxationType.SweIsk,
        strategy: InvestmentStrategyType.RebalanceToPlan
    }
};

export async function simpleFinancialPlanning(): Promise<SimpleFinancialPlanningResponse> {

    const now = moment();

    const observationDates = [...Array(31).keys()].map(index => now.clone().add(index, 'years').toDate());

    const firstAccount = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
    const secondAccount = "4fa85f64-5717-4562-b3fc-2c963f66afa6";
    const thirdAccount = "5fa85f64-5717-4562-b3fc-2c963f66afa6";
    const fourthAccount = "6fa85f64-5717-4562-b3fc-2c963f66afa6";
    const earnedIncomeAccount = "77785f64-5717-4562-b3fc-2c963f66afa6";
    const taxAccount = "88885f64-5717-4562-b3fc-2c963f66afa6";


    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccount(firstAccount, 'LU1980295213'),
            investmentAccount(secondAccount, 'SE0000810731'),
            investmentAccount(thirdAccount, 'SE0000810905'),
            investmentAccount(fourthAccount, 'SE0005281847'),
        ],
        transactionAccounts: [
            {
                id: earnedIncomeAccount,
                currencyCode: 'SEK',
                currentValue: 0
            },
            {
                id: taxAccount,
                currencyCode: 'SEK',
                currentValue: 0
            },
        ]
    };

    var earnedIncomeTransactions: Transactions = {
        oneTimeTransactions: [{ targetId: earnedIncomeAccount, date: observationDates[0], amount: 500000 }],
        recurringTransactions: [],
        transactionArrays: []
    }

    var transactions: Transactions = {
        oneTimeTransactions: [
            { targetId: firstAccount, date: observationDates[0], amount: 1000 },
            { sourceId: firstAccount, date: observationDates[10], amount: 10000 },
            { targetId: secondAccount, date: observationDates[0], amount: 20000 },
            { targetId: thirdAccount, date: observationDates[0], amount: 10000 },
            { targetId: thirdAccount, date: observationDates[5], amount: 10000 },
            { targetId: fourthAccount, date: observationDates[0], amount: 15000 }
        ],
        recurringTransactions: [{
            targetId: firstAccount, //in-flows
            startDate: observationDates[0],
            endDate: observationDates[observationDates.length - 1],
            frequency: Frequency.Monthly,
            amount: 2500
        },
        {
            sourceId: firstAccount, //out-flows
            startDate: observationDates[0],
            endDate: observationDates[observationDates.length - 1],
            frequency: Frequency.Monthly,
            amount: 2000
        }],
        transactionArrays: [],
    };



    const client = new OutRankFinancialPlanningApi({
        // apiKey: 'Bearer ' + accessToken.token,
    }, baseUri);



    const scenarioSet = (await client.financialplanningV1SimulationScenarioSetsGet())[0].scenarioSetId;

    const firstGroup = 'C9F3B64D-301C-4FF6-BC39-FC4A38E1C9A1';
    const secondGroup = 'B7013707-441A-4110-9798-ADBCEE6327F0';
    const transactionAccountGroup = '77713707-441A-4110-9798-ADBCEE6327F0';
    const taxAccountGroup = '88813707-441A-4110-9798-ADBCEE6327F0';

    var groups = [
        {
            id: firstGroup,
            assetsAndDebtsItemIds: [firstAccount, secondAccount]
        },
        {
            id: secondGroup,
            assetsAndDebtsItemIds: [thirdAccount, fourthAccount]
        },
        {
            id: transactionAccountGroup,
            assetsAndDebtsItemIds: [earnedIncomeAccount]
        },
        {
            id: taxAccountGroup,
            assetsAndDebtsItemIds: [taxAccount]
        }

    ];

    var strategies: PortfolioStrategiesRequest = {
        waterfallAllocationStrategies: [{
            tiers: [
                { assetsAndDebtsItemId: firstAccount, priority: 1, targetValue: 100000 },
                { assetsAndDebtsItemId: secondAccount, priority: 2, targetValue: 200000 }
            ]
        }]
    };



    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet as string,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        incomesAndExpenses: { taxExempt: transactions as Transactions, earnedIncome: earnedIncomeTransactions },
        observationDates: observationDates,
        inflationAdjustment: InflationAdjustmentContract.NoAdjustment,
        domicile: Domicile.Swe,
        assetsAndDebtsItemGroups: groups,
        portfolioStrategies: strategies,
        taxInformation: { municipalityKey: '0123', taxPaymentAssetsAndDebtsItemId: taxAccount }
    }

    const planningResponse = await client.financialplanningV1FinancialPlanningPost(financialPlanningRequest, scenarioSet);

    const cashFlowResponse = await client.financialplanningV1TransactionsCashFlowsAggregationPost({
        assetsAndDebtsItemId: firstAccount,
        frequency: Frequency.Annual,
        startDate: now.toDate(),
        incomesAndExpenses: { taxExempt: transactions as Transactions },
    })

    return { financialPlanningResponseModel: planningResponse, cashFlowsAggregationResponse: cashFlowResponse };

}

export function getDefaultSimpleFinancialPlanningResponse(): SimpleFinancialPlanningResponse {
    const response: SimpleFinancialPlanningResponse =
    {
        financialPlanningResponseModel: {
            percentileForecast: [{ percentile: wideOutcomePercentiles[1], outcomes: [{ pointInTime: moment().toDate(), value: 0 }] }],
            groupResults: [
                { groupId: '', forecast: [{ expectedValue: 0, pointInTime: moment().toDate() }] },
                { groupId: '', forecast: [{ expectedValue: 0, pointInTime: moment().toDate() }] },
                { groupId: '', forecast: [{ expectedValue: 0, pointInTime: moment().toDate() }] },
                { groupId: '', forecast: [{ expectedValue: 0, pointInTime: moment().toDate() }] }]
        },
        cashFlowsAggregationResponse: {
            cashFlows: [{ _in: 0, date: moment().toDate(), net: 0, out: 0 }]
        }
    }
    return response;
}

export interface SimpleFinancialPlanningResponse {
    financialPlanningResponseModel: FinancialPlanningResponseModel;
    cashFlowsAggregationResponse: CashFlowsAggregationResponse;
}

