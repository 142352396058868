import moment from "moment";
import { AssetsAndDebts, CashFlowObservationsRequest, DividendType, FinancialPlanningRequestModel, Frequency, InflationAdjustmentContract, InvestmentAccountTaxationType, InvestmentStrategyType, ProductCodeCodeType, TypeOfRealEstateRequest } from "../api_client/api";
import { percentiles } from "../appConstants";
import { v4 as uuidv4 } from 'uuid';


export function createSicknessPensionRequest(
    currentAccount: string,
    scenarioSet: string,
    monthlySpending: number,
    bufferAmount: number,
    housingInvestment: number,
    includeValueOfRealEstate: boolean
): FinancialPlanningRequestModel {

    const now = moment("2022-01-01T00:00:00.000Z");

    const observationDates = [...Array(41).keys()].map(index => now.clone().add(index, 'years').toDate());

    const houseLund = uuidv4();
    const growthBox = uuidv4();
    const equityFund = "SE0005281847";

    const initialValueOfFunds = 4640000;

    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            {
                id: growthBox,
                investmentAllocations: [
                    {
                        currencyCode: "SEK",
                        outperformanceAssumption: 0,
                        fees: {
                            annualFee: 0.0028,
                            performanceFee: 0,
                            purchaseFee: 0,
                            salesFee: 0,
                            transactionCost: 0
                        },
                        weight: 1,
                        productCode: {
                            code: equityFund,
                            codeType: ProductCodeCodeType.Isin
                        },
                        currentValue: initialValueOfFunds - housingInvestment
                    }
                ],
                taxationType: InvestmentAccountTaxationType.SweIsk,
                strategy: InvestmentStrategyType.RebalanceToPlan
            },
        ],
        transactionAccounts: [{ id: currentAccount, currencyCode: 'SEK', currentValue: 120000 }],
        realEstate: [
            includeValueOfRealEstate 
            ? { id: houseLund, currentValue: housingInvestment, typeOfProperty: TypeOfRealEstateRequest.PrivateHome, acquisitionValue: housingInvestment }
            : { id: houseLund, currentValue: 0, typeOfProperty: TypeOfRealEstateRequest.PrivateHome, acquisitionValue: 0 }
        ],
        mortgages: [
        ]

    };

    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        cashFlowObservations: CashFlowObservationsRequest.Annual,
        incomesAndExpenses: {
            earnedIncome: {
                recurringTransactions: [],
                oneTimeTransactions: [],
                transactionArrays: [
                    { //pension
                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                        frequency: Frequency.Annual,
                        startDate: now.toDate(),
                        amounts: [103740,	106020,	106020,	108288,	108288,	110568,	110568,	112836,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	115116,	105348,	105348,	105348,	105348,	105348,	105348,	105348,	105348,	105348,	105348,	105348],
                        targetId: currentAccount
                    }
                ]
            },
            dividends: [],
            taxExempt:
            {
                recurringTransactions: [{
                    amount: monthlySpending,
                    startDate: now.toDate(),
                    endDate: now.clone().add(40, 'years').toDate(),
                    frequency: Frequency.Monthly,
                    sourceId: currentAccount,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                }
                ],
                oneTimeTransactions: [],
                transactionArrays: [],
            }
        },
        taxInformation: {
            taxPaymentAssetsAndDebtsItemId: currentAccount,
            municipalityKey: "1281"
        },
        portfolioStrategies: {
            waterfallAllocationStrategies: [{
                tiers: [{
                    assetsAndDebtsItemId: currentAccount,
                    priority: 3,
                    targetValue: bufferAmount,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                },
                {
                    assetsAndDebtsItemId: growthBox,
                    priority: 1,
                    targetValue: 100000000,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                }
                ]
            }]
        },
        assetsAndDebtsItemGroups: [
            {
                id: uuidv4(), // eget kapital tillsammans [6]
                assetsAndDebtsItemIds: [
                    currentAccount,
                    growthBox
                ]
            },
            {
                id: uuidv4(), // huset lund [3]
                assetsAndDebtsItemIds: [
                    houseLund
                ]
            },
        ],
        observationDates: observationDates,
        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
    }
    return financialPlanningRequest;
}

