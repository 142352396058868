import React, { useContext } from 'react';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { CustomerAssetsContext } from './CustomerAssetsContext';
import { MortgagePlanningContext } from './MortgagePlanningContext';
import { calculateMonthlyInterestPayment } from './mortgageFunctions';
import SpendingCircleChart from './SpendingCircleChart';
import { useAppColors } from './useAppColors';
import { InvestmentGoalContext } from './InvestmentGoalContext'


function SpendingSummary(props) {

    const { variant } = props;

    const investmentGoal = useContext(InvestmentGoalContext);
    const colors = useAppColors();
    const basicCustomerInformation = useContext(BasicCustomerInformationContext);
    const customerAssets = useContext(CustomerAssetsContext);
    const mortgagePlanning = useContext(MortgagePlanningContext);
    const { mortgageInterestRate } = customerAssets;
    const interestPayment = calculateMonthlyInterestPayment(customerAssets.mortgageAmount, mortgageInterestRate / 100);
    const availableMonthlySpending = (basicCustomerInformation.netSalary - basicCustomerInformation.ordinaryConsumption - mortgagePlanning.maintenanceCost) < 0
        ? 0
        : Math.round(basicCustomerInformation.netSalary - basicCustomerInformation.ordinaryConsumption - mortgagePlanning.maintenanceCost);

    const bufferDeposit = Number(availableMonthlySpending) - (Number(investmentGoal.monthlyInvestmentAmount) + Number(mortgagePlanning.monthlyMortgagePaymentAmount) + interestPayment);

    const budgetData = variant === 'minimal' ? [
        { name: 'Taxes', value: (basicCustomerInformation.municipalTax + basicCustomerInformation.stateTax) - basicCustomerInformation.taxReduction, color: colors.spendingItems.taxes },
        { name: 'Ordinary Consumption', value: basicCustomerInformation.ordinaryConsumption, color: colors.spendingItems.ordinaryConsumption },
        // { name: 'Maintenance Cost', value: mortgagePlanning.maintenanceCost, color: colors.spendingItems.maintenanceCost },
        // { name: 'Interest Payment', value: interestPayment, color: colors.spendingItems.interestPayment },
        { name: 'Housing', value: mortgagePlanning.monthlyMortgagePaymentAmount + interestPayment + mortgagePlanning.maintenanceCost, color: colors.spendingItems.monthlyAmortisation },
        // { name: 'Investment Amount', value: investmentGoal.monthlyInvestmentAmount, color: colors.spendingItems.investmentAmount },
        { name: 'Remaining Funds', value: bufferDeposit + investmentGoal.monthlyInvestmentAmount, color: colors.spendingItems.personalAccount },
    ]
        : [
            { name: 'Taxes', value: (basicCustomerInformation.municipalTax + basicCustomerInformation.stateTax) - basicCustomerInformation.taxReduction, color: colors.spendingItems.taxes },
            { name: 'Ordinary Consumption', value: basicCustomerInformation.ordinaryConsumption, color: colors.spendingItems.ordinaryConsumption },
            { name: 'Maintenance Cost', value: mortgagePlanning.maintenanceCost, color: colors.spendingItems.maintenanceCost },
            { name: 'Interest Payment', value: interestPayment, color: colors.spendingItems.interestPayment },
            { name: 'Monthly Amortisation', value: mortgagePlanning.monthlyMortgagePaymentAmount, color: colors.spendingItems.monthlyAmortisation },
            { name: 'Investment Amount', value: investmentGoal.monthlyInvestmentAmount, color: colors.spendingItems.investmentAmount },
            { name: 'Remaining Funds', value: bufferDeposit, color: colors.spendingItems.personalAccount },
        ];
    return (<SpendingCircleChart spendingItemData={budgetData} colors={colors} />);
}

function areEqual(prevProps, nextProps) {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

export default React.memo(SpendingSummary, areEqual);

