import React, { useContext, useEffect, useState } from 'react';
import { FormGroup, Grid, TextField, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { getQuestionValues } from './api_client/forecastClient';
import { TextNumberFormat } from './NumberFormatCustom'
import { RiskQuestion } from './RiskQuestion';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { RetirementGoalContext } from './RetirementGoalContext';
import { styleDelegate } from './useStyles';
import { RiskProfileContext } from './RiskProfileContext';
import { SettingsContext } from './SettingsContext';
import { makeStyles } from '@material-ui/core/styles';


function RiskProfile() {
    const riskProfile = useContext(RiskProfileContext);
    const basicCustomerInformation = useContext(BasicCustomerInformationContext);
    const settings = useContext(SettingsContext);
    const retirementGoal = useContext(RetirementGoalContext);

    const classes = makeStyles(styleDelegate)();

    const [questionValues, setQuestionValues] = useState({
        returnRisk: {
            investmentAmount: 0,
            low: {
                returnAmount: 0
            },
            medium: {
                badReturnAmount: 0,
                medianReturnAmount: 0
            },
            high: {
                badReturnAmount: 0,
                medianReturnAmount: 0
            }
        },
        lossRisk: {
            investmentAmount: 0,
            investmentAmountIn3Years: 0,
            low: {
                drawdownAmount: 0
            },
            high: {
                drawdownAmount: 0
            }
        },
        pensionSpecificRisk: {
            low: {
                pensionAmount: 0
            },
            medium: {
                badPensionAmount: 0,
                medianPensionAmount: 0
            },
            high: {
                badPensionAmount: 0,
                medianPensionAmount: 0
            }
        }
    });

    // const prevGrossSalary = usePrevious(basicCustomerInformation.grossSalary);
    // const prevDateOfBirth = usePrevious(basicCustomerInformation.dateOfBirth);
    // const prevTargetMontlyPension = usePrevious(retirementGoal.targetMontlyPension);

    useEffect(() => {
        if (retirementGoal.isValid && basicCustomerInformation.isValid) {
            // (prevGrossSalary !== basicCustomerInformation.grossSalary || prevDateOfBirth !== basicCustomerInformation.dateOfBirth || prevTargetMontlyPension !== basicCustomerInformation.targetMontlyPension)) {
            // setNumberOfRequestInFlight(state => state + 1); // TODO
            getQuestionValues(retirementGoal.targetMontlyPension, basicCustomerInformation.grossSalary, basicCustomerInformation.dateOfBirth, settings.currency)
                .then(result => {
                    try {
                        // handleResult(result, setQuestionValues); TODO
                        setQuestionValues(result)
                    } finally {
                        // setNumberOfRequestInFlight(state => state - 1); TODO
                    }
                });
        }
    }, [basicCustomerInformation, settings.currency, retirementGoal.targetMontlyPension, retirementGoal.isValid]); // TODO maybe debounce

    function renderInvestmentRiskLevel() {
        const title = "Which statement best describes your investment style?"
        const investmentRiskHighRiskText = "I favour the opportunity to receive higher returns even if that implies accepting higher risk exposure.";
        const investmentRiskMiddleRiskText = "I can accept a certain degree of risk, given some level of predictability of the investment outcome.";
        const investmentRiskLowRiskText = "I do not feel comfortable taking risks and I would like to be able to predict the investment outcomes with high accuracy.";
        return (<RiskQuestion title={title} lowRiskText={investmentRiskLowRiskText} middleRiskText={investmentRiskMiddleRiskText} highRiskText={investmentRiskHighRiskText} riskLevel={riskProfile.investmentRiskLevel} setRiskLevel={riskProfile.setInvestmentRiskLevel} classes={classes} />);
    }

    function renderReturnRiskLevel() {
        const returnRiskTitle = (<>Assume you are planning to invest <TextNumberFormat value={questionValues.returnRisk.investmentAmount} />. Which of the alternatives best describes your preferred investment outcomes?</>);
        const returnRiskQuestionStart = "An alternative that in 10 years..."
        const returnRiskLowRiskText = (<>...guarantees <TextNumberFormat value={questionValues.returnRisk.low.returnAmount} />.</>);
        const returnRiskMiddleRiskText = (<>...yields <TextNumberFormat value={questionValues.returnRisk.medium.medianReturnAmount} /> given normal market performance and <TextNumberFormat value={questionValues.returnRisk.medium.badReturnAmount} /> given poor market performance.</>);
        const returnRiskHighRiskText = (<>...yields <TextNumberFormat value={questionValues.returnRisk.high.medianReturnAmount} /> given normal market performance and <TextNumberFormat value={questionValues.returnRisk.high.badReturnAmount} /> given poor market performance.</>);
        return (<RiskQuestion title={returnRiskTitle} questionStart={returnRiskQuestionStart} lowRiskText={returnRiskLowRiskText} middleRiskText={returnRiskMiddleRiskText} highRiskText={returnRiskHighRiskText} riskLevel={riskProfile.returnRiskLevel} setRiskLevel={riskProfile.setReturnRiskLevel} classes={classes} />);
    }

    function renderLossRiskLevel() {
        const lossRiskTitle = (<>Assume you invested <TextNumberFormat value={questionValues.lossRisk.investmentAmount} /> and after three years the value of the investment reaches <TextNumberFormat value={questionValues.lossRisk.investmentAmountIn3Years} />, after which it goes down to <TextNumberFormat value={questionValues.lossRisk.high.drawdownAmount} /> over the next year. Choose the alternative that best describes your attitude to this investment situation. </>);
        const lossRiskHighRiskText = (<>This development does not make me concerned as higher variation is compensated by higher returns. </>);
        const lossRiskMiddleRiskText = (<>I began to worry as soon as the value decreased below <TextNumberFormat value={questionValues.lossRisk.low.drawdownAmount} />. </>);
        const lossRiskLowRiskText = (<>I worry about even slight market drops, none of the above options apply for me. </>);
        return (<RiskQuestion title={lossRiskTitle} highRiskText={lossRiskHighRiskText} middleRiskText={lossRiskMiddleRiskText} lowRiskText={lossRiskLowRiskText} riskLevel={riskProfile.lossRiskLevel} setRiskLevel={riskProfile.setLossRiskLevel} classes={classes} />);
    }

    function renderPensionSpecificRiskLevel() {
        const title = `Increasing risk exposure is usually associated with an opportunity for higher returns. Assume you follow your pension investment plan. Choose the market situation that best describes your preferred investment outcomes.`;
        const questionStart = "An alternative that..."
        const lowRiskText = (<>...guarantees a monthly pension of <TextNumberFormat value={questionValues.pensionSpecificRisk.low.pensionAmount} />. </>);
        const middleRiskText = (<>...yields a monthly pension of <TextNumberFormat value={questionValues.pensionSpecificRisk.medium.medianPensionAmount} /> given normal market performance and <TextNumberFormat value={questionValues.pensionSpecificRisk.medium.badPensionAmount} /> given poor market performance.</>);
        const highRiskText = (<>...yields a monthly pension of <TextNumberFormat value={questionValues.pensionSpecificRisk.high.medianPensionAmount} /> given normal market performance and <TextNumberFormat value={questionValues.pensionSpecificRisk.high.badPensionAmount} /> given poor market performance. </>);
        return (<RiskQuestion title={title} questionStart={questionStart} lowRiskText={lowRiskText} middleRiskText={middleRiskText} highRiskText={highRiskText} riskLevel={riskProfile.pensionSpecificRiskLevel} setRiskLevel={riskProfile.setPensionSpecificRiskLevel} classes={classes} />);
    }
    return (
        <div>
            {renderInvestmentRiskLevel()}
            {renderReturnRiskLevel()}
            {renderLossRiskLevel()}
            {renderPensionSpecificRiskLevel()}
        </div>);
}

export default RiskProfile;


