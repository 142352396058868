import React from 'react';
export const RiskProfileContext = React.createContext({
    investmentRiskLevel: undefined,
    setInvestmentRiskLevel: () => { },
    returnRiskLevel: undefined,
    setReturnRiskLevel: () => { },
    lossRiskLevel: undefined,
    setLossRiskLevel: () => { },
    pensionSpecificRiskLevel: undefined,
    setPensionSpecificRiskLevel: () => { },
    isValid: false
});
