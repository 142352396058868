import {
    PortfolioAnalysisApi,
    PortfolioStressScenarioSetRequest,
    InvestmentStrategyType,
    InvestmentAccountTaxationType,
    PortfolioStressScenarioResponse,
    ProductCodeCodeType
} from './api';


let baseUri = "https://kdbrk-or-dev4-tm.trafficmanager.net";

if (process.env.NODE_ENV === 'development') {
    baseUri = "https://localhost:5001";
}
else if (window.location.hostname.startsWith("demoortestsa") || window.location.hostname.startsWith("demo.outrank.cloud")) {
    baseUri = "https://outrankproxyapp.azurewebsites.net";
}


export async function getStressScenarios(
    scenarioSet: string,
    investmentPortfolio: [{ code: string, codeType: string, name: string, weight: number }])
    : Promise<PortfolioStressScenarioResponse[] | Response> {

    const client = new PortfolioAnalysisApi(undefined, baseUri);
    const request: PortfolioStressScenarioSetRequest = {
        balanceSheetPredictContextId: scenarioSet,
        portfolio: {
            id: "567223b0-afef-4707-9be5-2047673ad839",
            strategy: InvestmentStrategyType.BuyAndHold,
            taxationType: InvestmentAccountTaxationType.NoTax,
            annualAccountFee: 0,
            investmentAllocations: investmentPortfolio.map(a => ({
                currencyCode: "SEK",
                acquisitionValue: 0,
                currentValue: 0,
                fees: { annualFee: 0, performanceFee: 0, purchaseFee: 0, salesFee: 0, transactionCost: 0 },
                productCode: { code: a.code, codeType: ProductCodeCodeType.Isin},
                outperformanceAssumption: 0,
                weight: a.weight
            }))
        }
    };
    const result = await client.apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(request);
    return result;
}