import React from 'react';
import { List, ListItem, ListItemText, Typography, Divider } from '@material-ui/core';


export const RiskQuestion = React.memo((props) => {
  const { classes } = props;
  const questionStart = props.questionStart !== undefined ? (<Typography align="left" variant="body2" className={classes.title}>{props.questionStart}</Typography>) : undefined;
  return (<>
    <div className={classes.questionTitle}>
      <Typography align="left" variant="body1" className={classes.title}><b>{props.title}</b></Typography>
      {questionStart}
    </div>
    <List component="nav" aria-label="riskLevel">
      <Divider />
      <ListItem className={classes.questionList} button selected={props.riskLevel === 'High'} onClick={event => props.setRiskLevel('High')}>
        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={props.highRiskText} />
      </ListItem>
      <Divider />
      <ListItem className={classes.questionList} button selected={props.riskLevel === 'Medium'} onClick={event => props.setRiskLevel('Medium')}>
        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={props.middleRiskText} />
      </ListItem>
      <Divider />
      <ListItem className={classes.questionList} button selected={props.riskLevel === 'Low'} onClick={event => props.setRiskLevel('Low')}>
        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={props.lowRiskText} />
      </ListItem>
      <Divider />
    </List>
  </>);
});
