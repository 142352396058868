import React, { useContext } from 'react';
import { FormGroup, Grid, TextField, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { NumberFormatCustom } from './NumberFormatCustom';
import { styleDelegate } from './useStyles';
import { makeStyles } from '@material-ui/core/styles';
import { CustomerAssetsContext } from './CustomerAssetsContext';

function CustomerAssets() {
  const customerAssetsContext = useContext(CustomerAssetsContext);
  const classes = makeStyles(styleDelegate)();

  return (
    <FormGroup margin="dense">
      <Grid container className={classes.formContainer} spacing={2} alignItems="stretch" direction="row">
        <Grid item xs={12} sm={6}>
          <Grid item >
            <FormControlLabel
              control={<Switch color="primary" checked={customerAssetsContext.estimateCapital} onChange={event => { customerAssetsContext.setEstimateCapital(event.target.checked) }} value={customerAssetsContext.estimateCapital} />}
              label={<Typography variant='body2'>Estimate My Existing Pension Captial</Typography>}
            /></Grid>
          <Grid item><TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} disabled={customerAssetsContext.estimateCapital} label="National Income Pension Capital" onChange={(e) => { customerAssetsContext.setPensionCapital({ ...customerAssetsContext.pensionCapital, nationalIncome: e.target.value }) }} value={customerAssetsContext.pensionCapital.nationalIncome} /></Grid>
          <Grid item><TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} disabled={customerAssetsContext.estimateCapital} label="National Premium Pension Capital" onChange={(e) => { customerAssetsContext.setPensionCapital({ ...customerAssetsContext.pensionCapital, nationalPremium: e.target.value }) }} value={customerAssetsContext.pensionCapital.nationalPremium} /></Grid>
          <Grid item><TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} disabled={customerAssetsContext.estimateCapital} label="Occupational Pension Capital" onChange={(e) => { customerAssetsContext.setPensionCapital({ ...customerAssetsContext.pensionCapital, occupational: e.target.value }) }} value={customerAssetsContext.pensionCapital.occupational} /></Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item><TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} label="Other Private Pension Capital" onChange={(e) => { customerAssetsContext.setExistingPrivatePensionCapital(e.target.value) }} value={customerAssetsContext.existingPrivatePensionCapital} /></Grid>
          <Grid item><TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} label="Additional One-time Deposit" onChange={(e) => { customerAssetsContext.setOneTimeDeposit(e.target.value) }} value={customerAssetsContext.oneTimeDeposit} /></Grid>
        </Grid>
      </Grid>
    </FormGroup>);
}

export default CustomerAssets;



