import React from 'react';

export const SettingsContext = React.createContext({
    locale: 'se',
    currency: 'SEK',
    setCurrency: (currency) => { },
    isLightTheme: true,
    setTheme: () => { },
    currentFullScreenComponent: undefined,
    previousFullScreenComponent: undefined,
    gotoToFullScreenComponent: (newComponent) => { },
    gotoPreviousToFullScreenComponent: () => { },
    errorMessage: undefined,
    setErrorMessage: (message) => { },
    currentScenarioSet: { name: 'Loading...', id: '' },
    setCurrentScenarioSet: () => { },
    availableScenarioSets: [{ name: 'Loading...', id: '' }],
    rateRelativeSek: 1
});
