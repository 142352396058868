import  { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { SettingsContext } from './SettingsContext';
import { blue, red, cyan, orange, green, blueGrey, teal, deepOrange, purple } from '@material-ui/core/colors';

// https://material.io/inline-tools/color/ #60D3F6 / #003F6D
export function useAppColors() {
    const theme = useTheme();
    const settings = useContext(SettingsContext);

    const blue200 = settings.isLightTheme ? "#8fc1d9" : "#60d3f6";
    const blue500 = "#00a6f1";
    const blue700 = settings.isLightTheme ? "#1a6b9d" : "#0086d0"
    const blue900 = settings.isLightTheme ? "#00406d" : "#00559d";
    const green200 = settings.isLightTheme ? "#4eb9b4" : "#60f6ce";
    const green700 = settings.isLightTheme ? "#007d74" : "#00bb7d";
    const green900 = "#008755";
    const red300 = settings.isLightTheme ? "#d95686" : "#f68360";
    const red700 = settings.isLightTheme ? "#a6004e" : "#db4013";
    const deepBlue500 = "#0052e8";
    const purple300 = settings.isLightTheme ? "#d95686" : "#f660d3";
    const yellow700 = "#fbc02d";
    return ({
        // fanChart: {
        //     narrow: cyan[700],
        //     medium: cyan[200],
        //     wide: cyan[100]
        // },
        // spendingItems: {
        //     investmentAmount: green[200],
        //     personalAccount: green[600],
        //     monthlyAmortisation: cyan[500],
        //     interestPayment: red[700]
        // },
        fanChart: {
            narrow: blue900,
            medium: blue500,
            wide: blue200,
            referenceLine: settings.isLightTheme ? theme.palette.grey[900] : theme.palette.grey[200]
        },
        outcomes: {
            bad: red300,
            median: blue700,
            good: green200
        },
        spendingItems: {
            taxes: blue700,
            ordinaryConsumption: purple300,
            maintenanceCost: red700,
            investmentAmount: green200,
            personalAccount: green700,
            monthlyAmortisation: blue200,
            interestPayment: red300
        },
        pensionPots: {
            nationalIncome: green700,
            nationalPremium: red700,
            occupational: green200,
            currentPrivate: blue500,
            newPrivate: blue200
        },
        status: {
            ok: green700,
            notify: yellow700
        }
    });
}