// import { getActiveScenarioSet } from './forecastClient'
import { OutRankFinancialPlanningApi, RequiredFundsFactorRequestModel } from './index'
import { percentiles } from '../appConstants'
import {
    RecurringTransaction,
    AdviceRequestModelInvestmentRiskLevel,
    AdviceRequestModelLossRiskLevel,
    AdviceRequestModelReturnRiskLevel,
    ApplicationSpecificRiskLevel,
    AssetsAndDebts,
    FinancialPlanningRequestModel,
    FinancialPlanningResponseModel,
    Frequency,
    InvestmentAccountTaxationType,
    InvestmentStrategyType,
    IsaContributionStrategy,
    ProductCodeCodeType,
    Transactions, EngageComparisonRequestModel, EngageComparisonResponseModel, ExpectedShortfallResult, SweGrossEarnedIncomePayment, OneTimeTransaction, EmployeeSicknessBenefitInsuranceTypeContract, InflationAdjustmentContract, TypeOfRealEstateRequest, AssetsAndDebtsGroupRequest, CashFlowsAggregationResponse, AggregatedCashFlowResponse, InvestmentAccountRequestModel
} from './api';
import moment from 'moment';
import { wideOutcomePercentiles } from '../appConstants';


let baseUri = "https://kdbrk-or-dev4-tm.trafficmanager.net";

if (process.env.NODE_ENV === 'development') {
    baseUri = "https://localhost:5001";
}
else if (window.location.hostname.startsWith("demoortestsa") || window.location.hostname.startsWith("demo.outrank.cloud")) {
    baseUri = "https://outrankproxyapp.azurewebsites.net";
}

export const namedFunds: any = {
    0: { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    1: { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    2: { id: 'LU1980295213', name: "Global Corporate Bond Fund" },
    3: { id: 'SE0000810731', name: "Skandia SMART Försiktig" },
    4: { id: 'SE0000810905', name: "Skandia SMART Balanserad" },
    5: { id: 'SE0005281847', name: "Skandia Global Exponering" },
};

export const namedFundsArray: any = [
    { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    { id: 'LU1980295213', name: "Global Corporate Bond Fund" },
    { id: 'SE0000810731', name: "Skandia SMART Försiktig" },
    { id: 'SE0000810905', name: "Skandia SMART Balanserad" },
    { id: 'SE0005281847', name: "Skandia Global Exponering" },
];

// export const namedFunds: any = {
//     0: { id: 'SE0000810863', name: "Capital Markets Fund" },
//     1: { id: 'SE0000810863', name: "Capital Markets Fund" },
//     2: { id: 'LU1980295213', name: "Global Corporate Bond Fund" },
//     3: { id: 'SE0000810731', name: "Low Risk Fund" },
//     4: { id: 'SE0000810905', name: "Medium Risk Fund" },
//     5: { id: 'SE0005281847', name: "Global Equity Fund" },
// }

// let currentScenarioSet: string = '';

// export function setScenarioSet(id: string) {
//     currentScenarioSet = id;
// }

export async function getScenarioSets() {
    const client = new OutRankFinancialPlanningApi(undefined, baseUri);
    var sets = await client.financialplanningV1SimulationScenarioSetsGet();
    return sets;
}

export async function getMappedFunds(scenarioSet: string) {
    const client = new OutRankFinancialPlanningApi(undefined, baseUri);
    var sets = await client.financialplanningV1ProductMappedFundsGet(scenarioSet);
    return sets.filter(p => p.codeType === ProductCodeCodeType.Isin);
}

export const investmentAccount = (id: string, riskLevel: number, investmentAccountTaxationType: InvestmentAccountTaxationType, currentValue: number): InvestmentAccountRequestModel => {
    return {
        id: id,
        investmentAllocations: [
            {
                currencyCode: "SEK",
                outperformanceAssumption: 0,
                fees: {
                    annualFee: 0,
                    performanceFee: 0,
                    purchaseFee: 0,
                    salesFee: 0,
                    transactionCost: 0
                },
                weight: 1,
                productCode: {
                    code: namedFunds['' + riskLevel].id,
                    codeType: ProductCodeCodeType.Isin
                },
                currentValue: currentValue
            }
        ],
        taxationType: investmentAccountTaxationType,
        strategy: InvestmentStrategyType.RebalanceToPlan
    }
};

const investmentAccountWithPortfolio = (id: string, allocations: { isin: string, weight: number }[], investmentAccountTaxationType: InvestmentAccountTaxationType) => {
    return {
        id: id,
        investmentAllocations: allocations.map(a => (
            {
                currencyCode: "SEK",
                outperformanceAssumption: 0,
                fees: {
                    annualFee: 0,
                    performanceFee: 0,
                    purchaseFee: 0,
                    salesFee: 0,
                    transactionCost: 0
                },
                weight: a.weight,
                productCode: {
                    code: a.isin,
                    codeType: ProductCodeCodeType.Isin
                }
            })
        ),
        taxationType: investmentAccountTaxationType,
        strategy: InvestmentStrategyType.RebalanceToPlan
    }
};

const transactions = async (
    monthlyGrossSalaryInGbp: number,
    initialContribution: number,
    monthlyContribution: number,
    oneTimeContributions: OneTimeContribution[],
    investmentAccountTaxationType: InvestmentAccountTaxationType,
    observationDates: Date[],
    monthlyAccount: string,
    initialAccount: string,
    now: moment.Moment,
    client: OutRankFinancialPlanningApi,
    taxationSettings: IsaContributionStrategy): Promise<Transactions | Response> => {

    let recurringTransactions: RecurringTransaction[] = [{
        targetId: monthlyAccount,
        startDate: now.toDate(),
        endDate: observationDates[observationDates.length - 1],
        frequency: Frequency.Monthly,
        amount: monthlyContribution
    }];

    if (investmentAccountTaxationType === InvestmentAccountTaxationType.GbrIsa) {
        const apiTransactions = await client.financialplanningV1InvestmentAccountGbrIsaTransactionsGet(
            monthlyContribution,
            monthlyAccount,
            monthlyAccount,
            taxationSettings,
            now.toDate(),
            observationDates[observationDates.length - 1]).catch(handleError);


        if ((apiTransactions as RecurringTransaction[]).length !== undefined) {
            recurringTransactions = (apiTransactions as RecurringTransaction[]);
        }
        else {
            return apiTransactions as Response;
        }
    }
    else if (investmentAccountTaxationType === InvestmentAccountTaxationType.GbrSipp) {
        const apiTransactions = await client.financialplanningV1InvestmentAccountGbrSippTransactionsGet(
            monthlyGrossSalaryInGbp * 12,
            monthlyContribution,
            monthlyAccount,
            now.toDate(),
            observationDates[observationDates.length - 1]).catch(handleError);

        if ((apiTransactions as RecurringTransaction[]).length !== undefined) {
            recurringTransactions = (apiTransactions as RecurringTransaction[]);
        }
        else {
            return apiTransactions as Response;
        }
    }

    const oneTimeTransactionsFromContributions: OneTimeTransaction[] = oneTimeContributions.map(c => ({ targetId: monthlyAccount, date: c.dateOfPayment, amount: c.amount }));

    const defaultTransactions = {
        oneTimeTransactions: oneTimeTransactionsFromContributions.concat([
            {
                targetId: initialAccount,
                date: now.clone().add(1, 'days').toDate(),
                amount: initialContribution
            }
        ]),
        recurringTransactions: recurringTransactions,
        transactionArrays: []
    };
    return defaultTransactions;
}

export async function variableDepositFinancialPlanning(
    scenarioSet: string,
    horizonInYears: number,
    initialContribution: number,
    oneTimeContributions: OneTimeContribution[],
    riskLevel: number,
    investmentAccountTaxationType: InvestmentAccountTaxationType,
    taxationSettings: IsaContributionStrategy,
    monthlyGrossSalaryInGbp: number,
    inflationAdjustment: InflationAdjustmentContract)
    : Promise<InvestmentGoalPlanningResult | Response> {

    const now = moment();

    // const dateOfHorizon = now.clone().add(horizonInYears, 'years').toDate();

    const observationDates = [...Array(41).keys()].map(index => now.clone().add(index, 'years').toDate());

    const client = new OutRankFinancialPlanningApi(undefined, baseUri);

    const initialAccount = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
    const monthlyAccount = "4fa85f64-5717-4562-b3fc-2c963f66afa6";

    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccount(initialAccount, riskLevel, investmentAccountTaxationType, 0),
            investmentAccount(monthlyAccount, riskLevel, investmentAccountTaxationType, 0)
        ]
    };

    // const scenarioSet = currentScenarioSet;

    const financialPlanningTransactions = await transactions(
        monthlyGrossSalaryInGbp,
        initialContribution,
        0,
        oneTimeContributions,
        investmentAccountTaxationType,
        observationDates,
        monthlyAccount,
        initialAccount,
        now,
        client,
        taxationSettings);
    if (financialPlanningTransactions instanceof Response) {
        return financialPlanningTransactions as Response;
    }

    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        incomesAndExpenses: { taxExempt: financialPlanningTransactions as Transactions },
        observationDates: observationDates,
        // applicationSpecificRiskLevel: ApplicationSpecificRiskLevel.Medium,
        // investmentRiskLevel: AdviceRequestModelInvestmentRiskLevel.Medium,
        // lossRiskLevel: AdviceRequestModelLossRiskLevel.Medium,
        // returnRiskLevel: AdviceRequestModelReturnRiskLevel.Medium,
        inflationAdjustment: inflationAdjustment
    }


    const totalDeposits = oneTimeContributions.map(t => t.amount).reduce((t, v): number => t + v);

    const planningResponse = initialContribution + totalDeposits === 0
        ? getDefaultInvestmentGoalPlanningResult().financialPlanningResponseModel
        : await client.financialplanningV1FinancialPlanningPost(financialPlanningRequest, scenarioSet);



    const outcomesAtHorizon = planningResponse.percentileForecast?.length === getDefaultInvestmentGoalPlanningResult().financialPlanningResponseModel.percentileForecast?.length
        ? getDefaultInvestmentGoalPlanningResult().outcomesAtHorizon
        : {
            bad: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[0])?.outcomes[horizonInYears].value ?? 0,
            median: planningResponse.percentileForecast?.find(o => o.percentile === 0.5)?.outcomes[horizonInYears].value ?? 0,
            good: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[1])?.outcomes[horizonInYears].value ?? 0
        }

    const planningResult = {
        financialPlanningResponseModel: planningResponse,
        requiredMonthlyContribution: 0,
        requiredInitialContribution: 0,
        outcomesAtHorizon: outcomesAtHorizon
    };

    return planningResult;
}

export async function callFinancialPlanning(
    scenarioSet: string,
    horizonInYears: number,
    financialPlanningRequest: FinancialPlanningRequestModel
)
    : Promise<InvestmentGoalPlanningResult | Response> {

    const client = new OutRankFinancialPlanningApi(undefined, baseUri);

    const planningResponse = await client.financialplanningV1FinancialPlanningPost(financialPlanningRequest, scenarioSet);

    const outcomesAtHorizon = planningResponse.percentileForecast?.length === getDefaultInvestmentGoalPlanningResult().financialPlanningResponseModel.percentileForecast?.length
        ? getDefaultInvestmentGoalPlanningResult().outcomesAtHorizon
        : {
            bad: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[0])?.outcomes[horizonInYears].value ?? 0,
            median: planningResponse.percentileForecast?.find(o => o.percentile === 0.5)?.outcomes[horizonInYears].value ?? 0,
            good: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[1])?.outcomes[horizonInYears].value ?? 0
        }

    const planningResult = {
        financialPlanningResponseModel: planningResponse,
        requiredMonthlyContribution: 0,
        requiredInitialContribution: 0,
        outcomesAtHorizon: outcomesAtHorizon
    };

    return planningResult;
}


const mortgageAccountId = '2fa85f64-5717-4562-b3fc-2c963f66afa6';
const realEstateAccountId = '3fa85f64-5717-4562-b3fc-2c963f66afa6';
const investmentAccountId = '4fa85f64-5717-4562-b3fc-2c963f66afa6';
const bufferAccountId = '5fa85f64-5717-4562-b3fc-2c963f66afa6';

export const mortgageAssetsAndDebtsItemGroups: Array<AssetsAndDebtsGroupRequestWithName> = [
    { name: 'Current Account', assetsAndDebtsItemIds: [bufferAccountId], id: bufferAccountId },
    // { name: 'Mortgage', assetsAndDebtsItemIds: [mortgageAccountId], id: mortgageAccountId },
    { name: 'Investment Account', assetsAndDebtsItemIds: [investmentAccountId], id: investmentAccountId },
    { name: 'Mortgage/Real Estate', assetsAndDebtsItemIds: [realEstateAccountId, mortgageAccountId], id: realEstateAccountId }
];

export function createMortgageRequest(
    valueOfRealEstate: number,
    sizeOfMortgage: number,
    amortisation: number,
    investmentAmount: number,
    bufferDeposit: number,
    portfolioIndex: number,
    fixedRatePeriodInMonths: number,
    minimumMonthlyAmortization: number,
    annualInterestRate: number)
    : { horizonInYears: number, transactions: Transactions, assetsAndDebts: AssetsAndDebts, mortgageAssetsAndDebtsItemGroups: Array<AssetsAndDebtsGroupRequestWithName> } {

    const now = moment().add(1, 'days');
    const horizonInYears = 20;

    // const interestRatePaymentAccountId = '6fa85f64-5717-4562-b3fc-2c963f66afa6';

    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccount(realEstateAccountId, 3, InvestmentAccountTaxationType.SweIsk, 0),
            investmentAccount(investmentAccountId, 3 + portfolioIndex, InvestmentAccountTaxationType.SweIsk, 0),
            // investmentAccount(interestRatePaymentAccountId, 1, InvestmentAccountTaxationType.NoTax),
        ],
        transactionAccounts: [
            {
                id: bufferAccountId,
                currencyCode: 'SEK',
                currentValue: 0
            }
        ],
        mortgages: [{ id: mortgageAccountId, annualInterestRate: annualInterestRate, fixedRatePeriodInMonths: fixedRatePeriodInMonths, minimumMonthlyAmortization: minimumMonthlyAmortization, interestRatePaymentAccountId: bufferAccountId }]
    };

    const transactions = {
        oneTimeTransactions: [
            {
                amount: -sizeOfMortgage,
                targetId: mortgageAccountId,
                date: now.toDate(),
            },
            {
                amount: valueOfRealEstate,
                targetId: realEstateAccountId,
                date: now.toDate(),
            },
        ],
        recurringTransactions: [
            {
                amount: amortisation,
                targetId: mortgageAccountId,
                frequency: Frequency.Monthly,
                startDate: now.toDate(),
                endDate: now.clone().add(horizonInYears, 'years').toDate()
            },
            {
                amount: investmentAmount,
                targetId: investmentAccountId,
                frequency: Frequency.Monthly,
                startDate: now.toDate(),
                endDate: now.clone().add(horizonInYears, 'years').toDate()
            },
            {
                amount: bufferDeposit,
                targetId: bufferAccountId,
                frequency: Frequency.Monthly,
                startDate: now.toDate(),
                endDate: now.clone().add(horizonInYears, 'years').toDate()
            }
        ],
        transactionArrays: []
    }


    return { horizonInYears, transactions, assetsAndDebts, mortgageAssetsAndDebtsItemGroups };
}

interface AssetsAndDebtsGroupRequestWithName extends AssetsAndDebtsGroupRequest {
    name: string;
}

export async function financialPlanning(
    scenarioSet: string,
    horizonInYears: number,
    transactions: Transactions,
    assetsAndDebts: AssetsAndDebts,
    assetsAndDebtsItemGroups: Array<AssetsAndDebtsGroupRequestWithName>)
    : Promise<InvestmentGoalPlanningResult | Response> {

    const now = moment();


    // const dateOfHorizon = now.clone().add(horizonInYears, 'years').toDate();

    const observationDates = [...Array(horizonInYears + 1).keys()].map(index => now.clone().add(index, 'years').toDate());

    const client = new OutRankFinancialPlanningApi(undefined, baseUri);

    // const scenarioSet = currentScenarioSet;

    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        incomesAndExpenses: { taxExempt: transactions as Transactions },
        observationDates: observationDates,
        assetsAndDebtsItemGroups: assetsAndDebtsItemGroups,
        // applicationSpecificRiskLevel: ApplicationSpecificRiskLevel.Medium,
        // investmentRiskLevel: AdviceRequestModelInvestmentRiskLevel.Medium,
        // lossRiskLevel: AdviceRequestModelLossRiskLevel.Medium,
        // returnRiskLevel: AdviceRequestModelReturnRiskLevel.Medium,
        inflationAdjustment: InflationAdjustmentContract.NoAdjustment
    }

    const planningResponse = await client.financialplanningV1FinancialPlanningPost(financialPlanningRequest, scenarioSet);

    const outcomesAtHorizon = planningResponse.percentileForecast?.length === getDefaultInvestmentGoalPlanningResult().financialPlanningResponseModel.percentileForecast?.length
        ? getDefaultInvestmentGoalPlanningResult().outcomesAtHorizon
        : {
            bad: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[0])?.outcomes[horizonInYears].value ?? 0,
            median: planningResponse.percentileForecast?.find(o => o.percentile === 0.5)?.outcomes[horizonInYears].value ?? 0,
            good: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[1])?.outcomes[horizonInYears].value ?? 0
        }

    const planningResult = {
        financialPlanningResponseModel: planningResponse,
        requiredMonthlyContribution: 0,
        requiredInitialContribution: 0,
        outcomesAtHorizon: outcomesAtHorizon
    };


    return planningResult;

}


export async function investmentGoalPlanning(
    scenarioSet: string,
    horizonInYears: number,
    targetAmount: number,
    initialContribution: number,
    monthlyContribution: number,
    riskLevel: number,
    investmentAccountTaxationType: InvestmentAccountTaxationType,
    taxationSettings: IsaContributionStrategy,
    monthlyGrossSalaryInGbp: number)
    : Promise<InvestmentGoalPlanningResult | Response> {


    const now = moment();

    const dateOfHorizon = now.clone().add(horizonInYears, 'years').toDate();

    const observationDates = [...Array(31).keys()].map(index => now.clone().add(index + 1, 'years').toDate());

    const client = new OutRankFinancialPlanningApi(undefined, baseUri);

    const initialAccount = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
    const monthlyAccount = "4fa85f64-5717-4562-b3fc-2c963f66afa6";


    const assetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccount(initialAccount, riskLevel, investmentAccountTaxationType, 0),
            investmentAccount(monthlyAccount, riskLevel, investmentAccountTaxationType, 0)
        ]
    };

    const defaultContribution = 1000;

    const initialContributionForCalculation = initialContribution === 0 ? defaultContribution : initialContribution;


    const initialRequiredFundsTransactions = await transactions(monthlyGrossSalaryInGbp,
        initialContributionForCalculation,
        monthlyContribution,
        [],
        investmentAccountTaxationType,
        observationDates,
        monthlyAccount,
        initialAccount,
        now,
        client,
        taxationSettings);
    if (initialRequiredFundsTransactions instanceof Response) {
        return initialRequiredFundsTransactions as Response;
    }

    const initialRequiredFundsRequest: RequiredFundsFactorRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        incomesAndExpenses: { taxExempt: initialRequiredFundsTransactions as Transactions },
        itemIdForFactorInAssetsAndDebts: initialAccount,
        targetNetAssetValue: targetAmount,
        observationDates: observationDates,
    }

    const initialRequiredResponse = await client.financialplanningV1RequiredFundsFactorPost(initialRequiredFundsRequest);
    const initialFactor = initialRequiredResponse.requiredFundsFactors?.find(f => isSameDate(new Date(f.observationDate), new Date(dateOfHorizon)))?.factor ?? 0;
    const requiredInitialContribution = initialContribution === 0 ? initialFactor * defaultContribution : initialFactor * initialContribution;

    const monthlyContributionForCalculation = monthlyContribution === 0 ? defaultContribution : monthlyContribution;

    const monthlyRequiredFundsTransactions = await transactions(monthlyGrossSalaryInGbp, initialContribution, monthlyContributionForCalculation, [],
        investmentAccountTaxationType,
        observationDates,
        monthlyAccount,
        initialAccount,
        now,
        client,
        taxationSettings);
    if (monthlyRequiredFundsTransactions instanceof Response) {
        return monthlyRequiredFundsTransactions as Response;
    }

    const monthlyRequiredFundsRequest: RequiredFundsFactorRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        incomesAndExpenses: { taxExempt: monthlyRequiredFundsTransactions as Transactions },
        itemIdForFactorInAssetsAndDebts: monthlyAccount,
        targetNetAssetValue: targetAmount,
        observationDates: observationDates,
    }

    const monthlyRequiredResponse = await client.financialplanningV1RequiredFundsFactorPost(monthlyRequiredFundsRequest);
    const monthlyFactor = monthlyRequiredResponse.requiredFundsFactors?.find(f => isSameDate(new Date(f.observationDate), new Date(dateOfHorizon)))?.factor ?? 0;
    const monthlyInitialContribution = monthlyContribution === 0 ? monthlyFactor * defaultContribution : monthlyFactor * monthlyContribution;

    const financialPlanningTransactions = await transactions(monthlyGrossSalaryInGbp, initialContribution, monthlyContribution, [],
        investmentAccountTaxationType,
        observationDates,
        monthlyAccount,
        initialAccount,
        now,
        client,
        taxationSettings);
    if (financialPlanningTransactions instanceof Response) {
        return financialPlanningTransactions as Response;
    }

    const financialPlanningRequest: FinancialPlanningRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        assetsAndDebts: assetsAndDebts,
        incomesAndExpenses: { taxExempt: financialPlanningTransactions as Transactions },
        observationDates: observationDates
    }


    const planningResponse = initialContribution + monthlyContribution === 0
        ? getDefaultInvestmentGoalPlanningResult().financialPlanningResponseModel
        : await client.financialplanningV1FinancialPlanningPost(financialPlanningRequest, scenarioSet);

    const outcomesAtHorizon = planningResponse.percentileForecast?.length === getDefaultInvestmentGoalPlanningResult().financialPlanningResponseModel.percentileForecast?.length
        ? getDefaultInvestmentGoalPlanningResult().outcomesAtHorizon
        : {
            bad: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[0])?.outcomes[horizonInYears - 1].value ?? 0,
            median: planningResponse.percentileForecast?.find(o => o.percentile === 0.5)?.outcomes[horizonInYears - 1].value ?? 0,
            good: planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[1])?.outcomes[horizonInYears - 1].value ?? 0
        }

    const planningResult = {
        financialPlanningResponseModel: planningResponse,
        requiredMonthlyContribution: targetAmount === 0 ? 0 : Math.ceil(monthlyInitialContribution),
        requiredInitialContribution: targetAmount === 0 ? 0 : Math.ceil(requiredInitialContribution),
        outcomesAtHorizon: outcomesAtHorizon
    };

    return planningResult;

}


export async function engageComparison(
    scenarioSet: string,
    horizonInYears: number,
    initialContribution: number,
    monthlyContribution: number,
    investmentAccountTaxationType: InvestmentAccountTaxationType,
    taxationSettings: IsaContributionStrategy,
    monthlyGrossSalaryInGbp: number,
    currentAllocations: { isin: string, weight: number }[],
    referenceAllocations: { isin: string, weight: number }[])
    : Promise<GoalTrackingResult | Response> {

    const now = moment();

    const observationDates = [...Array(31).keys()].map(index => now.clone().add(index, 'years').toDate());

    const client = new OutRankFinancialPlanningApi(undefined, baseUri);

    const initialAccount = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
    const monthlyAccount = "4fa85f64-5717-4562-b3fc-2c963f66afa6";


    const currentAssetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccountWithPortfolio(initialAccount, currentAllocations, investmentAccountTaxationType),
            investmentAccountWithPortfolio(monthlyAccount, currentAllocations, investmentAccountTaxationType)
        ]
    };

    const targetAssetsAndDebts: AssetsAndDebts = {
        investmentAccounts: [
            investmentAccountWithPortfolio(initialAccount, referenceAllocations, investmentAccountTaxationType),
            investmentAccountWithPortfolio(monthlyAccount, referenceAllocations, investmentAccountTaxationType)
        ]
    };

    const financialPlanningTransactions = await transactions(monthlyGrossSalaryInGbp, initialContribution, monthlyContribution, [],
        investmentAccountTaxationType,
        observationDates,
        monthlyAccount,
        initialAccount,
        now,
        client,
        taxationSettings);
    if (financialPlanningTransactions instanceof Response) {
        return financialPlanningTransactions as Response;
    }

    const financialPlanningRequest: EngageComparisonRequestModel = {
        scenarioSetId: scenarioSet,
        startDate: now.toDate(),
        percentiles: percentiles,
        currentAssetsAndDebts: currentAssetsAndDebts,
        targetAssetsAndDebts: targetAssetsAndDebts,
        incomesAndExpenses: { taxExempt: financialPlanningTransactions as Transactions },
        observationDates: observationDates,
        applicationSpecificRiskLevel: ApplicationSpecificRiskLevel.Medium,
        investmentRiskLevel: AdviceRequestModelInvestmentRiskLevel.Medium,
        lossRiskLevel: AdviceRequestModelLossRiskLevel.Medium,
        returnRiskLevel: AdviceRequestModelReturnRiskLevel.Medium
    }

    console.log("currentAllocations");
    console.log(financialPlanningRequest);


    const planningResponse = initialContribution + monthlyContribution === 0
        ? getDefaultEngageComparisonResult().engageComparisonResponseModel
        : await client.financialplanningV1EngageComparisonPost(financialPlanningRequest);

    const outcomesAtHorizon = planningResponse.currentPortfolioDevelopmentTimeSeries?.length === getDefaultEngageComparisonResult().engageComparisonResponseModel.currentPortfolioDevelopmentTimeSeries?.length
        ? getDefaultEngageComparisonResult()
        : {
            engageComparisonResponseModel: planningResponse,
            outcomesAtHorizon: {
                bad: planningResponse.currentPortfolioDevelopmentTimeSeries?.find(o => o.percentile === wideOutcomePercentiles[0])?.outcomes[horizonInYears].value ?? 0,
                median: planningResponse.currentPortfolioDevelopmentTimeSeries?.find(o => o.percentile === 0.5)?.outcomes[horizonInYears].value ?? 0,
                good: planningResponse.currentPortfolioDevelopmentTimeSeries?.find(o => o.percentile === wideOutcomePercentiles[1])?.outcomes[horizonInYears].value ?? 0
            },
            currentExpectedShortfallAtHorizon: planningResponse.currentExpectedShortfall[horizonInYears],
            targetExpectedShortfallAtHorizon: planningResponse.targetExpectedShortfall[horizonInYears],
            currentDiversificationOutcomeAtHorizon: planningResponse.currentDiversificationOutcomes[horizonInYears].value,
            targetDiversificationOutcomeAtHorizon: planningResponse.targetDiversificationOutcomes[horizonInYears].value,
        };

    return outcomesAtHorizon;

}

export async function getIncomeTrajectory(grossMonthlySalary: number, dateOfBirth: Date, sickLeaveStartMonth: number, sickLeaveEndMonth: number, rateOfAbsence: number, monthlyCosts: number, employeeSicknessBenefitInsuranceType: EmployeeSicknessBenefitInsuranceTypeContract)
    : Promise<{ dateOfPayment: Date, in: number, out: number, net: number }[]> {
    const client = new OutRankFinancialPlanningApi(undefined, baseUri);
    const now = moment();

    const endDate = now.clone().add(30, 'years').add(1, 'months').toDate();

    const salaryTrajectory = await client.financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalary, dateOfBirth, now.toDate(), endDate);

    const sickLeaveTrajectory = await client.financialplanningV1IncomeSweEmployeeSicknessCompensationPost({
        salaryTrajectory: salaryTrajectory,
        rateOfAbsence: rateOfAbsence,
        sickLeaveStartDate: now.clone().add(sickLeaveStartMonth, 'months').toDate(),
        sickLeaveEndDate: now.clone().add(sickLeaveEndMonth, 'months').toDate(),
        employeeSicknessBenefitInsuranceType
    });

    const afterTaxesTrajectory = await client.financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost({ salaryTrajectory: sickLeaveTrajectory, officialMunicipalityKey: 'NA' });
    const totalNetTrajectory = afterTaxesTrajectory.map((t, i) => ({
        dateOfPayment: t.dateOfPayment,
        in: afterTaxesTrajectory[i].amount,
        out: monthlyCosts,
        net: afterTaxesTrajectory[i].amount - monthlyCosts
    }))
    return totalNetTrajectory;
}

export async function getSimpleIncomeTrajectory(grossMonthlySalary: number, dateOfBirth: Date, monthlyCosts: number): Promise<AggregatedCashFlowResponse[]> {
    const client = new OutRankFinancialPlanningApi(undefined, baseUri);
    const now = moment();

    const endDate = now.clone().add(30, 'years').add(1, 'months').toDate();

    const salaryTrajectory = await client.financialplanningV1IncomeSweGrossSalaryPredictionGet(grossMonthlySalary, dateOfBirth, now.toDate(), endDate);

    const afterTaxesTrajectory = await client.financialplanningV1IncomeSweGrossToNetSalaryFromTrajectoryPost({ salaryTrajectory: salaryTrajectory, officialMunicipalityKey: 'NA' });

    const totalNetTrajectory = afterTaxesTrajectory.map((t, i) => ({
        date: t.dateOfPayment,
        in: afterTaxesTrajectory[i].amount,
        out: monthlyCosts,
        net: afterTaxesTrajectory[i].amount - monthlyCosts
    }))

    return totalNetTrajectory;
}

function isSameDate(date1: Date, date2: Date): boolean {
    const isSame = date1.getUTCFullYear() === date2.getUTCFullYear() ?? date1.getUTCMonth() === date2.getUTCMonth() ?? date1.getUTCDay() === date2.getUTCDay();
    return isSame;
}


export function getDefaultInvestmentGoalPlanningResult(): InvestmentGoalPlanningResult {
    const response: InvestmentGoalPlanningResult =
    {
        financialPlanningResponseModel: {
            percentileForecast: [{ percentile: wideOutcomePercentiles[1], outcomes: [{ pointInTime: moment().toDate(), value: 0 }] }],
            // utilityOutcomes: [{ pointInTime: moment().toDate(), utility: 0 }]
            groupResults: [],
            cashFlows: []

        },
        requiredInitialContribution: 0,
        requiredMonthlyContribution: 0,
        outcomesAtHorizon: {
            bad: 0,
            median: 0,
            good: 0
        }
    }
    return response;
}

export function getDefaultEngageComparisonResult(): GoalTrackingResult {
    const response: GoalTrackingResult =
    {
        engageComparisonResponseModel: {
            currentPortfolioDevelopmentTimeSeries: [{ percentile: wideOutcomePercentiles[1], outcomes: [{ pointInTime: moment().toDate(), value: 0 }] }],
            targetPortfolioDevelopmentTimeSeries: [{ percentile: wideOutcomePercentiles[1], outcomes: [{ pointInTime: moment().toDate(), value: 0 }] }],
            currentExpectedShortfall: [{ pointInTime: moment().toDate(), expectedShortfall: 0, restOfDistributionSamples: [0], tailDistributionSamples: [0], investedCapital: 0 }],
            targetExpectedShortfall: [{ pointInTime: moment().toDate(), expectedShortfall: 0, restOfDistributionSamples: [0], tailDistributionSamples: [0], investedCapital: 0 }],
            currentDiversificationOutcomes: [{ pointInTime: moment().toDate(), value: 0 }],
            targetDiversificationOutcomes: [{ pointInTime: moment().toDate(), value: 0 }]
        },
        outcomesAtHorizon: {
            bad: 0,
            median: 0,
            good: 0
        },
        currentExpectedShortfallAtHorizon: { expectedShortfall: 0, restOfDistributionSamples: [0], tailDistributionSamples: [0], investedCapital: 0 },
        targetExpectedShortfallAtHorizon: { expectedShortfall: 0, restOfDistributionSamples: [0], tailDistributionSamples: [0], investedCapital: 0 },
        currentDiversificationOutcomeAtHorizon: 0,
        targetDiversificationOutcomeAtHorizon: 0
    }
    return response;
}

function handleError(err: Response) {
    console.warn(err);
    const response = new Response('Your yearly contribution is above the allowed limit.');
    return response;
};

export interface InvestmentGoalPlanningResult {
    financialPlanningResponseModel: FinancialPlanningResponseModel;
    requiredInitialContribution: number;
    requiredMonthlyContribution: number;
    outcomesAtHorizon: OutcomesAtHorizon;
}

export interface GoalTrackingResult {
    engageComparisonResponseModel: EngageComparisonResponseModel;
    outcomesAtHorizon: OutcomesAtHorizon;
    currentExpectedShortfallAtHorizon: { expectedShortfall: number, restOfDistributionSamples: number[], tailDistributionSamples: number[], investedCapital: number };
    targetExpectedShortfallAtHorizon: { expectedShortfall: number, restOfDistributionSamples: number[], tailDistributionSamples: number[], investedCapital: number };
    currentDiversificationOutcomeAtHorizon: number;
    targetDiversificationOutcomeAtHorizon: number;
}

export interface OutcomesAtHorizon {
    bad: number;
    median: number;
    good: number;
}

export interface SweIncomePayment {
    dateOfPayment: Date;
    grossMonthlySalary: number;
    grossMonthlySickLeaveIncome: number;
    netMonthlySickLeaveIncome: number;
    netTotalIncome: number;
}

export interface OneTimeContribution {
    dateOfPayment: Date;
    amount: number;
}
