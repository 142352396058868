import React from 'react';
export const RetirementGoalContext = React.createContext({
    targetMontlyPension: '',
    setTargetMontlyPension: () => { },
    ageOfRetirement: '65',
    setAgeOfRetirement: () => { },
    isValid: false,
});


