import React, { useState, useContext, useEffect } from 'react';
import { FormGroup, Grid, TextField, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { NumberFormatCustom } from './NumberFormatCustom';
import moment from "moment";
import { styleDelegate } from './useStyles';
import { makeStyles } from '@material-ui/core/styles';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';

function BasicCustomerInformation() {
    const basicCustomerInformation = useContext(BasicCustomerInformationContext);
    const [dateOfBirthAsMomentState, setDateOfBirthAsMomentState] = useState(null);
    const classes = makeStyles(styleDelegate)();


    useEffect(() => {
        const newMoment = moment(basicCustomerInformation.dateOfBirth)
        if (newMoment.isValid()) setDateOfBirthAsMomentState(moment(basicCustomerInformation.dateOfBirth));
    }, [basicCustomerInformation.dateOfBirth])


    function updateDateOfBirth(dateOfBirth) {
        if (dateOfBirth && dateOfBirth.isValid() && dateOfBirth !== dateOfBirthAsMomentState) {
            const asString = moment(dateOfBirth).format('YYYY-MM-DD');
            basicCustomerInformation.setDateOfBirth(asString);
            setDateOfBirthAsMomentState(dateOfBirth);
        }
    }

    return (
        <FormGroup margin="dense">
            <Grid container className={classes.formContainer} alignItems="stretch" direction="column">
                <Grid item>
                    <KeyboardDatePicker fullWidth={true} required format="YYYY-MM-DD" disableFuture={true} label="Date of Birth" onChange={(e) => { updateDateOfBirth(e); }} value={dateOfBirthAsMomentState} InputLabelProps={{ shrink: true, }} type='tel' />
                </Grid>
                <Grid item>
                    <TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} required label="Monthly Salary (Gross)" onChange={(e) => { basicCustomerInformation.setGrossSalary(e.target.value); }} value={basicCustomerInformation.grossSalary} />
                </Grid>
                {/* <Grid item>
                    <TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} required label="Monthly Spending" onChange={(e) => { basicCustomerInformation.setMonthlySpending(e.target.value); }} value={basicCustomerInformation.monthlySpending} />
                </Grid> */}
                <Grid item>
                    <FormControl fullWidth={true} className={classes.formControl}>
                        <InputLabel id="numberOfAdults-select-label">Number of Adults</InputLabel>
                        <Select labelId="numberOfAdults-select-label" value={basicCustomerInformation.numberOfAdults} onChange={event => { basicCustomerInformation.setNumberOfAdults(event.target.value) }}>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth={true} className={classes.formControl}>
                        <InputLabel id="numberOfChildren-select-label">Number of Children</InputLabel>
                        <Select labelId="numberOfChildren-select-label" value={basicCustomerInformation.numberOfChildren} onChange={event => { basicCustomerInformation.setNumberOfChildren(event.target.value) }}>
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </FormGroup>);
}

export default BasicCustomerInformation;
