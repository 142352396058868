import React, { useState, useEffect, useContext, useRef } from 'react';
import { getEstimations, getAdvice } from './api_client/forecastClient';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Collapse, Paper, Slider, Typography, FormGroup, FormControl, Grid, TextField, IconButton, Divider, Tooltip } from '@material-ui/core';
import { Element, scroller } from 'react-scroll'
import { AssessmentOutlined, Launch } from '@material-ui/icons';
import { TextNumberFormat, NumberFormatCustom } from './NumberFormatCustom'
import moment from "moment";
import { styleDelegate } from './useStyles';
import PensionBarChart from './PensionBarChart';
import PensionFanChart from './PensionFanChart';
import { PensionResultTable } from './PensionResultTable';
import { SettingsContext } from './SettingsContext';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { CustomerAssetsContext } from './CustomerAssetsContext';
import { RiskProfileContext } from './RiskProfileContext';
import { RetirementGoalContext } from './RetirementGoalContext';
import { usePrevious } from './usePrevious';
import { useDebounce } from './useDebounce';
import { wideOutcomePercentiles } from './appConstants';
import { useAppColors } from './useAppColors';
import { GoalAppShell } from './GoalAppShell';



moment.locale("se");

const CustomTooltip = withStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.primary.dark,
      fontSize: 12
    },
    arrow: {
      color: theme.palette.primary.dark
    }
  }
})(Tooltip);


const defaultEstimatedState = {
  defaultTargetPension: undefined,
  estimatedPensionCapital: {
    nationalIncome: 0,
    nationalPremium: 0,
    occupational: 0
  }
}

function RetirementGoal() {

  const classes = makeStyles(styleDelegate)();
  const theme = useTheme();
  const colors = useAppColors();

  const settings = useContext(SettingsContext);
  const basicCustomerInformation = useContext(BasicCustomerInformationContext);
  const customerAssetsContext = useContext(CustomerAssetsContext);
  const riskProfile = useContext(RiskProfileContext);
  const retirementGoal = useContext(RetirementGoalContext);

  const debouncedInputState = useDebounce(retirementGoal, 800);
  const debouncedBasicCustomerInformation = useDebounce(basicCustomerInformation, 800);
  const debouncedCustomerAssetsContext = useDebounce(customerAssetsContext, 800);

  const [estimatedState, setEstimatedState] = useState(defaultEstimatedState);



  const [resultState, setResultState] = useState({
    isGoalAlreadyReached: false,
    requiredDeposit: 0,
    rankedPortfolios: [{
      outcomes: [
        {
          percentile: 0.05,
          nationalPension: 0,
          occupationalPension: 0,
          currentPrivatePension: 0,
          newPrivatePension: 0
        },
        {
          percentile: 0.5,
          nationalPension: 0,
          occupationalPension: 0,
          currentPrivatePension: 0,
          newPrivatePension: 0
        },
        {
          percentile: 0.95,
          nationalPension: 0,
          occupationalPension: 0,
          currentPrivatePension: 0,
          newPrivatePension: 0
        }],
      recommendedInvestment: { isin: '', taxationType: '', name: '' },
      portfolioDevelopmentTimeSeries: [{ percentile: wideOutcomePercentiles[1], outcomes: [{ pointInTime: moment(), value: 0 }] }]
    }]
  });

  const outcomes = resultState.rankedPortfolios[0].outcomes;
  const recommendedInvestment = resultState.rankedPortfolios[0].recommendedInvestment;
  const portfolioDevelopmentTimeSeries = resultState.rankedPortfolios[0].portfolioDevelopmentTimeSeries;


  const [numberOfRequestInFlight, setNumberOfRequestInFlight] = useState(0);

  const [targetMontlyPensionEntered, setTargetMontlyPensionEntered] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const { errorMessage, setErrorMessage } = settings;


  const prevGrossSalary = usePrevious(debouncedBasicCustomerInformation.grossSalary);
  const prevDateOfBirth = usePrevious(debouncedBasicCustomerInformation.dateOfBirth);
  const prevTargetMontlyPension = usePrevious(debouncedInputState.targetMontlyPension);
  const prevDebouncedInputState = usePrevious(debouncedInputState);
  const prevDebouncedBasicCustomerInformation = usePrevious(debouncedBasicCustomerInformation);
  const prevDebouncedCustomerAssetsContext = usePrevious(debouncedCustomerAssetsContext);

  const prevRiskProfile = usePrevious(riskProfile);

  const [showResultTooltip, setShowResultTooltip] = useState(false);

  useEffect(() => {
    if (debouncedBasicCustomerInformation.isValid) {

      if (prevGrossSalary !== debouncedBasicCustomerInformation.grossSalary || prevDateOfBirth !== debouncedBasicCustomerInformation.dateOfBirth) {
        setNumberOfRequestInFlight(state => state + 1);
        getEstimations(Number(debouncedBasicCustomerInformation.grossSalary), debouncedBasicCustomerInformation.dateOfBirth, settings.currency)
          .then(result => {
            try {
              handleResult(result, setEstimatedState);
            } finally {
              setNumberOfRequestInFlight(state => state - 1);
            }
          });
      }
      if (
        (Number(debouncedInputState.ageOfRetirement) > 50 &&
          Number(debouncedInputState.targetMontlyPension) > 1000) &&
        (JSON.stringify(prevDebouncedInputState) !== JSON.stringify(debouncedInputState) ||
          JSON.stringify(prevDebouncedBasicCustomerInformation) !== JSON.stringify(debouncedBasicCustomerInformation) ||
          JSON.stringify(prevDebouncedCustomerAssetsContext) !== JSON.stringify(debouncedCustomerAssetsContext) ||
          ((riskProfile.isValid) && (JSON.stringify(riskProfile) !== JSON.stringify(prevRiskProfile)))
        )
      ) {
        setNumberOfRequestInFlight(state => state + 1);

        getAdvice(Number(debouncedBasicCustomerInformation.grossSalary),
          debouncedBasicCustomerInformation.dateOfBirth,
          Number(debouncedInputState.ageOfRetirement),
          Number(debouncedInputState.targetMontlyPension),
          debouncedBasicCustomerInformation.monthlySpending !== '' ? Number(debouncedBasicCustomerInformation.monthlySpending) : undefined,
          Number(debouncedCustomerAssetsContext.pensionCapital.nationalIncome),
          Number(debouncedCustomerAssetsContext.pensionCapital.nationalPremium),
          Number(debouncedCustomerAssetsContext.pensionCapital.occupational),
          Number(debouncedCustomerAssetsContext.existingPrivatePensionCapital),
          Number(debouncedCustomerAssetsContext.oneTimeDeposit),
          riskProfile.pensionSpecificRiskLevel,
          riskProfile.investmentRiskLevel,
          riskProfile.returnRiskLevel,
          riskProfile.lossRiskLevel,
          settings.currency).then(result => {
            try {
              handleResult(result, setResultState);
            } finally {
              setNumberOfRequestInFlight(state => state - 1);
            }
          });
      }
    }
  }, [debouncedCustomerAssetsContext,
    prevDebouncedCustomerAssetsContext,
    prevDebouncedBasicCustomerInformation,
    debouncedBasicCustomerInformation,
    debouncedInputState,
    settings.currency,
    prevGrossSalary,
    prevDateOfBirth,
    prevTargetMontlyPension,
    prevDebouncedInputState,
    riskProfile,
    prevRiskProfile]);

  const handleResult = (result, setStateFunc) => {
    if (result === undefined || result.errors !== undefined) {
      if (result !== undefined && result.errors !== undefined)
        setErrorMessage(result.errors);
      else
        setErrorMessage("Oops... Something went wrong, please try again.");
      return;
    }
    else {
      setStateFunc(result);
      setErrorMessage(undefined);
    }
    setHasResults(true);
  }

  const prevRecommendedInvestment = usePrevious(recommendedInvestment.isin);

  useEffect(() => {
    if (prevRecommendedInvestment !== recommendedInvestment.isin && recommendedInvestment.isin !== '') {
      setShowResultTooltip(true);
      setTimeout(() => setShowResultTooltip(false), 3000);
    }
  }, [recommendedInvestment.isin, prevRecommendedInvestment])

  useEffect(() => {
    if (JSON.stringify(estimatedState) === JSON.stringify(defaultEstimatedState)) return;
    //TODO capital should not be here
    if (customerAssetsContext.estimateCapital) {
      customerAssetsContext.setPensionCapital(estimatedState.estimatedPensionCapital);
    }
    if (!targetMontlyPensionEntered) {
      retirementGoal.setTargetMontlyPension(estimatedState.defaultTargetPension);
    }
  }, [estimatedState]);

  function updateTargetPension(value) {
    retirementGoal.setTargetMontlyPension(value);
    if (estimatedState.defaultTargetPension !== undefined && Number(value) !== Number(estimatedState.defaultTargetPension)) {
      setTargetMontlyPensionEntered(true);
    }
  }

  resultState.rankedPortfolios[0].outcomes.sort(function (a, b) { return a - b });

  const badOutcome = resultState.rankedPortfolios[0].outcomes[0];
  const medianOutcome = resultState.rankedPortfolios[0].outcomes[1];
  const goodOutcome = resultState.rankedPortfolios[0].outcomes[2];

  const resultRef = useRef(null)
  const resultHeaderRef = useRef(null)

  const formInputs = <FormControl style={{ width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }} component="fieldset" autoComplete="off">
    <FormGroup>
      <TextField InputProps={{ inputComponent: NumberFormatCustom }} fullWidth={true} variant='filled' size='small' required label="Monthly Pension Goal" onChange={(e) => { updateTargetPension(e.target.value); }} value={retirementGoal.targetMontlyPension} />
      <Slider
        min={0}
        max={50000}
        value={retirementGoal.targetMontlyPension}
        onChange={(e, v) => { updateTargetPension(Number(v)); }}
        aria-labelledby="input-slider" />
      <TextField fullWidth={true} required label="Age of Retirement" type='number' variant='filled' size='small' onChange={(e) => { retirementGoal.setAgeOfRetirement(e.target.value); }} value={retirementGoal.ageOfRetirement} />
      <Slider
        min={63}
        max={69}
        value={retirementGoal.ageOfRetirement}
        onChange={(e, v) => { retirementGoal.setAgeOfRetirement(Number(v)); }}
        aria-labelledby="input-slider" />
    </FormGroup>
  </FormControl>;

  const results = <Paper square>
    <Grid ref={resultHeaderRef} container direction="column" alignItems='stretch' justify='center'>
      <Grid item>
        <LinearProgress style={{ width: "100%", visibility: numberOfRequestInFlight > 0 ? "visible" : "hidden" }} />
        <div className={classes.cardHeaderPadding} style={{ display: 'flex' }}>
          <div style={{ position: 'absolute', zIndex: 1, }}>
            <CustomTooltip open={showResultTooltip} placement="top" arrow title="New investment recommendation available!">
              <IconButton onClick={() => {
                scroller.scrollTo('resultSticky', {
                  duration: 800,
                  smooth: 'easeInOutQuad',
                  offset: -resultHeaderRef.current.offsetHeight,
                })
              }} style={{ padding: 0 }} color='inherit'>
                <AssessmentOutlined fontSize='large' />
              </IconButton>
            </CustomTooltip>
          </div>
          <div style={{ flexGrow: '1', textAlign: 'center', paddingTop: '0.4rem', paddingBottom: '0.5rem' }}>
            <Typography style={{ display: 'inline-block' }}>Monthly Deposit:</Typography> <Typography style={{ display: 'inline-block' }}><b>{resultState.requiredDeposit === 0 ? '-' : <TextNumberFormat disableRounding={true} value={Math.round(resultState.requiredDeposit)} />}</b></Typography>
          </div>
        </div>
      </Grid>
    </Grid>
    <Grid ref={resultRef} container className={classes.resultContainer} direction="column" alignItems='stretch' justify='center'>
      <Grid item>
        <Element name="resultSticky">
          <Collapse in={hasResults} timeout='auto'>
            {/* <Paper square> */}
            <Collapse in={recommendedInvestment.isin !== '' || resultState.isGoalAlreadyReached} timeout='auto'>
              <div style={{ textAlign: 'left', marginBottom: theme.spacing(1) }} className={classes.customTable}>
                {resultState.isGoalAlreadyReached ? (<Typography variant='h6'>Goal already reached!</Typography>) :
                  (
                    <>
                      <Grid container orientation="row">
                        <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Investment</Typography></Grid>
                        <Grid xs={7} item><Typography variant='body2'>{recommendedInvestment.name}</Typography></Grid>
                        <Grid xs={1} item style={{ textAlign: 'right' }}><Launch fontSize={'small'} /></Grid>
                        <Grid xs={12} item><Divider></Divider></Grid>
                        <Grid xs={4} item><Typography variant='body2' style={{ color: theme.palette.text.secondary }} >Account Type</Typography></Grid>
                        <Grid xs={8} item><Typography variant='body2'>{recommendedInvestment.taxationType}</Typography></Grid>

                      </Grid>
                    </>)}
              </div>
            </Collapse>
            <Grid container direction="row"
              justify="center"
              alignItems="baseline">
              <Grid item style={{ width: '100%', height: '100%', maxWidth: 400, textAlign: 'center' }}>
                <Typography color='textSecondary' variant='body2'>Value of Pension Funds</Typography>
                <PensionFanChart currentAge={41} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} />
              </Grid>
              <Grid item style={{ width: '100%', height: '100%', maxWidth: 400, textAlign: 'center' }}>
                <Typography color='textSecondary' variant='body2'>Pension Outcomes</Typography>
                <PensionBarChart badOutcome={badOutcome} medianOutcome={medianOutcome} goodOutcome={goodOutcome} colors={colors.pensionPots} />
              </Grid>
            </Grid>
            <PensionResultTable colors={colors.pensionPots} classes={classes} badOutcome={badOutcome} medianOutcome={medianOutcome} goodOutcome={goodOutcome} />
            {/* </Paper> */}
          </Collapse>
        </Element>
      </Grid>
    </Grid>
  </Paper>;


  return (
      <GoalAppShell title='Pension Goal' formView={formInputs} resultView={results} />
  );
}

export default RetirementGoal;
