import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, Grid } from '@material-ui/core';
import PensionAdviceAppBar from './PensionAdviceAppBar';
import { styleDelegate } from './useStyles';
import { SettingsContext } from './SettingsContext';


export function GoalAppShell(props) {
  const theme = useTheme();
  const classes = makeStyles(styleDelegate)();
  const settings = useContext(SettingsContext);
  const { errorMessage, setErrorMessage } = settings;

  const { title, formView, resultView } = props;
  return (
    <div className={classes.backgroundContainer}>
      <Grid container direction="column" justify="flex-start" alignItems='stretch' style={{ minHeight: '100vh' }}>
        <Grid item>
          <PensionAdviceAppBar title={title} />
        </Grid>
        <Grid item style={{ minHeight: '100%', maxWidth: '100%' }}>
          <Grid container spacing={0} className={classes.paperContainer} style={{ textAlign: 'left', paddingTop: theme.spacing(2) }} direction="row" alignItems='stretch' justify='center'>
            <Grid item xs={12} sm={5}>
              {formView}
            </Grid>
            <Grid item xs={12} sm={7}>
              {resultView}
            </Grid>
          </Grid>
        </Grid>
        <Snackbar style={{ whiteSpace: 'pre-wrap' }} open={errorMessage !== undefined} autoHideDuration={4000} onClose={() => setErrorMessage(undefined)} message={errorMessage}></Snackbar>
      </Grid>
    </div>
  );
}
