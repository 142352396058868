import React, { useState } from 'react';
import { Bar, CartesianGrid, Area, ResponsiveContainer, XAxis, YAxis, ComposedChart } from 'recharts';
import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from './appConstants';
import { useAppColors } from './useAppColors';
import { TextNumberFormat } from './NumberFormatCustom'
import moment from 'moment';

function chunk(a, l) {
    if (a.length == 0) return [];
    else return [a.slice(0, l)].concat(chunk(a.slice(l), l));
}

function IncomeBarChart(props) {

    const { horizon, isAnnual } = props;
    const colors = useAppColors();

    const xAxisLength = (Math.ceil(horizon / 10) * 10) * 12 + 1;

    let cashFlows = props.cashFlows;
    if (!(isAnnual === true)) {
        cashFlows = chunk(props.cashFlows, 12)
            .map(a => a.reduce((tot, current) => {
                return {
                    in: tot.in + current.in,
                    out: tot.out + current.out,
                    net: tot.net + current.net
                }
            }));
    }

    const chartData = cashFlows.slice(0, horizon).map((o, i) => {
        return {
            name: '' + (i),
            in: o.in,
            out: -o.out,
            net: o.net
        };
    });


    return (<div style={{ height: '10rem' }}>
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={chartData} margin={{
                top: 15, right: 25, left: 23, bottom: 10,
            }}>


                <defs>
                    <pattern id="pattern-stripe"
                        width="8" height="8"
                        patternUnits="userSpaceOnUse"
                        patternTransform="rotate(45)">
                        <rect width="4" height="8" transform="translate(0,0)" fill="black"></rect>
                    </pattern>
                    <linearGradient id="lineColorShortfall" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="5%" stopColor={"#000000"} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={"#000000"} stopOpacity={0.8} />
                    </linearGradient>
                    <linearGradient id="fanChartMedium" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.fanChart.medium} stopOpacity={1} />
                        <stop offset="95%" stopColor={colors.fanChart.medium} stopOpacity={0.6} />
                    </linearGradient>
                    <linearGradient id="colorShortfall" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors.spendingItems.interestPayment} stopOpacity={0.6} />
                        <stop offset="95%" stopColor={colors.spendingItems.interestPayment} stopOpacity={1} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeOpacity={0.3} vertical={false} />
                <XAxis tick={{ fontSize: 10 }} ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]} dataKey="year" />
                {/* <YAxis width={30} type="number" ticks={[0, investedCapital]} domain={[0, investedCapital]} tick={<CustomizedAxisTick />} /> */}
                <YAxis width={35} type="number" tick={<CustomizedAxisTick />} />
                {/* <Bar animationDuration={500} barSize={6} type="monotone" dataKey="in" strokeOpacity={0} fillOpacity={0} /> */}
                <Bar animationDuration={500} barSize={3} type="monotone" dataKey="in" strokeWidth={2} strokeOpacity={0.8} stroke={colors.fanChart.medium} fillOpacity={0.5} fill={"url(#fanChartMedium)"} />

                <Bar animationDuration={500} barSize={3} type="monotone" dataKey="out" strokeWidth={2} strokeOpacity={0.8} stroke={colors.spendingItems.interestPayment} fillOpacity={0.5} fill={"url(#colorShortfall)"} />
                <Area animationDuration={500} type="linear" dataKey="net" strokeOpacity={0.6} strokeDasharray="3 3" stroke={colors.fanChart.narrow} fillOpacity={0} fill="#00000000" />
            </ComposedChart>
        </ResponsiveContainer>
    </div>);
}

function CustomizedAxisTick(props) {
    const {
        x, y, stroke, payload,
    } = props;

    return (
        <>
            <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: 10 }}
                renderText={value =>
                    <g transform={`translate(${x},${y})`}>
                        <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: 10 }} fill="#666" >{value}</text>
                    </g>
                }
            />

        </>
    );
}

export function areEqual(prevProps, nextProps) {
    var prevProps2 = Object.assign({}, prevProps);
    delete prevProps2.targetLabel;

    var nextProps2 = Object.assign({}, nextProps);
    delete nextProps2.targetLabel;

    if (JSON.stringify(prevProps2) === JSON.stringify(nextProps2)) {

        return true;
    }
    return false;
}

export default React.memo(IncomeBarChart, areEqual);
