import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Slider, TextField, } from '@material-ui/core';
import { NumberFormatCustom, CurrencyFormatCustom } from './NumberFormatCustom';


export default function SliderInput(props) {
    const theme = useTheme();
    const { value, onChange, minValue, maxValue, title, showCurrency, size } = props;
    const actualSize = size === undefined ? 'small' : size;
    return (<>
      <TextField InputProps={{ inputComponent: showCurrency ? CurrencyFormatCustom : NumberFormatCustom }} fullWidth={true} required label={title} variant='filled' size={actualSize} onChange={(e) => { onChange(Number(e.target.value)); }} value={value} />
      <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
        <Slider
          min={minValue}
          max={maxValue}
          value={value}
          onChange={(e, v) => { onChange(Number(v)); }} />
      </div>
    </>);
  }
