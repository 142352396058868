import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { SettingsContext } from './SettingsContext';
import { createMuiTheme, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { getCurrencies, calculateTaxesAndReductions, rateRelativeSek } from './api_client/forecastClient';
import { getScenarioSets, setScenarioSet } from './api_client/tsClient';
import { calculateMonthlyInterestPayment } from './mortgageFunctions';
import Onboarding from './Onboarding';
import CustomerProfile from './CustomerProfile';
import RetirementGoal from './RetirementGoal';
import InvestmentSavingsGoal from './InvestmentSavingsGoal';
import GoalTracking from './GoalTracking';
import MortgagePlanning from './MortgagePlanning';
import LifeEvents from './LifeEvents';
import SimpleGraphDemo from './SimpleGraphDemo';
import PortfolioBuilder from './PortfolioBuilder';
import MultiGoalLifeEvents from './MultiGoalLifeEvents';
import { MortgagePlanningContext } from './MortgagePlanningContext';
import { InvestmentGoalContext } from './InvestmentGoalContext';
import { Fade, CssBaseline } from '@material-ui/core';
import { usePrevious } from './usePrevious';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { CustomerAssetsContext } from './CustomerAssetsContext';
import { RiskProfileContext } from './RiskProfileContext'
import { RetirementGoalContext } from './RetirementGoalContext'
import { styleDelegate } from './useStyles';
import { initDB, updateStore } from './db/dbFunctions'
import PortfolioComparisonBarChart from './PortfolioComparisonBarChart';


export const createTheme = (isLight) => createMuiTheme({
    typography: {
        fontFamily: "Roboto Condensed, Roboto, sans-serif"
    },
    palette: {
        type: isLight ? 'light' : 'dark',
        primary: {
            // main: isLight ? '#003f6d' : '#8d98aa',
            main: isLight ? '#003f6d' : '#60d3f6',
        },
        secondary: {
            main: isLight ? '#60d3f6' : '#ce0023'
        }
    },
});

const storeNames = {
    settings: "settings",
    basicCustomerInformation: "basicCustomerInformation",
    customerAssets: "customerAssets",
    riskProfile: "riskProfile",
    retirementGoal: "retirementGoal",
    mortgagePlanning: "mortgagePlanning",
    investmentGoal: "investmentGoal"
}

export const isLightDefault = true;

export function AppContainer(props) {

    const [theme, setTheme] = useState(createTheme(isLightDefault));
    const [settings, setSettings] = useState({
        locale: 'se',
        currency: 'SEK',
        availableCurrencies: ['SEK'],
        setCurrency: (currency) => {
            setSettings(state => ({ ...state, currency: currency }));
        },
        isLightTheme: isLightDefault,
        setTheme: (isLight) => {
            //setTheme(createTheme(isLight));
            setSettings(state => ({ ...state, isLightTheme: isLight }));
        },
        // currentFullScreenComponent: (<Onboarding />),
        // currentFullScreenComponent: (<CustomerProfile />),
        // currentFullScreenComponent: (<RetirementGoal />),
        // currentFullScreenComponent: (<InvestmentSavingsGoal />),
        // currentFullScreenComponent: (<GoalTracking />),
        currentFullScreenComponent: (<LifeEvents />),
        // currentFullScreenComponent: (<MortgagePlanning />),
        // currentFullScreenComponent: (<SimpleGraphDemo />),
        // currentFullScreenComponent: (<PortfolioBuilder />),
        // currentFullScreenComponent: (<GoalTracking />),
        // currentFullScreenComponent: (<MultiGoalLifeEvents />),
        previousFullScreenComponent: undefined,
        gotoToFullScreenComponent: (newComponent) => {
            setSettings(state => ({ ...state, previousFullScreenComponent: state.currentFullScreenComponent, currentFullScreenComponent: newComponent }));
        },
        gotoPreviousToFullScreenComponent: () => {
            setSettings(state => {
                if (state.previousFullScreenComponent)
                    return ({ ...state, previousFullScreenComponent: state.currentFullScreenComponent, currentFullScreenComponent: state.previousFullScreenComponent });
                else
                    return state;
            });
        },
        errorMessage: undefined,
        setErrorMessage: (errorMessage) => {
            setSettings(state => ({ ...state, errorMessage: errorMessage }));
        },
        rateRelativeSek: 1,
        currentScenarioSet: { name: 'Loading...', id: '' },
        setCurrentScenarioSet: (set) => {
            setSettings(state => ({ ...state, currentScenarioSet: set }));
        },
        availableScenarioSets: [{ name: 'Loading...', id: '' }]
    });

    useEffect(() => {
        setTheme(createTheme(settings.isLightTheme));
    }, [settings.isLightTheme]);

    useEffect(() => {
        getCurrencies().then(r => setSettings(state => ({ ...state, availableCurrencies: r })));
    }, []);

    useEffect(() => {
        getScenarioSets().then(r => {
            setSettings(state =>
                ({ ...state, currentScenarioSet: { name: r[0].name, id: r[0].scenarioSetId }, availableScenarioSets: r.map(s => ({ name: s.name, id: s.scenarioSetId })) }));
        }
        );
    }, []);

    useEffect(() => {
        rateRelativeSek(settings.currency).then(rateRelativeSek => setSettings(state => ({ ...state, rateRelativeSek: rateRelativeSek })))
    }, [settings.currency])

    const [basicCustomerInformation, setBasicCustomerInformation] = useState({
        grossSalary: '38000',
        setGrossSalary: (grossSalary) => {
            setBasicCustomerInformation(state => ({ ...state, grossSalary }))
        },
        monthlySpending: '17000',
        setMonthlySpending: (monthlySpending) => {
            setBasicCustomerInformation(state => ({ ...state, monthlySpending }))
        },
        dateOfBirth: '1978-09-27',
        setDateOfBirth: (dateOfBirth) => {
            setBasicCustomerInformation(state => ({ ...state, dateOfBirth }))
        },
        numberOfAdults: 1, // TODO, count number of BasicCustomerInformationContext (or something) instead
        setNumberOfAdults: (numberOfAdults) => {
            setBasicCustomerInformation(state => ({ ...state, numberOfAdults }))
        },
        numberOfChildren: 0, // TODO, count number of BasicCustomerInformationContext (or something) (children are also people...)
        setNumberOfChildren: (numberOfChildren) => {
            setBasicCustomerInformation(state => ({ ...state, numberOfChildren }))
        },
        municipalTax: 0,
        stateTax: 0,
        taxReduction: 0,
        netSalary: 0,
        ordinaryConsumption: 0,
        isValid: false,
    });



    useEffect(() => {
        if (Number(basicCustomerInformation.grossSalary) > 0 && Number(basicCustomerInformation.monthlySpending) > 0 && basicCustomerInformation.dateOfBirth.length === 10) {
            if (!basicCustomerInformation.isValid) {
                setBasicCustomerInformation(state => ({ ...state, isValid: true }))
            }
        }
        else {
            if (basicCustomerInformation.isValid) {
                setBasicCustomerInformation(state => ({ ...state, isValid: false }))
            }
        }
    }, [basicCustomerInformation, setBasicCustomerInformation]);

    useEffect(() => {
        setBasicCustomerInformation(state => ({ ...state, ordinaryConsumption: (basicCustomerInformation.numberOfAdults === 1 ? 9100 / settings.rateRelativeSek : 16100 / settings.rateRelativeSek) + (basicCustomerInformation.numberOfChildren) * 3500 / settings.rateRelativeSek }));
    }, [basicCustomerInformation.numberOfAdults, basicCustomerInformation.numberOfChildren, settings.rateRelativeSek])

    useEffect(() => {
        const getTaxesAndReductions = async () => (await calculateTaxesAndReductions(basicCustomerInformation.grossSalary, settings.currency));
        getTaxesAndReductions().then((result) => setBasicCustomerInformation(state => ({ ...state, municipalTax: result.municipalTax, stateTax: result.stateTax, taxReduction: result.jobbskatteavdrag, netSalary: result.netSalary })));
    }, [basicCustomerInformation.grossSalary, settings.currency])


    // TODO estimate capital call here
    const [customerAssets, setCustomerAssets] = useState({
        estimateCapital: true,
        setEstimateCapital: (estimateCapital) => { setCustomerAssets(state => ({ ...state, estimateCapital })) },
        oneTimeDeposit: '',
        setOneTimeDeposit: (oneTimeDeposit) => { setCustomerAssets(state => ({ ...state, oneTimeDeposit })) },
        pensionCapital: {
            nationalIncome: '',
            nationalPremium: '',
            occupational: ''
        },
        setPensionCapital: (pensionCapital) => { setCustomerAssets(state => ({ ...state, pensionCapital })) },
        existingPrivatePensionCapital: '',
        setExistingPrivatePensionCapital: (existingPrivatePensionCapital) => { setCustomerAssets(state => ({ ...state, existingPrivatePensionCapital })) },
        downPaymentFunds: 1000000,
        setDownPaymentFunds: (downPaymentFunds) => { setCustomerAssets(state => ({ ...state, downPaymentFunds })) },
        zeroRateMortgageAmount: 0,
        setZeroRateMortgageAmount: (zeroRateMortgageAmount) => { setCustomerAssets(state => ({ ...state, zeroRateMortgageAmount })) },
        valueOfResidence: 2000000,
        setValueOfResidence: (valueOfResidence) => { setCustomerAssets(state => ({ ...state, valueOfResidence })) },
        typeOfRealEstate: 'House',
        setTypeOfRealEstate: (typeOfRealEstate) => { setCustomerAssets(state => ({ ...state, typeOfRealEstate })) },
        mortgageAmount: 0,
        setMortgageAmount: (mortgageAmount) => { setCustomerAssets(state => ({ ...state, mortgageAmount, monthlyInterestPayment: calculateMonthlyInterestPayment(mortgageAmount, state.mortgageInterestRate / 100) })) },
        mortgageInterestRate: 1.58,
        setMortgageInterestRate: (mortgageInterestRate) => { setCustomerAssets(state => ({ ...state, mortgageInterestRate, monthlyInterestPayment: calculateMonthlyInterestPayment(state.mortgageAmount, mortgageInterestRate / 100) })) },
        monthlyInterestPayment: 0,
        investmentPortfolio: [],
        setInvestmentPortfolio: (investmentPortfolio) => { setCustomerAssets(state => ({ ...state, investmentPortfolio })) }
    });

    const [riskProfile, setRiskProfile] = useState({
        investmentRiskLevel: 'Medium',
        setInvestmentRiskLevel: (investmentRiskLevel) => { setRiskProfile(state => ({ ...state, investmentRiskLevel })) },
        returnRiskLevel: 'Medium',
        setReturnRiskLevel: (returnRiskLevel) => { setRiskProfile(state => ({ ...state, returnRiskLevel })) },
        lossRiskLevel: 'Medium',
        setLossRiskLevel: (lossRiskLevel) => { setRiskProfile(state => ({ ...state, lossRiskLevel })) },
        pensionSpecificRiskLevel: 'Medium',
        setPensionSpecificRiskLevel: (pensionSpecificRiskLevel) => { setRiskProfile(state => ({ ...state, pensionSpecificRiskLevel })) },
        isValid: false
    });



    useEffect(() => {
        var isValid = riskProfile.investmentRiskLevel && riskProfile.returnRiskLevel && riskProfile.lossRiskLevel && riskProfile.pensionSpecificRiskLevel;
        if (isValid && !riskProfile.isValid) {
            setRiskProfile(state => ({ ...state, isValid: true }))
        }
        if (!isValid && riskProfile.isValid) {
            setRiskProfile(state => ({ ...state, isValid: false }))
        }
    }, [riskProfile, setRiskProfile]);

    const [retirementGoal, setRetirementGoal] = useState({
        targetMontlyPension: '20000',
        setTargetMontlyPension: (targetMontlyPension) => { setRetirementGoal(state => ({ ...state, targetMontlyPension })) },
        ageOfRetirement: '65',
        setAgeOfRetirement: (ageOfRetirement) => { setRetirementGoal(state => ({ ...state, ageOfRetirement })) },
        isValid: false,
    });

    const [mortgagePlanning, setMortgagePlanning] = useState({
        monthlyMortgagePaymentAmount: 0,
        setMonthlyMortgagePaymentAmount: (monthlyMortgagePaymentAmount) => { setMortgagePlanning(state => ({ ...state, monthlyMortgagePaymentAmount })) },
        maintenanceCost: 0,
        isValid: false,
    });

    const [investmentGoal, setInvestmentGoal] = useState({
        monthlyInvestmentAmount: 0,
        setMonthlyInvestmentAmount: (monthlyInvestmentAmount) => { setInvestmentGoal(state => ({ ...state, monthlyInvestmentAmount })) },
        accountType: 'SweIsk',
        setAccountType: (accountType) => { setInvestmentGoal(state => ({ ...state, accountType })) },
        isaContributionStrategy: 'OnlyBondsAndSharesIsa',
        setIsaContributionStrategy: (isaContributionStrategy) => { setInvestmentGoal(state => ({ ...state, isaContributionStrategy })) },
    });

    useEffect(() => {
        setMortgagePlanning(state => ({ ...state, maintenanceCost: (customerAssets.typeOfRealEstate === 'House' ? 4000 : 3100) / settings.rateRelativeSek }));
    }, [customerAssets.typeOfRealEstate, settings.rateRelativeSek]);

    useEffect(() => {
        var isValid = Number(retirementGoal.ageOfRetirement) > 50 && Number(retirementGoal.targetMontlyPension) > 1000
        if (isValid && !retirementGoal.isValid) {
            setRetirementGoal(state => ({ ...state, isValid: true }))
        }
        if (!isValid && retirementGoal.isValid) {
            setRetirementGoal(state => ({ ...state, isValid: false }))
        }
    }, [retirementGoal, setRetirementGoal]);

    useEffect(() => {
        const storeConf = [
            {
                storeName: storeNames.settings,
                initialState: settings,
                setState: setSettings
            },
            {
                storeName: storeNames.basicCustomerInformation,
                initialState: basicCustomerInformation,
                setState: setBasicCustomerInformation
            },
            {
                storeName: storeNames.customerAssets,
                initialState: customerAssets,
                setState: setCustomerAssets
            },
            {
                storeName: storeNames.riskProfile,
                initialState: riskProfile,
                setState: setRiskProfile
            },
            {
                storeName: storeNames.retirementGoal,
                initialState: retirementGoal,
                setState: setRetirementGoal
            },
            {
                storeName: storeNames.mortgagePlanning,
                initialState: mortgagePlanning,
                setState: setMortgagePlanning
            },
            {
                storeName: storeNames.investmentGoal,
                initialState: investmentGoal,
                setState: setInvestmentGoal
            },
        ];
        initDB(storeConf);
    }, [settings, basicCustomerInformation, customerAssets, riskProfile, retirementGoal, mortgagePlanning, investmentGoal]);

    useEffect(() => {
        updateStore(storeNames.settings, settings);
    }, [settings]);

    useEffect(() => {
        updateStore(storeNames.basicCustomerInformation, basicCustomerInformation);
    }, [basicCustomerInformation]);

    useEffect(() => {
        updateStore(storeNames.customerAssets, customerAssets);
    }, [customerAssets]);

    useEffect(() => {
        updateStore(storeNames.riskProfile, riskProfile);
    }, [riskProfile]);

    useEffect(() => {
        updateStore(storeNames.retirementGoal, retirementGoal);
    }, [retirementGoal]);

    useEffect(() => {
        updateStore(storeNames.mortgagePlanning, mortgagePlanning);
    }, [mortgagePlanning]);

    useEffect(() => {
        updateStore(storeNames.investmentGoal, investmentGoal);
    }, [investmentGoal]);

    return (
        <SettingsContext.Provider value={settings}>
            <RetirementGoalContext.Provider value={retirementGoal}>
                <MortgagePlanningContext.Provider value={mortgagePlanning}>
                    <InvestmentGoalContext.Provider value={investmentGoal}>
                        <RiskProfileContext.Provider value={riskProfile}>
                            <CustomerAssetsContext.Provider value={customerAssets}>
                                <BasicCustomerInformationContext.Provider value={basicCustomerInformation}>
                                    <ThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <ComponentTransition currentFullScreenComponent={settings.currentFullScreenComponent} />
                                    </ThemeProvider>
                                </BasicCustomerInformationContext.Provider>
                            </CustomerAssetsContext.Provider>
                        </RiskProfileContext.Provider>
                    </InvestmentGoalContext.Provider>
                </MortgagePlanningContext.Provider>
            </RetirementGoalContext.Provider>
        </SettingsContext.Provider>);
}

function ComponentTransition(props) {
    const classes = makeStyles(styleDelegate)();

    const [firstComponent, setFirstComponent] = useState(<div></div>);
    const [secondComponent, setSecondComponent] = useState(<div></div>);
    const [isfirstComponentActive, setIsfirstComponentActive] = useState(true);
    const prevCurrentFullScreenComponent = usePrevious(props.currentFullScreenComponent);

    useEffect(() => {
        if (prevCurrentFullScreenComponent === props.currentFullScreenComponent) return;
        if (isfirstComponentActive)
            setSecondComponent(props.currentFullScreenComponent);
        else
            setFirstComponent(props.currentFullScreenComponent);
        setIsfirstComponentActive(!isfirstComponentActive);
    }, [props.currentFullScreenComponent, isfirstComponentActive, prevCurrentFullScreenComponent]);

    return (
        <div style={{ position: 'relative' }}>
            <Fade unmountOnExit in={isfirstComponentActive} >
                <div className={classes.stepperContent} children={firstComponent}>
                </div>
            </Fade>
            <Fade unmountOnExit in={!isfirstComponentActive} >
                <div className={classes.stepperContent} children={secondComponent}>
                </div>
            </Fade>
        </div>
    );
}
