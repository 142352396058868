import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { percentiles } from './../appConstants';


const namedFunds = {
    SE0005281847: "Global Equity Fund",
    SE0000810905: "Medium Risk Fund",
    SE0000810731: "Low Risk Fund"
}

const accountTypeMap = {
    SweIsk: "Investment Savings Account",
    SweEndowmentInsurance: "Endowment Insurance"
}

function getPortfolio(isin, uuid, taxationType) {
    return {
        "id": uuid,
        "investmentAllocations": [
            {
                "currencyCode": "SEK",
                "fees": {
                    "annualFee": 0.01,
                    "performanceFee": 0,
                    "purchaseFee": 0,
                    "salesFee": 0,
                    "transactionCost": 0
                },

                "weight": 1,
                "productCode": {
                    "codeType": "Isin",
                    "code": isin
                },
                "outperformanceAssumption": 0
            }
        ],
        "taxationType": taxationType,
        "strategy": "RebalanceToPlan"
    };
}

let baseUri = "https://kdbrk-or-api-dev-wa.azurewebsites.net/";

if (process.env.NODE_ENV === 'development') {
    baseUri = "https://localhost:5001/";
}
else if (window.location.hostname.startsWith("demoortestsa") || window.location.hostname.startsWith("demo.outrank.cloud")) {
    baseUri = "https://outrankproxyapp.azurewebsites.net/";
}

let currencyExchangRates = undefined;

async function downloadCurrencyExchangeRates() {
    if (currencyExchangRates !== undefined) return;

    const response = await fetch(baseUri + "api/usecase/MarketData/CurrencyExchangRates");
    const data = await getResponseContent(response);
    if (data.errors !== undefined) {
        currencyExchangRates = [{
            currency: "SEK",
            rateRelativeEuro: 10.8723,
            rateRelativeSek: 1
        }];
    }
    currencyExchangRates = data;
}

const filteredCurrencies = ['SEK', 'EUR', 'GBP', 'NOK', 'DKK', 'USD'];

export async function getCurrencies() {
    await downloadCurrencyExchangeRates();

    const rates = currencyExchangRates.filter(r => filteredCurrencies.includes(r.currency)).map(r => r.currency);
    rates.sort(function (a, b) {
        return filteredCurrencies.indexOf(a) - filteredCurrencies.indexOf(b);
    });
    return rates;
}

export async function rateRelativeSek(currency) {
    await downloadCurrencyExchangeRates();
    return currencyExchangRates.find(r => r.currency === currency).rateRelativeSek;
}


export async function getEstimations(grossSalary, dateOfBirth, currency) {

    const rate = await rateRelativeSek(currency);
    grossSalary = Math.round(rate * grossSalary);
    const scenarioSetId = await getActiveScenarioSet();
    const fund = await getDefaultFund(scenarioSetId);

    if (fund === undefined) return;
    const portfolio = getPortfolio(fund, uuidv4(), 'SweIsk');

    const forecastRequest = {
        "scenarioSetId": scenarioSetId,
        "asOfDate": moment(),
        "grossMonthlySalary": grossSalary,
        "dateOfBirth": dateOfBirth,
        "percentiles": percentiles,
        "newPrivatePensionPortfolio": portfolio
    };

    const response = await fetch(baseUri + `financialplanning/v1/pension/forecast/assumed-monthly-deposits`, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(forecastRequest) }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;


    const estimatedNetPensionCapital = {
        nationalIncome: Math.round(data.estimatedNetPensionCapital.nationalIncome / rate),
        nationalPremium: Math.round(data.estimatedNetPensionCapital.nationalPremium / rate),
        occupational: Math.round(data.estimatedNetPensionCapital.occupational / rate)
    };

    return { defaultTargetPension: Math.round(data.desiredPension.value / rate), estimatedPensionCapital: estimatedNetPensionCapital };
};

export async function getForecast(
    grossSalary,
    dateOfBirth,
    targetRetirementAge,
    targetMontlyPension,
    nationalIncomePensionCapital,
    nationalPremiumPensionCapital,
    occupationalPensionCapital,
    privatePensionCapital,
    oneTimeDeposit,
    currency) {

    const rate = await rateRelativeSek(currency);

    grossSalary = Math.round(rate * grossSalary);
    targetMontlyPension = Math.round(rate * targetMontlyPension);
    nationalIncomePensionCapital = Math.round(rate * nationalIncomePensionCapital);
    nationalPremiumPensionCapital = Math.round(rate * nationalPremiumPensionCapital);
    occupationalPensionCapital = Math.round(rate * occupationalPensionCapital);
    privatePensionCapital = Math.round(rate * privatePensionCapital);
    oneTimeDeposit = Math.round(rate * oneTimeDeposit);

    const scenarioSetId = await getActiveScenarioSet();
    const fund = await getDefaultFund(scenarioSetId);
    if (fund === undefined) return;
    const portfolio = getPortfolio(fund, uuidv4(), 'SweIsk');

    const forecastRequest = {
        "scenarioSetId": scenarioSetId,
        "asOfDate": moment(),
        "grossMonthlySalary": grossSalary,
        "dateOfBirth": dateOfBirth,
        "desiredRetirementAge": targetRetirementAge,
        "desiredTotalSalaryEquivalentMonthlyPensionPayment": targetMontlyPension,
        "currentGrossIncomePensionCapital": nationalIncomePensionCapital,
        "currentGrossPremiumPensionCapital": nationalPremiumPensionCapital,
        "currentGrossOccupationalPensionCapital": occupationalPensionCapital,
        "currentPrivatePensionCapital": privatePensionCapital,
        "oneTimeNewPensionDeposit": oneTimeDeposit,
        "percentiles": percentiles,
        "newPrivatePensionPortfolio": portfolio
    };
    const response = await fetch(baseUri + `financialplanning/v1/pension/forecast/desired-with-known-current`, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(forecastRequest) }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;

    return {
        isGoalAlreadyReached: false,
        requiredDeposit: Math.round(data.requiredMonthlyDeposit / rate),
        rankedPortfolios: [{
            outcomes: convertOutcomes(data.estimatedMonthlySalaryEquivalentPensionPaymentOutcomes, rate),
            recommendedInvestment: { isin: '', taxationType: '', name: '' },
            portfolioDevelopmentTimeSeries: data.portfolioDevelopmentTimeSeries,
            utilityScore: 0
        }]
    };
};

export async function getAdvice(
    grossSalary,
    dateOfBirth,
    targetRetirementAge,
    targetMonthlyPension,
    monthlySpending,
    nationalIncomePensionCapital,
    nationalPremiumPensionCapital,
    occupationalPensionCapital,
    privatePensionCapital,
    oneTimeDeposit,
    pensionInvestmentRiskLevel,
    investmentRiskLevel,
    returnRiskLevel,
    lossRiskLevel,
    currency,
    taxationTypes = ['SweIsk', 'SweEndowmentInsurance'],
    alternativeInvestmentDeposit = undefined) {

    const rate = await rateRelativeSek(currency);

    let monthlyDeposit = 0;

    if (alternativeInvestmentDeposit !== undefined) {
        monthlyDeposit = Math.floor(alternativeInvestmentDeposit * rate);
    } else {

        const monthlyDepositResponse = await getForecast(
            grossSalary,
            dateOfBirth,
            targetRetirementAge,
            targetMonthlyPension,
            nationalIncomePensionCapital,
            nationalPremiumPensionCapital,
            occupationalPensionCapital,
            privatePensionCapital,
            oneTimeDeposit,
            currency);


        if (!(investmentRiskLevel && returnRiskLevel && lossRiskLevel && pensionInvestmentRiskLevel && monthlySpending !== undefined)) {
            return monthlyDepositResponse;
        }

        monthlyDeposit = Math.floor(monthlyDepositResponse.requiredDeposit * rate);
        if (monthlyDeposit === 0) {
            monthlyDepositResponse.isGoalAlreadyReached = true;
            return monthlyDepositResponse;
        }

    }

    grossSalary = Math.round(rate * grossSalary);
    targetMonthlyPension = Math.round(rate * targetMonthlyPension);
    monthlySpending = Math.round(rate * monthlySpending);
    nationalIncomePensionCapital = Math.round(rate * nationalIncomePensionCapital);
    nationalPremiumPensionCapital = Math.round(rate * nationalPremiumPensionCapital);
    occupationalPensionCapital = Math.round(rate * occupationalPensionCapital);
    privatePensionCapital = Math.round(rate * privatePensionCapital);
    oneTimeDeposit = Math.round(rate * oneTimeDeposit);

    const scenarioSetId = await getActiveScenarioSet();

    const funds = await getAllFunds(scenarioSetId);
    if (funds === undefined) return;

    const isinGuid = taxationTypes.flatMap(taxationType => funds.map(i => ({ isin: i, taxationType: taxationType, guid: uuidv4() })));

    const portfolios = isinGuid.map(kvp => getPortfolio(kvp.isin, kvp.guid, kvp.taxationType));

    const monthlySavingsCapacity = Math.floor((await grossToNet(grossSalary)) - monthlySpending);

    const adviceRequest = {
        "scenarioSetId": scenarioSetId,
        "asOfDate": moment(),
        "grossMonthlySalary": grossSalary,
        "dateOfBirth": dateOfBirth,
        "desiredRetirementAge": targetRetirementAge,
        "currentGrossIncomePensionCapital": nationalIncomePensionCapital,
        "currentGrossPremiumPensionCapital": nationalPremiumPensionCapital,
        "currentGrossOccupationalPensionCapital": occupationalPensionCapital,
        "currentPrivatePensionCapital": privatePensionCapital,
        "oneTimeNewPensionDeposit": oneTimeDeposit,
        "monthlyDeposit": monthlyDeposit,
        "monthlySavingsCapacity": monthlySavingsCapacity,
        "percentiles": percentiles,
        "pensionInvestmentRiskLevel": pensionInvestmentRiskLevel,
        "investmentRiskLevel": investmentRiskLevel,
        "returnRiskLevel": returnRiskLevel,
        "lossRiskLevel": lossRiskLevel,
        "newPrivatePensionPortfolios": portfolios
    };
    const response = await fetch(baseUri + `financialplanning/v1/pension/swe/advice`, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(adviceRequest) }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;

    const utilities = data.rankedPortfolios.map(portfolio => portfolio.pensionForecast.utility);
    const maxUtility = Math.max(...utilities);
    const minUtility = Math.min(...utilities);

    var rangeRatio = 4 / (maxUtility - minUtility);


    const rankedPortfolios = data.rankedPortfolios.map(portfolio => {

        const isinMap = isinGuid.find(kvp => kvp.guid + '' === portfolio.portfolioId);
        // const investment = { isin: isinMap.isin, taxationType: accountTypeMap[isinMap.taxationType], name: namedFunds[isinMap.isin] }
        const investment = { isin: isinMap.isin, taxationType: accountTypeMap[isinMap.taxationType], name: namedFunds[isinMap.isin] }
        return ({
            outcomes: convertOutcomes(portfolio.pensionForecast.estimatedMonthlySalaryEquivalentPensionPaymentOutcomes, rate),
            recommendedInvestment: investment, //TODO this is a value defined and mapped in the app
            portfolioDevelopmentTimeSeries: portfolio.pensionForecast.portfolioDevelopmentTimeSeries,
            utilityScore: Math.round(5 - ((maxUtility - portfolio.pensionForecast.utility) * rangeRatio))
        });
    });

    return {
        isGoalAlreadyReached: false,
        requiredDeposit: Math.round(monthlyDeposit / rate),
        rankedPortfolios: rankedPortfolios
    };
}

function convertOutcomes(outcomes, rate) {
    return outcomes.map(o => {
        return {
            percentile: o.percentile,
            nationalPension: Math.round(o.averageOutcomes.nationalPension / rate),
            occupationalPension: Math.round(o.averageOutcomes.occupationalPension / rate),
            currentPrivatePension: Math.round(o.averageOutcomes.currentPrivatePension / rate),
            newPrivatePension: Math.round(o.averageOutcomes.newPrivatePension / rate)
        }
    });
}

async function getResponseContent(response) {
    const data = await response.json();
    if (response.status === 400) {
        if (data.Errors !== undefined) {
            return { errors: data.Errors.map(e => e.Message + '\n') };
        }
        else {
            return { errors: 'Oops... Something went wrong, please try again.' };
        }
    }
    if (data.code && data.code === 400) {
        return { errors: data.message };
    }
    return data;
}

export async function getQuestionValues(targetMonthlyPension, grossMonthlySalary, dateOfBirth, currency) {
    const rate = await rateRelativeSek(currency);
    grossMonthlySalary = Math.round(rate * grossMonthlySalary);
    targetMonthlyPension = Math.round(rate * targetMonthlyPension);
    var uri = `financialplanning/v1/risk/general-aversion-options?desiredTotalSalaryEquivalentMonthlyPensionPayment=${targetMonthlyPension}&grossMonthlySalary=${grossMonthlySalary}&dateOfBirth=${dateOfBirth}&asOfDate=${moment().toISOString()}`;
    const response = await fetch(baseUri + uri, { method: 'get', headers: { 'Content-Type': 'application/json' } }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;
    return convertQuestionValues(data, rate);
}

function convertQuestionValues(values, rate) {
    return {
        returnRisk: {
            investmentAmount: Math.round(values.returnRisk.investmentAmount / rate),
            low: {
                returnAmount: Math.round(values.returnRisk.low.returnAmount / rate)
            },
            medium: {
                badReturnAmount: Math.round(values.returnRisk.medium.badReturnAmount / rate),
                medianReturnAmount: Math.round(values.returnRisk.medium.medianReturnAmount / rate)
            },
            high: {
                badReturnAmount: Math.round(values.returnRisk.high.badReturnAmount / rate),
                medianReturnAmount: Math.round(values.returnRisk.high.medianReturnAmount / rate)
            }
        },
        lossRisk: {
            investmentAmount: Math.round(values.lossRisk.investmentAmount / rate),
            investmentAmountIn3Years: Math.round(values.lossRisk.investmentAmountIn3Years / rate),
            low: {
                drawdownAmount: Math.round(values.lossRisk.low.drawdownAmount / rate)
            },
            high: {
                drawdownAmount: Math.round(values.lossRisk.high.drawdownAmount / rate)
            }
        },
        pensionSpecificRisk: {
            low: {
                pensionAmount: Math.round(values.pensionSpecificRisk.low.pensionAmount / rate)
            },
            medium: {
                badPensionAmount: Math.round(values.pensionSpecificRisk.medium.badPensionAmount / rate),
                medianPensionAmount: Math.round(values.pensionSpecificRisk.medium.medianPensionAmount / rate)
            },
            high: {
                badPensionAmount: Math.round(values.pensionSpecificRisk.high.badPensionAmount / rate),
                medianPensionAmount: Math.round(values.pensionSpecificRisk.high.medianPensionAmount / rate)
            }
        }
    }
}

let allIsins = undefined;

export async function getDefaultFund(scenarioSetId) {
    const funds = await getAllFunds(scenarioSetId);
    if (funds.includes('SE0000810731')) return 'SE0000810731';
    return funds[0];
}

export async function getAllFunds(scenarioSetId) {
    if (allIsins !== undefined) return allIsins;
    var uri = `financialplanning/v1/product/mapped-funds?scenarioSetId=${scenarioSetId}`;
    const response = await fetch(baseUri + uri, { method: 'get', headers: { 'Content-Type': 'application/json' } }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;
    allIsins = data.filter(f => f.codeType === "Isin" && namedFunds[f.code] !== undefined).map(f => f.code); // TODO hack for demo
    if (allIsins.length === 0) allIsins = data.filter(f => f.codeType === "Isin").map(f => f.code);
    return allIsins;
}

export async function grossToNet(grossSalaryInSek) {
    var uri = `financialplanning/v1/income/swe/gross-to-net-salary?grossMonthlySalary=${Math.round(grossSalaryInSek)}&officialMunicipalityKey=0000`;
    const response = await fetch(baseUri + uri, { method: 'get', headers: { 'Content-Type': 'application/json' } }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;
    return data.netSalary;
}


export async function calculateTaxesAndReductions(grossSalary, currency) {
    const rate = await rateRelativeSek(currency);
    const resultInSek = await calculateTaxesAndReductionsWithoutConversion(grossSalary * rate);

    return { municipalTax: resultInSek.municipalTax / rate, stateTax:  resultInSek.stateTax / rate, jobbskatteavdrag: resultInSek.jobbskatteavdrag / rate, netSalary: resultInSek.netSalary / rate };
}

async function calculateTaxesAndReductionsWithoutConversion(grossSalaryInSek) {
    var uri = `financialplanning/v1/income/swe/gross-to-net-salary?grossMonthlySalary=${Math.round(grossSalaryInSek)}&officialMunicipalityKey=0000`;
    const response = await fetch(baseUri + uri, { method: 'get', headers: { 'Content-Type': 'application/json' } }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;
    return data;
}

let scenarioSetId = undefined;

export async function getActiveScenarioSet() {
    if (scenarioSetId !== undefined) return scenarioSetId;
    var uri = `financialplanning/v1/simulation/scenario-sets`;
    const response = await fetch(baseUri + uri, { method: 'get', headers: { 'Content-Type': 'application/json' } }).catch(handleError);
    const data = await getResponseContent(response);
    if (data.errors !== undefined) return data;
    scenarioSetId = data[0].scenarioSetId;
    return scenarioSetId;
}

var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: 'Oops... Something went wrong, please try again.'
    }));
};
