import React, { useState, useEffect, useContext } from 'react';
import { calculateTotalSpending } from './budgetFunctions'
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Collapse, Paper, Slider, Typography, DialogTitle, Dialog, FormControl, Grid, TextField, IconButton, InputLabel, Select, MenuItem, Fade, Button, Divider, Tooltip, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions } from '@material-ui/core';
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import { ArrowBack, Settings, WorkOff, Work } from '@material-ui/icons';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { styleDelegate } from './useStyles';
import InvestmentGoalFanChart from './InvestmentGoalFanChart';
import { InvestmentGoalContext } from './InvestmentGoalContext'
import { SettingsContext } from './SettingsContext';
import { BasicCustomerInformationContext } from './BasicCustomerInformationContext';
import { MortgagePlanningContext } from './MortgagePlanningContext';
import { CustomerAssetsContext } from './CustomerAssetsContext';
import { useDebounce } from './useDebounce';
import { GoalAppShell } from './GoalAppShell';
import SpendingSummary from './SpendingSummary';
import Retraining from './LifeEventComponents/Retraining';
import RetirementExample from './LifeEventComponents/RetirementExample';
import SicknessPension from './LifeEventComponents/SicknessPension';
import { createRequest, createBaselineRequest } from './LifeEventComponents/RetrainingRequestFactory';
import { createRetirementExampleRequest, } from './LifeEventComponents/RetirementExampleRequestFactory';
import { createSicknessPensionRequest } from './LifeEventComponents/SicknessPensionRequestFactory';
import { variableDepositFinancialPlanning, getDefaultInvestmentGoalPlanningResult, getIncomeTrajectory, callFinancialPlanning } from './api_client/tsClient';
import { InflationAdjustmentContract } from './api_client';
import IncomeBarChart from './IncomeBarChart';
import { TextNumberFormat, NumberFormatCustom, CurrencyFormatCustom } from './NumberFormatCustom';
import SliderInput from './SliderInput';
import GroupResultsChart from './GroupResultsChart';

moment.locale("se");

function LifeEvents() {

  const classes = makeStyles(styleDelegate)();
  const theme = useTheme();

  const settings = useContext(SettingsContext);
  const investmentGoal = useContext(InvestmentGoalContext);
  const basicCustomerInformation = useContext(BasicCustomerInformationContext);
  const mortgagePlanning = useContext(MortgagePlanningContext);
  const customerAssets = useContext(CustomerAssetsContext);


  const [resultState, setResultState] = useState({ forecast: getDefaultInvestmentGoalPlanningResult(), referenceForecast: undefined });

  const portfolioDevelopmentTimeSeries = resultState.forecast.financialPlanningResponseModel.percentileForecast;
  const referencePortfolioDevelopmentTimeSeries = resultState.referenceForecast === undefined ? undefined : resultState.referenceForecast.financialPlanningResponseModel.percentileForecast;

  const [numberOfRequestInFlight, setNumberOfRequestInFlight] = useState(0);

  const { setErrorMessage, currentScenarioSet } = settings;

  const [monthlySpending, setMonthlySpending] = useState(0);

  const [horizonInYears, setHorizonInYears] = useState(30);
  const [initialContribution, setInitialContribution] = useState(0);
  const [riskLevel, setRiskLevel] = useState(3);

  const [typeOfEvent, setTypeOfEvent] = useState('sicknessPension');

  const debouncedHorizonInYears = useDebounce(horizonInYears, 800);
  const debouncedInitialContribution = useDebounce(initialContribution, 800);
  const debouncedMonthlyContributions = useDebounce(investmentGoal.monthlyInvestmentAmount, 800);
  const debouncedRiskLevel = useDebounce(riskLevel, 800);

  const [incomeTrajectory, setIncomeTrajectory] = useState([{ dateOfPayment: moment().toDate(), amount: 0 }]);

  const [sickLeave, setSickLeave] = useState({ sickLeaveStartMonth: 100, sickLeaveEndMonth: 140, rateOfAbsence: 1 });
  const [retraining, setRetraining] = useState({ startMonth: 24, endMonth: 36, rateOfAbsence: 1, expectedPayRaise: 0.2, annualTuitionFee: 50000 });
  const [retirement, setRetirement] = useState({ riskRatio: 0.75, annualSpending: 1500000 });
  const [sicknessPension, setSicknessPension] = useState({ monthlySpending: 18000, housingInvestment: 1500000, bufferAmount: 300000, includeValueOfRealEstate: false });

  const debouncedSickLeave = useDebounce(sickLeave, 800);
  const debouncedRetraining = useDebounce(retraining, 800);
  const debouncedRetirement = useDebounce(retirement, 800);
  const debouncedSicknessPension = useDebounce(sicknessPension, 800);

  const [occupationalInsuranceType, setOccupationalInsuranceType] = useState("NoInsurance");
  const [privateInsuranceType, setPrivateInsuranceType] = useState("NoInsurance");
  const [privateInsuranceOneTimeAmount, setPrivateInsuranceOneTimeAmount] = useState(200000);
  const debouncedPrivateInsuranceOneTimeAmount = useDebounce(privateInsuranceOneTimeAmount);
  const [privateInsuranceOneTimeAmountOpen, setPrivateInsuranceOneTimeAmountOpen] = useState(false);

  useEffect(() => {
    setMonthlySpending(calculateTotalSpending(mortgagePlanning, basicCustomerInformation, customerAssets));
    if (typeOfEvent !== 'retraining') {
      setNumberOfRequestInFlight(state => state + 1);
      getIncomeTrajectory(basicCustomerInformation.grossSalary, new Date(basicCustomerInformation.dateOfBirth), debouncedSickLeave.sickLeaveStartMonth, debouncedSickLeave.sickLeaveEndMonth, debouncedSickLeave.rateOfAbsence, calculateTotalSpending(mortgagePlanning, basicCustomerInformation, customerAssets), occupationalInsuranceType)
        .then(result => {
          try {
            handleResult(result, setIncomeTrajectory, setErrorMessage);
          } finally {
            setNumberOfRequestInFlight(state => state - 1);
          }
        });
    }
  }, [basicCustomerInformation, mortgagePlanning, customerAssets, debouncedSickLeave, occupationalInsuranceType, setErrorMessage, typeOfEvent])


  useEffect(() => {
    if (currentScenarioSet.id === '') return;


    if (typeOfEvent === 'sickleave' && incomeTrajectory[0].dateOfPayment !== undefined) {
      setNumberOfRequestInFlight(state => state + 1);

      var privateInsuranceCompensation = {
        dateOfPayment: moment().add(debouncedSickLeave.sickLeaveStartMonth, 'months').toDate(),
        amount: privateInsuranceType === 'SicknessAccidentInsurance' ? debouncedPrivateInsuranceOneTimeAmount : 0
      };

      var contributions = incomeTrajectory.map(t => ({
        dateOfPayment: t.dateOfPayment,
        amount: t.net
      })
      ).concat([privateInsuranceCompensation]);
      console.log(contributions);

      variableDepositFinancialPlanning(currentScenarioSet.id,
        debouncedHorizonInYears,
        debouncedInitialContribution,
        contributions,
        debouncedRiskLevel,
        investmentGoal.accountType,
        investmentGoal.isaContributionStrategy,
        basicCustomerInformation.grossSalary,
        'NoAdjustment')
        .then(result => {
          try {
            handleResult({ forecast: result, referenceForecast: undefined }, setResultState, setErrorMessage);
          } finally {
            setNumberOfRequestInFlight(state => state - 1);
          }
        });

    }
  }, [
    debouncedHorizonInYears,
    debouncedRiskLevel,
    investmentGoal.accountType,
    basicCustomerInformation.grossSalary,
    privateInsuranceType,
    setErrorMessage,
    currentScenarioSet,
    monthlySpending,
    retraining,
    debouncedInitialContribution,
    debouncedMonthlyContributions,
    investmentGoal.isaContributionStrategy,
    incomeTrajectory,
    debouncedSickLeave.sickLeaveStartMonth,
    debouncedPrivateInsuranceOneTimeAmount,
    typeOfEvent
  ]);

  useEffect(() => {
    if (currentScenarioSet.id === '') return;

    if (typeOfEvent === 'retraining') {
      setNumberOfRequestInFlight(state => state + 1);

      const request = createRequest(
        currentScenarioSet.id,
        debouncedRiskLevel,
        investmentGoal.accountType,
        basicCustomerInformation.grossSalary,
        monthlySpending,
        debouncedInitialContribution,
        debouncedRetraining,
        InflationAdjustmentContract.ValueAsOfStartDate
      );

      const baselineRequest = createBaselineRequest(
        currentScenarioSet.id,
        debouncedRiskLevel,
        investmentGoal.accountType,
        basicCustomerInformation.grossSalary,
        monthlySpending,
        debouncedInitialContribution,
        InflationAdjustmentContract.ValueAsOfStartDate
      );

      const baseLineCall = callFinancialPlanning(currentScenarioSet.id,
        debouncedHorizonInYears,
        baselineRequest);

      const retrainingCall = callFinancialPlanning(currentScenarioSet.id,
        debouncedHorizonInYears,
        request);
      Promise.all([retrainingCall, baseLineCall])
        .then(results => {
          const result = results[0];
          const referenceResult = results[1];
          try {
            setIncomeTrajectory(result.financialPlanningResponseModel.cashFlows[0].averageCashFlows.map(cf =>
            ({
              in: cf.in,
              out: cf.out,
              net: cf.net
            })))
            handleResult({ forecast: result, referenceForecast: referenceResult }, setResultState, setErrorMessage);
          } finally {
            setNumberOfRequestInFlight(state => state - 1);
          }
        });
    }
  }, [
    debouncedHorizonInYears,
    debouncedRiskLevel,
    investmentGoal.accountType,
    basicCustomerInformation.grossSalary,
    privateInsuranceType,
    setErrorMessage,
    currentScenarioSet,
    monthlySpending,
    debouncedRetraining,
    debouncedInitialContribution,
    debouncedMonthlyContributions,
    investmentGoal.isaContributionStrategy,
    debouncedSickLeave.sickLeaveStartMonth,
    debouncedPrivateInsuranceOneTimeAmount,
    privateInsuranceType,
    typeOfEvent
  ]);

  useEffect(() => {
    if (currentScenarioSet.id === '') return;

    if (typeOfEvent === 'retirement') {
      setNumberOfRequestInFlight(state => state + 1);

      const currentAccount = uuidv4();

      const request = createRetirementExampleRequest(
        currentAccount,
        currentScenarioSet.id,
        debouncedRetirement.annualSpending,
        debouncedRetirement.riskRatio
      );

      const baselineRequest = createRetirementExampleRequest(
        currentAccount,
        currentScenarioSet.id,
        1500000,
        0.75
      );

      const baseLineCall = callFinancialPlanning(currentScenarioSet.id,
        debouncedHorizonInYears,
        baselineRequest);

      const retrainingCall = callFinancialPlanning(currentScenarioSet.id,
        debouncedHorizonInYears,
        request);
      const groupNames = ["Available Funds", "House", "Vacation Home"]

      Promise.all([retrainingCall, baseLineCall])
        .then(results => {
          const result = results[0];
          const referenceResult = results[1];
          try {
            setIncomeTrajectory(result.financialPlanningResponseModel.cashFlows.find(cf => cf.assetsAndDebtsItemId == currentAccount).averageCashFlows.map(cf =>
            ({
              in: cf.in,
              out: cf.out,
              net: cf.net
            })))
            handleResult({
              forecast: result,
              referenceForecast: referenceResult,
              groupResults: result.financialPlanningResponseModel.groupResults.map((gr, i) => ({ ...gr, name: groupNames[i] }))
            }, setResultState, setErrorMessage);
          } finally {
            setNumberOfRequestInFlight(state => state - 1);
          }
        });
    }
  }, [
    debouncedHorizonInYears,
    debouncedRiskLevel,
    investmentGoal.accountType,
    basicCustomerInformation.grossSalary,
    privateInsuranceType,
    setErrorMessage,
    currentScenarioSet,
    monthlySpending,
    debouncedRetraining,
    debouncedInitialContribution,
    debouncedMonthlyContributions,
    investmentGoal.isaContributionStrategy,
    debouncedSickLeave.sickLeaveStartMonth,
    debouncedPrivateInsuranceOneTimeAmount,
    privateInsuranceType,
    typeOfEvent,
    debouncedRetirement
  ]);

  useEffect(() => {
    if (currentScenarioSet.id === '') return;

    if (typeOfEvent === 'sicknessPension') {
      setNumberOfRequestInFlight(state => state + 1);

      const currentAccount = uuidv4();

      const request = createSicknessPensionRequest(
        currentAccount,
        currentScenarioSet.id,
        debouncedSicknessPension.monthlySpending,
        debouncedSicknessPension.bufferAmount,
        debouncedSicknessPension.housingInvestment,
        debouncedSicknessPension.includeValueOfRealEstate
      );

      const baselineRequest = createSicknessPensionRequest(
        currentAccount,
        currentScenarioSet.id,
        18000,
        300000,
        1500000,
        debouncedSicknessPension.includeValueOfRealEstate
      );

      const baseLineCall = callFinancialPlanning(currentScenarioSet.id,
        debouncedHorizonInYears,
        baselineRequest);

      const retrainingCall = callFinancialPlanning(currentScenarioSet.id,
        debouncedHorizonInYears,
        request);
      const groupNames = ["Available Funds", "Home"]

      Promise.all([retrainingCall, baseLineCall])
        .then(results => {
          const result = results[0];
          const referenceResult = results[1];
          try {
            setIncomeTrajectory(result.financialPlanningResponseModel.cashFlows.find(cf => cf.assetsAndDebtsItemId == currentAccount).averageCashFlows.map(cf =>
            ({
              in: cf.in,
              out: cf.out,
              net: cf.net
            })))
            handleResult({
              forecast: result,
              referenceForecast: referenceResult,
              groupResults: result.financialPlanningResponseModel.groupResults.map((gr, i) => ({ ...gr, name: groupNames[i] }))
            }, setResultState, setErrorMessage);
          } finally {
            setNumberOfRequestInFlight(state => state - 1);
          }
        });
    }
  }, [
    debouncedHorizonInYears,
    debouncedRiskLevel,
    investmentGoal.accountType,
    basicCustomerInformation.grossSalary,
    privateInsuranceType,
    setErrorMessage,
    currentScenarioSet,
    monthlySpending,
    debouncedRetraining,
    debouncedInitialContribution,
    debouncedMonthlyContributions,
    investmentGoal.isaContributionStrategy,
    debouncedSickLeave.sickLeaveStartMonth,
    debouncedPrivateInsuranceOneTimeAmount,
    privateInsuranceType,
    typeOfEvent,
    debouncedSicknessPension
  ]);


  const marks = [
    {
      value: 0,
      label: 'No risk',
    },
    {
      value: 1,
      label: 'Low',
    },
    {
      value: 2,
      label: 'Low-medium',
    },
    {
      value: 3,
      label: 'Medium',
    },
    {
      value: 4,
      label: 'Medium-high',
    },
    {
      value: 5,
      label: 'High',
    },
  ];


  const [isaContributionStrategiesOpen, setIsaContributionStrategiesOpen] = React.useState(false);

  const handleClickOpenAccountTypeDialog = () => {
    setIsaContributionStrategiesOpen(true);
  };

  const handleClose = () => {
    setIsaContributionStrategiesOpen(false);
  };

  const isaContributionStrategies = [
    { value: 'OnlyBondsAndSharesIsa', text: 'Only bonds and shares ISA' },
    { value: 'PreferLifetimeIsa', text: 'Prefer lifetime ISA' }
  ];

  const sickLeaveMarks = [
    {
      value: 0,
      label: 'Now',
    },
    {
      value: 120,
      label: '10yrs',
    },
    {
      value: 240,
      label: '20yrs',
    },
    {
      value: 360,
      label: '30yrs',
    },
  ];

  const rateOfAbsenceMarks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 0.25,
      label: '25%',
    },
    {
      value: 0.5,
      label: '50%',
    },
    {
      value: 0.75,
      label: '75%',
    },
    {
      value: 1,
      label: '100%',
    },
  ];



  const zoomMarks = [
    {
      value: 10,
      label: '10yrs',
    },
    {
      value: 20,
      label: '20yrs',
    },
    {
      value: 30,
      label: '30yrs',
    },
    {
      value: 40,
      label: '40yrs',
    }
  ];



  const sickLeaveInputs = () => (<div>
    <Typography id="sickLeaveLabel">
      Sick Leave Period
  </Typography>
    <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
      <Slider
        style={{ marginTop: theme.spacing(2) }}
        value={[sickLeave.sickLeaveStartMonth, sickLeave.sickLeaveEndMonth]}
        onChange={(event, newValue) => { setSickLeave(state => ({ ...state, sickLeaveStartMonth: newValue[0], sickLeaveEndMonth: newValue[1] })) }}
        valueLabelDisplay="auto"
        aria-labelledby="sickLeaveLabel"
        ValueLabelComponent={ValueLabelComponent}
        max={360}
        min={0}
        marks={sickLeaveMarks}
      />
    </div>
    <Typography id="absenceLabel">
      Rate of Absence
  </Typography>
    <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
      <Slider
        // style={{ marginTop: theme.spacing(3) }}
        value={sickLeave.rateOfAbsence}
        onChange={(event, newValue) => { setSickLeave(state => ({ ...state, rateOfAbsence: newValue })) }}
        // valueLabelDisplay="auto"
        aria-labelledby="absenceLabel"
        // ValueLabelComponent={ValueLabelComponent}
        step={0.25}
        max={1}
        min={0}
        marks={rateOfAbsenceMarks}
      />
    </div>

    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: '1' }}>
        <FormControl style={{ width: '100%', paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
          <InputLabel style={{ width: '100%', paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }} id="occupational-insurance-label">Occupational Insurance</InputLabel>
          <Select
            labelId="occupational-insurance-label"
            // id="account-type-select"
            variant='filled'
            margin='dense'
            value={occupationalInsuranceType}
            onChange={(event) => { setOccupationalInsuranceType(event.target.value) }}>
            <MenuItem value={'NoInsurance'}>No Insurance</MenuItem>
            <MenuItem value={'StandardCollectiveAgreement'}>Standard Collective Agreement</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginLeft: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
        <IconButton disabled={true} onClick={() => { }}><Settings /></IconButton>
        {/* <ContributionStrategyDialog title='ISA Contribution Strategy' values={isaContributionStrategies} setSelectedValue={investmentGoal.setIsaContributionStrategy} selectedValue={investmentGoal.isaContributionStrategy} open={isaContributionStrategiesOpen} onClose={handleClose} /> */}
      </div>
    </div>

    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: '1' }}>
        <FormControl style={{ width: '100%', paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
          <InputLabel style={{ width: '100%', paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }} id="private-insurance-label">Private Insurance</InputLabel>
          <Select
            labelId="private-insurance-label"
            // id="account-type-select"
            variant='filled'
            margin='dense'
            value={privateInsuranceType}
            onChange={(event) => { setPrivateInsuranceType(event.target.value) }}>
            <MenuItem value={'NoInsurance'}>No Insurance</MenuItem>
            <MenuItem value={'SicknessAccidentInsurance'}>Sickness/Accident Insurance</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginLeft: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
        <IconButton disabled={privateInsuranceType !== 'SicknessAccidentInsurance'} onClick={() => setPrivateInsuranceOneTimeAmountOpen(true)}><Settings /></IconButton>
        <PrivateInsuranceDialog title='Sickness/Accident Insurance' setValue={setPrivateInsuranceOneTimeAmount} value={privateInsuranceOneTimeAmount} open={privateInsuranceOneTimeAmountOpen} onClose={() => setPrivateInsuranceOneTimeAmountOpen(false)} />
      </div>
    </div>
  </div>);


  const formInputs = <div>
    <FormControl style={{ width: '100%', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
      <div style={{ flexGrow: '1' }}>
        <FormControl style={{ width: '100%', paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
          <InputLabel style={{ width: '100%', paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }} id="occupational-insurance-label">Type of Event</InputLabel>
          <Select
            labelId="occupational-insurance-label"
            // id="account-type-select"
            variant='filled'
            margin='dense'
            value={typeOfEvent}
            onChange={(event) => { setTypeOfEvent(event.target.value) }}>
            <MenuItem value={'sickleave'}>Sick Leave</MenuItem>
            <MenuItem value={'retraining'}>Prepare for new Career</MenuItem>
            <MenuItem value={'retirement'}>Retirement Example</MenuItem>
            <MenuItem value={'sicknessPension'}>Sickness Pension</MenuItem>
          </Select>
        </FormControl>
      </div>
      {typeOfEvent === 'sickleave' ? sickLeaveInputs() 
        : typeOfEvent === 'retraining' ? <Retraining retraining={retraining} setRetraining={setRetraining} />
        : typeOfEvent === 'sicknessPension' ? <SicknessPension sicknessPension={sicknessPension} setSicknessPension={setSicknessPension} />
        : <RetirementExample retirement={retirement} setRetirement={setRetirement} />}
      {typeOfEvent !== 'retirement' && typeOfEvent !== 'sicknessPension' ?
        <><Typography style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
          Savings Investment Plan
        </Typography>
          <SliderInput value={initialContribution} showCurrency onChange={setInitialContribution} minValue={0} maxValue={1000000} title={'Initial Savings Buffer'} />
          <Typography id="risklevel">
            Risk Level
        </Typography>
          <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            <Slider
              classes={{ markLabel: classes.smallText }}
              min={0}
              max={5}
              value={riskLevel}
              onChange={(e, v) => { setRiskLevel(Number(v)); }}
              marks={marks}
              aria-labelledby="risklevel"
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: '1' }}>
              <FormControl style={{ width: '100%', paddingBottom: theme.spacing(2) }} component="fieldset" autoComplete="off">
                <InputLabel style={{ width: '100%', paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }} id="account-type-label">Account Type</InputLabel>
                <Select
                  labelId="account-type-label"
                  id="account-type-select"
                  variant='filled'
                  margin='dense'
                  value={investmentGoal.accountType}
                  onChange={(event) => { investmentGoal.setAccountType(event.target.value) }}>
                  <MenuItem value={'SweIsk'}>ISK (Sweden)</MenuItem>
                  <MenuItem value={'SweEndowmentInsurance'}>Endowment insurance (Sweden)</MenuItem>
                  <MenuItem value={'GbrIsa'}>Isa (UK)</MenuItem>
                  <MenuItem value={'GbrSipp'}>Sipp (UK)</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ marginLeft: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
              <IconButton disabled={investmentGoal.accountType !== 'GbrIsa'} onClick={handleClickOpenAccountTypeDialog}><Settings /></IconButton>
              <ContributionStrategyDialog title='ISA Contribution Strategy' values={isaContributionStrategies} setSelectedValue={investmentGoal.setIsaContributionStrategy} selectedValue={investmentGoal.isaContributionStrategy} open={isaContributionStrategiesOpen} onClose={handleClose} />
            </div>
          </div></> : <></>}
    </FormControl>

  </div >;



  // const chartLabel = <Paper>{'Time frame ' + horizonInYears + 'yrs, target ' + targetAmount}</Paper>;

  const results = <div style={{ position: 'relative', minHeight: '70vh' }}>
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions === 0} className={classes.stepperContent}> */}
    <Collapse in={typeOfEvent != 'retirement' && typeOfEvent != 'sicknessPension' && debouncedInitialContribution + debouncedMonthlyContributions === 0} >
      <Grid container direction="row" justify="center" alignItems="baseline">
        <Grid item style={{ width: '100%', height: '100%', minHeight: theme.spacing(7), textAlign: 'center' }}>
          <Typography style={{ paddingTop: theme.spacing(2) }} variant='body1'>Enter an initial or a monthly contribution to get started!</Typography>
        </Grid>
      </Grid>
    </Collapse>
    {/* </Fade> */}
    {/* <Fade in={debouncedInitialContribution + debouncedMonthlyContributions > 0} timeout={2000} className={classes.stepperContent}> */}
    <Collapse in={typeOfEvent === 'retirement' || typeOfEvent === 'sicknessPension' || debouncedInitialContribution + debouncedMonthlyContributions > 0}>
      <Paper square>
        <Grid container direction="column" alignItems='stretch' justify='center'>
          <Grid item>
            <LinearProgress style={{ width: "100%", visibility: numberOfRequestInFlight > 0 ? "visible" : "hidden" }} />
          </Grid>
        </Grid>
        <Grid container className={classes.resultContainer} direction="column" alignItems='stretch' justify='center'>
          <Grid item>
            <Grid container direction="row"
              // justify="center"
              alignItems="baseline">
              <Grid item style={{ paddingTop: theme.spacing(1), width: '100%', height: '100%', textAlign: 'left' }}>
                <Typography style={{ paddingLeft: theme.spacing(2) }} color='textPrimary' display="inline">Plan Summary</Typography>
                <Fade in={portfolioDevelopmentTimeSeries.length > 1}>
                  <div>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
                    <div style={{ paddingBottom: theme.spacing(1), paddingLeft: theme.spacing(2) }}>
                      <Typography color='textSecondary' variant='body2'>Income Over Time</Typography>
                      <IncomeBarChart cashFlows={incomeTrajectory} isAnnual={typeOfEvent === 'sickleave' ? false : true} horizon={horizonInYears} />
                      <Typography color='textSecondary' variant='body2'>Value Over Time ({settings.currency}/year)</Typography>
                      <InvestmentGoalFanChart targetAmount={0} horizon={horizonInYears} currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} referencePortfolioDevelopmentTimeSeries={referencePortfolioDevelopmentTimeSeries} />
                      <GroupResultsChart groupResults={resultState.groupResults} horizon={horizonInYears} />
                      <div style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
                        <Slider
                          classes={{ markLabel: classes.smallText }}
                          min={10}
                          max={40}
                          step={10}
                          value={horizonInYears}
                          onChange={(e, v) => { setHorizonInYears(Number(v)); }}
                          marks={zoomMarks}
                          aria-labelledby="risklevel"
                        />
                      </div>
                      {/* {typeOfEvent != 'retirement' && typeOfEvent != 'sicknessPension' ?
                        <><Typography color='textSecondary' variant='body2'>Budget Breakdown</Typography>
                          <SpendingSummary variant='minimal' /></> : <></>} */}
                    </div>
                  </div>
                </Fade>
              </Grid>
              <Grid item style={{ width: '100%', height: '100%', maxWidth: 400, textAlign: 'center' }}>
              </Grid>
            </Grid>
          </Grid>
        </Grid >
      </Paper >
    </Collapse>
    {/* </Fade> */}
  </div>;


  return (
    <div>
      {/* <PensionFanChart currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} /> */}
      <GoalAppShell title='Life Events' formView={formInputs} resultView={results} />
    </div>
  );
}

export default LifeEvents;

function ValueLabelComponent(props) {
  const { children, open } = props;

  return (
    <ValueLabel
      open={open}
      enterTouchDelay={0}
      placement="top"
      value={props.index === 0 ? <WorkOff fontSize='inherit' /> : <Work fontSize='inherit' />}

    >
      {children}
    </ValueLabel>

  );
}

const handleResult = (result, setStateFunc, setErrorMessage) => {
  if (result instanceof Response) {
    if (result !== undefined && result.errors !== undefined)
      setErrorMessage(result.errors);
    else
      result.text().then(text => setErrorMessage(text));
    return;
  }
  else {
    setStateFunc(result);
    // setErrorMessage(undefined);
  }
}

const RequiredInput = (props) => {
  const theme = useTheme();
  const classes = makeStyles(styleDelegate)();

  const { requiredEnabled, title, requiredContribution, contribution, setContribution, maxValue } = props;
  return (<div>
    <div style={{ float: 'left', width: '55%' }}>
      <SliderInput value={contribution} showCurrency onChange={setContribution} minValue={0} maxValue={maxValue} title={title} />
    </div>
    <div style={{ float: 'right', width: '40%' }}>
      <Typography style={{ textAlign: 'left' }} className={classes.smallText} variant='body2' color='textSecondary'>Required Amount</Typography>
      <Button onClick={() => { setContribution(requiredContribution); }} size='small' style={{ minWidth: theme.spacing(14), textAlign: 'left', justifyContent: 'left' }} variant={requiredEnabled ? "contained" : "outlined"} color='primary' disabled={!requiredEnabled}>
        <ArrowBack fontSize="small" style={{ visibility: requiredEnabled ? 'visible' : 'hidden', marginRight: theme.spacing(1), marginBottom: 1 }} />
        {requiredEnabled ? <TextNumberFormat roundToNearest={1} value={requiredContribution} /> : ''}

      </Button>
    </div>
  </div>);
};


function ContributionStrategyDialog(props) {
  const theme = useTheme();


  const { onClose, setSelectedValue, selectedValue, open, values, title } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog style={{ padding: theme.spacing(2) }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <RadioGroup style={{ padding: theme.spacing(2) }} aria-label="gender" name="gender1" value={selectedValue} onChange={(event) => setSelectedValue(event.target.value)}>
        {values.map((value) => (
          <FormControlLabel key={value.value} value={value.value} control={<Radio />} label={value.text} />
        ))}
      </RadioGroup>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}

function PrivateInsuranceDialog(props) {
  const theme = useTheme();


  const { onClose, setValue, value, open, title } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog style={{ padding: theme.spacing(2) }} onClose={handleClose} aria-labelledby="one-time-compensation-label" open={open}>
      <DialogTitle id="one-time-compensation-label">{title}</DialogTitle>
      <div style={{ padding: theme.spacing(2) }}>
        <SliderInput value={value} showCurrency onChange={setValue} minValue={0} maxValue={1000000} title={'One-time Compensation'} />
      </div>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}